import style from './Success.module.scss'
import successSrc from '../../../assets/success.json'

import { Player } from '@lottiefiles/react-lottie-player'
import { useParams, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

import Heading from '../../commons/Heading'
import Section from '../../commons/Section'

const Success = ({ isModified }) => {
  const navigate = useNavigate()
  const { id, rid } = useParams()

  const handleEvent = (event) => {
    if (event === 'complete') {
      navigate(`/app/competition/${id}/round/${rid}`, { replace: true })
    }
  }

  return (
    <Section className={style.container}>
      <Player
        autoplay
        src={successSrc}
        speed={1}
        className={style.content}
        onEvent={handleEvent}
      />
      <Heading.Display>{isModified ? '¡Cambios guardados!' : '¡Equipo creado!'}</Heading.Display>
    </Section>
  )
}

export default Success

Success.propTypes = {
  isModified: PropTypes.bool
}
