import style from './Providers.module.scss'
import { ReactComponent as AppleLogo } from '../../../assets/images/icon_apple.svg'
import { ReactComponent as GoogleLogo } from '../../../assets/images/icon_google.svg'

import useAuth from '../../../hooks/useAuth'
import useToast from '../../../hooks/useToast'

const Providers = ({ disabled = false }) => {
  const { signinGoogle, signinFacebook, loading } = useAuth()
  const toast = useToast()

  const handleGoogle = () => {
    signinGoogle()
      .catch((err) => {
        toast.error({
          title: err.title || err.code,
          message: err.message
        })
      })
  }

  const handleFacebook = () => {
    signinFacebook()
      .catch((err) => {
        toast.error({
          title: err.title || err.code,
          message: err.message
        })
      })
  }

  return (
    <div className={style.container}>
      <div className={style.header}>
        <span>ó</span>
      </div>
      <div className={style.content}>
        <button className={style.googleButton} onClick={handleGoogle} disabled={loading || disabled}><GoogleLogo />Con Google</button>
        <button className={style.appleButton} onClick={handleFacebook} disabled><AppleLogo />Con Apple</button>
      </div>
    </div>
  )
}

export default Providers
