import { useEffect } from 'react'

import Heading from '../commons/Heading'
import Section from '../commons/Section'
import Skelleton from '../commons/Skeleton'
import Empty from '../modules/Empty'
import CardCompetition from '../modules/CardCompetition'

import useNavigation from '../../hooks/useNavigation'
import useCompetitions from '../../hooks/useCompetitions'
import useToast from '../../hooks/useToast'

const Competitions = () => {
  const { data, error, isLoading } = useCompetitions()
  const { setNavigation } = useNavigation()
  const { error: toast } = useToast()

  useEffect(() => {
    setNavigation({
      title: 'Competencias'
    })
  }, [setNavigation])

  useEffect(() => {
    if (error) {
      toast({
        title: 'Ocurrió un error al cargar las competencias',
        message: error.message
      })
    }
  }, [error, toast])

  return (
    <>
      {isLoading
        ? (
          <Section>
            <Skelleton />
            <Skelleton />
            <Skelleton />
          </Section>
          )
        : data.my.length
          ? <My competitions={data} />
          : <All competitions={data} />}
    </>
  )
}

const My = ({ competitions }) => {
  return (
    <>
      <Section>
        <Heading
          title='Mis competencias'
        />
        {competitions.my.map((props) => <CardCompetition key={props.id} {...props} signed />)}
      </Section>
      {competitions.all.length
        ? (
          <Section>
            <Heading
              title='Competencias disponibles'
            />
            {competitions.all.map((props) => <CardCompetition key={props.id} {...props} />)}
          </Section>
          )
        : null}
      {competitions.myFinished.length
        ? (
          <Section>
            <Heading
              title='Mis competencias pasadas'
            />
            {competitions.myFinished.map((props) => <CardCompetition key={props.id} {...props} finished />)}
          </Section>
          )
        : null}
    </>
  )
}

const All = ({ competitions }) => {
  return (
    <>
      <Empty />
      <Section>
        <Heading
          title='Competencias disponibles'
        />
        {competitions.all.map((props) => <CardCompetition key={props.id} {...props} />)}
      </Section>
      {competitions.myFinished.length
        ? (
          <Section>
            <Heading
              title='Mis competencias pasadas'
            />
            {competitions.myFinished.map((props) => <CardCompetition key={props.id} {...props} finished />)}
          </Section>
          )
        : null}
    </>
  )
}
export default Competitions
