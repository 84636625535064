import style from './index.module.scss'
import imageSrc1 from '../../../assets/images/img_onboard_1.svg'
import imageSrc2 from '../../../assets/images/img_onboard_2.svg'
import imageSrc3 from '../../../assets/images/img_onboard_3.svg'

import { useState, useRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import PropTypes from 'prop-types'

import Nav from './Nav'
import Slide from './Slide'
import Button from '../../commons/Button'

const Onboarding = ({ active, onClick }) => {
  const [current, setCurrent] = useState(0)
  const nodeRef = useRef(null)

  const nextSlide = () => {
    const maxLength = 2
    if (current < maxLength) {
      setCurrent(current + 1)
      return
    }
    onClick()
  }

  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={active}
      timeout={500}
      mountOnEnter
      unmountOnExit
      classNames={{ ...style }}
    >
      <div className={style.container} ref={nodeRef}>
        <div className={style.header}>
          <button
            className={style.link}
            onClick={onClick}
          >
            Saltar
          </button>
        </div>
        <div className={style.content}>
          <Slide
            active={current === 0}
            title={<>Bienvenido a la<br />cancha</>}
            text='Se el mejor DT y comparte con tus amigos la emoción del fútbol, suma puntos mientras te diviertes y grita ¡GOOOOOL!'
            imageSrc={imageSrc1}
          />
          <Slide
            active={current === 1}
            title={<>Vive la emoción<br />del juego</>}
            text='Únete a un torneo en el que deseas participar, y juega contra otras personas para coronarte como el mejor y obtener recompensas.'
            imageSrc={imageSrc2}
          />
          <Slide
            active={current === 2}
            title={<>Saca al DT que<br />llevas dentro</>}
            text='Haz tu mejor análisis, tienes hasta 1 hora antes del primer partido de la jornada para armar tu 11 ideal entre los jugadores que participan y su alineación.'
            imageSrc={imageSrc3}
          />
        </div>
        <div className={style.footer}>
          <Nav
            current={current}
            setCurrent={setCurrent}
          />
          <div className={style.button}>
            <Button onClick={nextSlide}>
              {
                current === 0
                  ? '¿Qué más?'
                  : current === 1
                    ? 'Siguiente'
                    : '¡A jugar!'
              }
            </Button>
          </div>
        </div>
      </div>
    </CSSTransition>
  )
}

export default Onboarding

Onboarding.propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func
}
