import useSWR from 'swr'
import { useMemo } from 'react'

import { apiCompetitions } from '../api'

const useStandings = (competitionId, roundId) => {
  const { data, error, isLoading } = useSWR(['getStandings', { competitionId, roundId }], () => apiCompetitions.getStandings(competitionId, roundId))

  const standings = useMemo(() => {
    if (!isLoading && !error) {
      if (Array.isArray(data)) {
        return data.map((item) => ({
          id: item.coach?.id,
          name: item.coach?.name,
          teamName: item.coach?.team_name,
          photoURL: item.coach?.photo,
          teamId: item.id,
          score: item.points,
          position: item.position,
          hasTeam: item.alineation !== null
        }))
          .sort((a, b) => a.position - b.position)
      }
      return data
    }
    return null
  }, [data, error, isLoading])

  return { data: standings, error, isLoading }
}

export default useStandings
