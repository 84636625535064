import style from './Toast.module.scss'
import { ReactComponent as IconSuccess } from '../../assets/images/icon_success.svg'
import { ReactComponent as IconError } from '../../assets/images/icon_error.svg'

import { useRef } from 'react'
import { CSSTransition } from 'react-transition-group'

import useToast from '../../hooks/useToast'

const Toast = () => {
  const nodeRef = useRef(null)
  const { toast, clear } = useToast()
  const { type, title, message, show } = toast

  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={show}
      timeout={240}
      mountOnEnter
      unmountOnExit
      classNames={{ ...style }}
      onExited={clear}
    >
      <div
        className={`${style.container} ${type === 'error' ? style.error : ''} ${type === 'success' ? style.success : ''}`}
        ref={nodeRef}
      >
        <div className={style.content}>
          {type === 'error'
            ? (
              <div className={style.icon}>
                <IconError />
              </div>
              )
            : type === 'success'
              ? (
                <div className={style.icon}>
                  <IconSuccess />
                </div>
                )
              : null}
          <div className={style.body}>
            {title && <div className={style.title}>{title}</div>}
            <div className={style.message}>{message}</div>
          </div>
        </div>
      </div>
    </CSSTransition>
  )
}

export default Toast
