import { useMemo } from 'react'
import useSWR from 'swr'

import { apiFantasy } from '../api'

const useTeam = (id) => {
  const { data, error, isLoading } = useSWR(id ? ['getTeam', id] : null, () => apiFantasy.getTeams(id), {
    revalidateOnFocus: false,
    revalidateOnReconnect: false
  })

  const team = useMemo(() => {
    if (!isLoading && !error) {
      if (data?.alineation) {
        return {
          name: data.coach.team_name,
          formation: data.alineation,
          players: {
            defenders: data.back.map((player) => ({
              fullName: player.full_name,
              id: player.id,
              name: player.short_name,
              number: player.number,
              position: player.position,
              price: player.cost,
              teamName: player.team?.name,
              teamId: player.team?.id,
              teamLogoURL: player.team?.logo,
              colorPrimary: player.team?.color_primary,
              colorSecondary: player.team?.color_secondary,
              type: player.team?.shirt_type
            })),
            forwards: data.front.map((player) => ({
              fullName: player.full_name,
              id: player.id,
              name: player.short_name,
              number: player.number,
              position: player.position,
              price: player.cost,
              teamName: player.team?.name,
              teamId: player.team?.id,
              teamLogoURL: player.team?.logo,
              colorPrimary: player.team?.color_primary,
              colorSecondary: player.team?.color_secondary,
              type: player.team?.shirt_type
            })),
            goalkeeper: {
              fullName: data.gatekeeper.full_name,
              id: data.gatekeeper.id,
              name: data.gatekeeper.short_name,
              number: data.gatekeeper.number,
              position: data.gatekeeper.position,
              price: data.gatekeeper.cost,
              teamName: data.gatekeeper.team?.name,
              teamId: data.gatekeeper.team?.id,
              teamLogoURL: data.gatekeeper.team?.logo,
              colorPrimary: data.gatekeeper.team?.color_primary,
              colorSecondary: data.gatekeeper.team?.color_secondary,
              type: data.gatekeeper.team?.shirt_type
            },
            midfielders: data.middle.map((player) => ({
              fullName: player.full_name,
              id: player.id,
              name: player.short_name,
              number: player.number,
              position: player.position,
              price: player.cost,
              teamName: player.team?.name,
              teamId: player.team?.id,
              teamLogoURL: player.team?.logo,
              colorPrimary: player.team?.color_primary,
              colorSecondary: player.team?.color_secondary,
              type: player.team?.shirt_type
            }))
          },
          captain: {
            fullName: data.captain.full_name,
            id: data.captain.id,
            name: data.captain.short_name,
            number: data.captain.number,
            photoURL: data.captain.photo,
            position: data.captain.position,
            price: data.captain.cost,
            teamName: data.captain.team?.name,
            teamId: data.captain.team?.id,
            teamLogoURL: data.captain.team?.logo
          }
        }
      }
      return 204
    }
    return null
  }, [data, error, isLoading])

  return { data: team, error, isLoading }
}

export default useTeam
