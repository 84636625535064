const Privacy = () => {
  return (
    <section>
      <h1>Política de privacidad</h1>
      <p><i>Fecha de actualización 4 de enero de 2024</i></p>
      <p>La siguiente Política de Protección a la Propiedad Intelectual e Industrial (la “Política de PI”) rigen los derechos de Propiedad Intelectual e Industrial que ostenta Astrogool® ("Astrogool" o "nosotros") y las obligaciones que usted (el “usuario”) tiene frente a este sitio web, aplicación móvil y a cualquiera de los contenidos disponibles por o a través de www.astrogool.com, incluyendo cualquier contenido derivado del mismo (el "Sitio Web").</p>
      <h2>I. GENERAL</h2>
      <p>La empresa Astrogool®  es dueño único y exclusivo, de todos los derechos, título e intereses en y del Sitio Web, de todo el contenido (incluyendo, por ejemplo, audio, fotografías, ilustraciones, gráficos, otros medios visuales, videos, copias, textos, software, títulos, archivos de Onda de choque, etc.), códigos, datos y materiales del mismo, el aspecto y el ambiente, el diseño y la organización del Sitio Web y la compilación de los contenidos, códigos, datos y los materiales en el Sitio Web, incluyendo pero no limitado a, cualesquiera derechos de autor, derechos de marca, derechos de patente, derechos de base de datos, derechos morales, derechos sui generis y otras propiedades intelectuales y derechos patrimoniales del mismo.</p>
      <p>El simple uso del Sitio Web no le otorga propiedad, licencia o autorización de ninguno de los contenidos, códigos, datos o materiales a los que pueda acceder en o a través del Sitio Web. El usuario tiene prohibido copiar, reproducir, modificar, distribuir, mostrar, realizar o transmitir cualquiera de los Servicios, contenido de los Servicios y/o material propiedad de Astrogool®, para cualquier que sea el propósito.</p>
      <h2>II. DERECHOS DE MARCAS COMERCIALES Y DE SERVICIOS.</h2>
      <p>Astrogool® (o nuestros afiliados) poseemos todos los derechos sobre los nombres de productos, nombres de empresas, nombres comerciales, logotipos, empaques y diseños de productos ("Marcas comerciales"); tales los clubes deportivos afiliados y terceros son propietarios de todas las marcas registradas en sus respectivos productos o servicios, ya sea que aparezcan o no en letra grande o con el símbolo de la marca registrada.</p>
      <p>El uso no autorizado de dichas Marcas Comerciales, incluida la reproducción, imitación, dilución o usos confusos o engañosos, está prohibido según la Ley Federal de Protección a la Propiedad Industrial en los Estados Unidos Mexicanos, por lo tanto, tiene expresamente prohibido usar o hacer mal uso de cualquier Marca Comercial, excepto lo expresamente dispuesto en esta Política, y nada de lo establecido o implícito en los Servicios le confiere ninguna licencia o derecho para hacerlo. Además, la apariencia de los Servicios, incluidos todos los encabezados de página, gráficos, íconos de botones y secuencias de comandos, constituyen Marcas comerciales y están sujetas a restricciones sobre el uso de las Marcas comerciales.</p>
      <h2>III. USO PERMITIDO</h2>
      <p>El Usuario puede utilizar los Servicios del Sitio Web únicamente para sus propios fines informativos y no comerciales. Cualquier otro uso, incluso con fines comerciales, está estrictamente prohibido sin nuestro consentimiento previo expreso por escrito. La recuperación sistemática de datos u otro contenido de los Servicios, ya sea para crear o compilar, directa o indirectamente, una colección, compilación, base de datos o directorio, está prohibida sin nuestro consentimiento previo expreso por escrito.</p>
      <h2>IV. CONTACTO</h2>
      <p>Si tiene preguntas o comentarios en relación con la Política de Protección a la Propiedad Intelectual e Industrial aquí descritos, no dude en contactarnos al correo electrónico <a href='mailto:astrogool2023@gmail.com'>astrogool2023@gmail.com</a>. Es importante que nos proporcione su nombre completo y medio de contacto preferido, ya sea por correo electrónico, teléfono y/o dirección física, para responder a sus preguntas y comentarios.</p>
      <p><b>ATENTAMENTE</b><br />Astrogool®</p>
    </section>
  )
}

export default Privacy
