import style from './CardRound.module.scss'
import { ReactComponent as IconForward } from '../../assets/images/icon_chevron-forward.svg'

import PropTypes from 'prop-types'

import Status from '../commons/Status'

const CardRound = ({ name, startDate, status, team, onClick }) => {
  return (
    <div className={style.container} onClick={onClick}>
      <h3 className={style.title}>{name}</h3>
      <div className={style.date}>
        {startDate}
      </div>
      <div className={style.footer}>
        <div className={style.rank}>
          <Rank
            label='Posición'
            value={team?.position === undefined || status === 'open' ? '--' : team.position}
          />
          <Rank
            label='Puntaje'
            value={team?.score === undefined ? 'NA' : team.score}
          />
        </div>
        {status === 'open'
          ? team?.formation === null
            ? <div className={style.cta}>¡Esperando al DT!</div>
            : <Rank label='Formación' value={team?.formation} />
          : team?.formation === null
            ? <div className={style.na}>Sin participación</div>
            : <Rank label='Formación' value={team?.formation} />}
      </div>
      <Status
        className={style.status}
        status={status}
      />
      <div className={style.icon}>
        <IconForward />
      </div>
    </div>
  )
}

const Rank = ({ label, value }) => (
  <div className={style.rank_item}>
    <span className={style.label}>{label}</span>
    <span className={style.value}>{value}</span>
  </div>
)

export default CardRound

CardRound.propTypes = {
  name: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  team: PropTypes.object,
  onClick: PropTypes.func
}
