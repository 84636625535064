import style from './Slide.module.scss'

import { createRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import PropTypes from 'prop-types'

const Onboarding = ({ title, text, imageSrc, active }) => {
  const nodeRef = createRef(null)

  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={active}
      timeout={710}
      mountOnEnter
      unmountOnExit
      classNames={{ ...style }}
    >
      <div className={style.container} ref={nodeRef}>
        <div className={style.image}>
          <img src={imageSrc} alt={title} />
        </div>
        <div className={style.content}>
          <h1 className={style.title}>{title}</h1>
          <p className={style.text}>{text}</p>
        </div>
      </div>
    </CSSTransition>
  )
}

export default Onboarding

Onboarding.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node.isRequired, PropTypes.string]).isRequired,
  text: PropTypes.string,
  imageSrc: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired
}
