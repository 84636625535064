import style from './Status.module.scss'

import PropTypes from 'prop-types'

const Status = ({ status, className }) => {
  let label
  let classes = style.status
  switch (status) {
    case 'open':
      label = 'Abierta'
      classes = `${classes} ${style.open}`
      break
    case 'live':
      label = 'En juego'
      classes = `${classes} ${style.live}`
      break
    case 'finished':
      label = 'Finalizada'
      classes = `${classes} ${style.finished}`
      break
    default:
      break
  }
  if (className) {
    classes = `${classes} ${className}`
  }

  return (
    <div className={classes}><span />{label}</div>
  )
}

export default Status

Status.propTypes = {
  className: PropTypes.string,
  status: PropTypes.oneOf(['open', 'live', 'finished']).isRequired
}
