import style from './Header.module.scss'
import { ReactComponent as IconAstros } from '../../../assets/images/icon_astros.svg'

import { useRef } from 'react'
import PropTypes from 'prop-types'

const Header = ({ position, astros }) => {
  const positionName = useRef('')
  switch (position) {
    case 'goalkeeper':
      positionName.current = 'Portero'
      break
    case 'defenders':
      positionName.current = 'Defensa'
      break
    case 'midfielders':
      positionName.current = 'Mediocampista'
      break
    case 'forwards':
      positionName.current = 'Delantero'
      break
    default:
      positionName.current = 'Jugador'
      break
  }

  return (
    <div className={style.container}>
      <h1 className={style.title}>Selecciona un <span>{positionName.current}</span></h1>
      <div className={style.astros}>
        Presupuesto disponible:
        <IconAstros />
        <span>${astros}M</span>
      </div>
    </div>
  )
}

export default Header

Header.propTypes = {
  position: PropTypes.string.isRequired,
  astros: PropTypes.number.isRequired
}
