import style from './Referral.module.scss'
import { ReactComponent as IconCopy } from '../../../assets/images/icon_copy.svg'
import { ReactComponent as IconShare } from '../../../assets/images/icon_share.svg'

import { useState, useRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import PropTypes from 'prop-types'

import Button from '../../commons/Button'

const Referral = ({ url }) => {
  const [copied, setCopied] = useState(false)
  const nodeRef = useRef(null)

  const copyLink = (ev) => {
    ev.preventDefault()
    navigator.clipboard.writeText(url)
      .then(() => {
        setCopied(true)
        setTimeout(() => setCopied(false), 1000)
      })
  }

  const handleClick = () => {
    if (navigator.share) {
      navigator.share({
        title: 'Comparte la emoción del Fútbol con AstroGool',
        text: 'Te invito a que demuestres tus conocimientos de fútbol y compitamos para ganar premios.',
        url
      })
    } else {
      const text = 'Hola,%20te%20invito%20a%20que%20te%20registres%20en:%20'
      const waUrl = `whatsapp://send?text=${text}${url}`
      window.open(waUrl, '_self')
    }
  }

  return (
    <div className={style.container}>
      <div className={style.content}>
        <div>
          <h2 className={style.title}>¿Otra oportunidad?</h2>
          <div className={style.text}>¡Invita a un amigo! tú ganas <b>10 millones</b> y tu amigo otros <b>10 millones</b>.</div>
        </div>
        <Button
          onClick={handleClick}
          icon={<IconShare />}
        >
          Compartir enlace
        </Button>
        <div>
          <div className={style.small}>Copiar enlace</div>
          <div className={style.url}>
            <input
              type='text'
              readOnly
              name='url'
              value={url}
            />
            <button
              className={style.action}
              onClick={copyLink}
            >
              <IconCopy />
            </button>
            <CSSTransition
              nodeRef={nodeRef}
              in={copied}
              timeout={200}
              mountOnEnter
              unmountOnExit
              classNames={{ ...style }}
            >
              <span
                className={style.copied}
                ref={nodeRef}
              >
                Enlace copiado
              </span>
            </CSSTransition>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Referral

Referral.propTypes = {
  url: PropTypes.string.isRequired
}
