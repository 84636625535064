import style from './index.module.scss'

import { useState, useRef } from 'react'
import AvatarEditor from 'react-avatar-editor'
import PropTypes from 'prop-types'

import Button from '../../commons/Button'

import useUploadPhoto from '../../../hooks/useUploadPhoto'
import useToast from '../../../hooks/useToast'

const initialState = {
  position: { x: 0.5, y: 0.5 },
  scale: 1
}

const UpdatePhoto = ({ image, onSaved }) => {
  const [imageProps, setImageProps] = useState(initialState)
  const [uploadPhoto, saving] = useUploadPhoto()
  const imageRef = useRef(null)
  const toast = useToast()

  const handlePositionChange = (position) => {
    setImageProps({ ...imageProps, position })
  }

  const handleScale = (ev) => {
    const scale = parseFloat(ev.target.value)
    setImageProps({ ...imageProps, scale })
  }

  const handleClick = (ev) => {
    ev.preventDefault()
    const canvas = imageRef.current.getImageScaledToCanvas()
    canvas.toBlob((blob) => {
      uploadPhoto(blob)
        .then(() => {
          onSaved()
        })
        .catch((err) => {
          toast.error({
            title: 'Ocurrió un error al cargar la imagen',
            message: err.message
          })
        })
    }, 'image/jpeg', 0.8)
  }

  return (
    <section className={style.container}>
      <h1 className={style.title}>Cambiar foto de perfil</h1>
      <div className={style.crop}>
        <AvatarEditor
          ref={imageRef}
          image={image}
          width={230}
          height={230}
          border={16}
          color={[36, 38, 61, 0.75]}
          borderRadius={120}
          scale={imageProps.scale}
          position={imageProps.position}
          onPositionChange={handlePositionChange}
        />
        <input
          className={style.scale}
          name='scale'
          type='range'
          onChange={handleScale}
          max='2'
          min='1'
          step='0.01'
          value={imageProps.scale}
        />
      </div>
      <Button
        className={style.button}
        onClick={handleClick}
        loading={saving}
      >
        Guardar
      </Button>
    </section>
  )
}

export default UpdatePhoto

UpdatePhoto.propTypes = {
  image: PropTypes.object,
  onSaved: PropTypes.func
}
