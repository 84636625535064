import style from './Header.module.scss'
import image from '../../../assets/images/img_league.svg'

import { useRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import PropTypes from 'prop-types'

import Tabs from '../../commons/Tabs'
import Loader from '../../commons/Loader'
import Button from '../../commons/Button'

import useEnroll from '../../../hooks/useEnroll'
import useToast from '../../../hooks/useToast'

const Header = ({ loading, competition, enrolled, setEnrolled, tabs, activeTab, setActiveTab }) => {
  const rankRef = useRef(null)
  const buttonRef = useRef(null)
  const tabsRef = useRef(null)
  const [enroll, enrolling] = useEnroll()
  const { error } = useToast()

  const handleClick = () => {
    enroll(competition.id)
      .then(() => {
        setActiveTab(tabs[1].key)
        setEnrolled(true)
      })
      .catch((err) => {
        error({
          title: 'Ocurrió un error al unirte a la competencia',
          message: err.message
        })
      })
  }

  return (
    <div className={style.container}>
      {loading
        ? <div className={style.loader}><Loader className={style.loader_content} /></div>
        : competition === 404 || !competition
          ? null
          : (
            <>
              <div className={style.graphic}>
                <img src={image} alt='Copa' />
              </div>
              <div className={style.content}>
                <h1 className={style.title}>{competition.seasonName}</h1>
                <div className={style.meta}>
                  <span>Modalidad: <br /><b>{competition.mode}</b></span>
                  <span>Participantes: <br /><b>{competition.players}</b></span>
                </div>
                <div className={style.footer}>
                  {competition.enrolled
                    ? (
                      <div className={style.rank} ref={rankRef}>
                        <Rank
                          label='Posición'
                          value={competition.position}
                        />
                        <div className={style.divider} />
                        <Rank
                          label='Puntos'
                          value={competition.points}
                        />
                      </div>
                      )
                    : (
                      <>
                        <CSSTransition
                          nodeRef={buttonRef}
                          in={!enrolled}
                          timeout={300}
                          mountOnEnter
                          unmountOnExit
                          classNames={{ ...style }}
                        >
                          <div className={`${style.button} ${enrolling ? style.joining : ''}`} ref={buttonRef}>
                            <Button
                              loading={enrolling}
                              onClick={handleClick}
                              animated
                            >
                              ¡Unirme a la competencia!
                            </Button>
                          </div>
                        </CSSTransition>
                        <CSSTransition
                          nodeRef={rankRef}
                          in={enrolled}
                          timeout={300}
                          mountOnEnter
                          unmountOnExit
                          classNames={{ ...style }}
                        >
                          <div className={style.rank} ref={rankRef}>
                            <Rank
                              label='Posición'
                              value={0}
                            />
                            <div className={style.divider} />
                            <Rank
                              label='Puntos'
                              value={0}
                            />
                          </div>
                        </CSSTransition>
                      </>)}
                </div>
              </div>
              <CSSTransition
                nodeRef={tabsRef}
                in={enrolled}
                timeout={300}
                mountOnEnter
                unmountOnExit
                classNames={{ ...style }}
              >
                <Tabs className={style.tabs} ref={tabsRef}>
                  {tabs.map((item) => (
                    <Tabs.Item
                      key={item.key}
                      label={item.label}
                      active={activeTab === item.key}
                      onClick={() => setActiveTab(item.key)}
                    />
                  ))}
                </Tabs>
              </CSSTransition>
            </>
            )}
    </div>
  )
}

const Rank = ({ label, value, onClick }) => (
  <div className={style.rank_item} onClick={onClick}>
    <span className={style.label}>{label}</span>
    <span className={style.value}>{value === undefined ? 'NA' : value}</span>
  </div>
)

export default Header

Header.propTypes = {
  loading: PropTypes.bool.isRequired,
  competition: PropTypes.oneOfType([PropTypes.object, PropTypes.number, PropTypes.string]),
  enrolled: PropTypes.bool,
  setEnrolled: PropTypes.func.isRequired,
  tabs: PropTypes.array.isRequired,
  activeTab: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired
}

Rank.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  value: PropTypes.number
}
