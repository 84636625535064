import style from './index.module.scss'

import { useState, useRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import PropTypes from 'prop-types'

import Header from './Header'
import Counter from './Counter'
import TableStandings from '../TableStandings'
import { NotFound, ServerError, Forbidden } from '../Errors'

import useRound from '../../../hooks/useRound'

const Round = ({ active, id, competitionId, setRoute }) => {
  const { data: round, isLoading } = useRound(id, competitionId)
  const [hasStarted, setHasStarted] = useState(false)
  const nodeRef = useRef(null)

  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={active}
      timeout={500}
      mountOnEnter
      unmountOnExit
      classNames={{ ...style }}
    >
      <div className={style.container} ref={nodeRef}>
        <Header
          loading={isLoading}
          round={round}
          hasStarted={hasStarted}
        />
        {isLoading
          ? null
          : round === 404
            ? <NotFound />
            : round === null
              ? <ServerError />
              : round === 403 || round?.status === 'scheduled'
                ? <Forbidden />
                : round.status === 'open' && !hasStarted
                // : round.status
                  ? (
                    <Counter
                      date={round.startDateRaw}
                      setHasStarted={setHasStarted}
                      setRoute={setRoute}
                      competitionId={competitionId}
                      roundId={id}
                      team={round.team}
                    />
                    )
                  : (
                    <TableStandings
                      team={round.team}
                      competitionId={competitionId}
                      roundId={id}
                      setRoute={setRoute}
                    />
                    )}
      </div>
    </CSSTransition>
  )
}

export default Round

Round.propTypes = {
  active: PropTypes.bool,
  competitionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setRoute: PropTypes.func.isRequired
}
