import style from './index.module.scss'

import { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import Button from '../../commons/Button'
import Input from '../../commons/Input'
import Modal from '../../commons/Modal'
import Providers from './Providers'
import AccountExists from './AccountExists'

import useAuth from '../../../hooks/useAuth'
import useToast from '../../../hooks/useToast'
import Checkbox from '../../commons/Checkbox'

const SignupForm = () => {
  const [errors, setErrors] = useState({
    email: null,
    password: null
  })
  const [checked, setChecked] = useState(false)
  const [searchParams] = useSearchParams()
  const auth = useAuth()
  const toast = useToast()

  useEffect(() => {
    const inviteCode = searchParams.get('invite_code')
    if (inviteCode) window.sessionStorage.setItem('inviteCode', inviteCode)
    return () => window.sessionStorage.removeItem('inviteCode')
  }, [searchParams])

  const handleBlur = (ev) => {
    const field = ev.target.name
    const value = ev.target.value
    switch (field) {
      case 'email':
        if (value.trim() === '') {
          setErrors({
            ...errors,
            email: null
          })
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
          setErrors({
            ...errors,
            email: 'Este correo no es un email válido'
          })
        } else {
          setErrors({
            ...errors,
            email: null
          })
        }
        return
      case 'password':
        if (value.trim() === '') {
          setErrors({
            ...errors,
            password: null
          })
        } else if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(value)) {
          setErrors({
            ...errors,
            password: 'Tu contraseña necesita al menos 8 caracteres, una letra mayúscula y un número'
          })
        } else {
          setErrors({
            ...errors,
            password: null
          })
        }
        return
      default:
        return false
    }
  }

  const handleSubmit = (ev) => {
    ev.preventDefault()
    const email = ev.target.elements.email.value
    const password = ev.target.elements.password.value
    if (email.trim() === '') {
      setErrors({
        ...errors,
        email: 'No olvides tu email'
      })
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      setErrors({
        ...errors,
        email: 'Este correo no es un email válido'
      })
    } else if (password.trim() === '') {
      setErrors({
        ...errors,
        password: 'No olvides introducir tu contraseña'
      })
    } else if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(password)) {
      setErrors({
        ...errors,
        password: 'Tu contraseña necesita al menos 8 caracteres, una letra mayúscula y un número'
      })
    } else {
      setErrors({
        email: null,
        password: null
      })
      auth.createUser({ email, password })
        .catch((err) => {
          if (err.field) {
            setErrors({
              email: null,
              password: null,
              [err.field]: err.message
            })
          } else {
            toast.error({
              title: err.code,
              message: err.message
            })
          }
        })
    }
  }

  const handleClick = (ev) => {
    ev.preventDefault()
    const path = ev.target.href
    window.open(path, '_blank')
  }

  return (
    <>
      <form className={style.container} onSubmit={handleSubmit}>
        <Input
          type='email'
          label='Correo'
          name='email'
          placeholder='Ingresa tu correo'
          error={errors.email}
          onBlur={handleBlur}
        />
        <Input.Password
          type='password'
          label='Contraseña'
          name='password'
          placeholder='Ingresa tu contraseña'
          error={errors.password}
          onBlur={handleBlur}
        />
        <div className={style.policies}>
          <Checkbox checked={checked} onChange={() => setChecked(!checked)}>
            <span>Acepto las <a className={style.link} href='/terms' target='_blank' rel='noopener noreferrer' onClick={handleClick}>condiciones</a> y <a className={style.link} href='/privacy' target='_blank' rel='noopener noreferrer' onClick={handleClick}>política de privacidad</a></span>
          </Checkbox>
        </div>
        <Button
          type='submit'
          loading={auth.loading}
          disabled={!checked}
        >
          Registrarse
        </Button>
      </form>
      <Providers disabled={!checked} />
      <Modal
        show={auth.hasDifferentCredential}
        close={() => auth.setHasDifferentCredential(false)}
      >
        <AccountExists onClick={() => auth.setHasDifferentCredential(false)} />
      </Modal>
    </>
  )
}

export default SignupForm
