import style from './index.module.scss'

import { useState, useRef, useEffect } from 'react'
import { CSSTransition } from 'react-transition-group'
import PropTypes from 'prop-types'

import Header from './Header'
import TableStandings from '../TableStandings'
import Rounds from './Rounds'
import { NotFound, ServerError } from '../Errors'

import useCompetition from '../../../hooks/useCompetition'

const tabNavigation = [
  {
    label: 'Jornadas',
    key: 'rounds'
  },
  {
    label: 'Tabla general',
    key: 'positions'
  }
]

const Competititon = ({ active, id, setRoute }) => {
  const { data: competition, isLoading } = useCompetition(id)
  const [enrolled, setEnrolled] = useState(null)
  const [activeTab, setActiveTab] = useState(tabNavigation[0].key)
  const nodeRef = useRef(null)

  useEffect(() => {
    if (!isLoading) {
      setEnrolled(competition?.enrolled)
    }
  }, [isLoading, competition])

  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={active}
      timeout={500}
      mountOnEnter
      unmountOnExit
      classNames={{ ...style }}
    >
      <div className={style.container} ref={nodeRef}>
        <Header
          loading={isLoading}
          competition={competition}
          enrolled={enrolled}
          setEnrolled={setEnrolled}
          tabs={tabNavigation}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        {isLoading
          ? null
          : competition === 404
            ? <NotFound />
            : competition === null
              ? <ServerError />
              : !enrolled
                  ? <TableStandings.Preview competitionId={id} />
                  : activeTab === 'rounds'
                    ? (
                      <Rounds
                        competitionId={id}
                        seasonId={competition.seasonId}
                        setRoute={setRoute}
                        teams={competition.teams}
                      />
                      )
                    : (
                      <TableStandings
                        competitionId={id}
                      />
                      )}
      </div>
    </CSSTransition>
  )
}

export default Competititon

Competititon.propTypes = {
  active: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setRoute: PropTypes.func.isRequired
}
