import style from './PlayingField.module.scss'

const PlayingField = ({ children }) => {
  return (
    <div className={style.container}>
      <div className={style.content}>
        {children}
      </div>
    </div>
  )
}

const Goalkeeper = ({ children }) => {
  return (
    <div className={style.row}>
      {children}
    </div>
  )
}

const Defenders = ({ children }) => {
  return (
    <div className={style.row}>
      {children}
    </div>
  )
}

const Midfielders = ({ children }) => {
  return (
    <div className={style.row}>
      {children}
    </div>
  )
}

const Forwards = ({ children }) => {
  return (
    <div className={style.row}>
      {children}
    </div>
  )
}

PlayingField.Goalkeeper = Goalkeeper
PlayingField.Defenders = Defenders
PlayingField.Midfielders = Midfielders
PlayingField.Forwards = Forwards

export default PlayingField
