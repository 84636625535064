import style from './Screen.module.scss'

import { useRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import PropTypes from 'prop-types'

const Screen = ({ active, onEntered }) => {
  const nodeRef = useRef(null)

  const handleEnter = () => {
    const element = document.getElementById('root')
    element.style.overflowX = 'hidden'
  }

  const handleEntered = () => {
    const element = document.getElementById('root')
    element.removeAttribute('style')
    onEntered()
  }

  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={active}
      timeout={500}
      mountOnEnter
      unmountOnExit
      classNames={{ ...style }}
      onEnter={handleEnter}
      onEntered={handleEntered}
    >
      <div className={style.container} ref={nodeRef} />
    </CSSTransition>
  )
}

export default Screen

Screen.propTypes = {
  active: PropTypes.bool.isRequired,
  onEntered: PropTypes.func
}
