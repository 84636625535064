import { useState, useRef, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import Screen from '../commons/Screen'
import Round from '../modules/Round'

import useNavigation from '../../hooks/useNavigation'
import useRound from '../../hooks/useRound'
import useToast from '../../hooks/useToast'

const RoundRoute = () => {
  const { id, rid } = useParams()
  const navigate = useNavigate()
  const { data: round, error } = useRound(rid, id)
  const { error: toast } = useToast()
  const { setNavigation } = useNavigation()
  const [active, setActive] = useState(0)
  const destinationRoute = useRef(null)

  useEffect(() => {
    setNavigation({
      title: round?.name,
      hasBack: true,
      isSecondary: true
    })
  }, [setNavigation, round])

  useEffect(() => {
    if (error) {
      toast({
        title: 'Ocurrió un error al cargar la jornada',
        message: error.message
      })
    }
  }, [error, toast])

  const setDestinationRoute = (route) => {
    destinationRoute.current = route
    setActive(1)
  }

  const handleEntered = () => {
    navigate(destinationRoute.current)
  }

  return (
    <>
      <Round
        active={active === 0}
        id={rid}
        competitionId={id}
        setRoute={setDestinationRoute}
      />
      <Screen
        active={active === 1}
        onEntered={handleEntered}
      />
    </>
  )
}

export default RoundRoute
