import style from './CardPosition.module.scss'
import { ReactComponent as IconEmblem } from '../../assets/images/icon_shield.svg'
import { ReactComponent as IconChevron } from '../../assets/images/icon_chevron-forward.svg'

import PropTypes from 'prop-types'

const CardPosition = ({ id, photoURL, score, teamName, teamId, name, position, current, disabled, onClick }) => {
  const handleClick = () => {
    if (!disabled && onClick) {
      onClick(`/app/team/${teamId}`)
    }
  }

  return (
    <div className={`${style.container} ${current ? style.current : ''} ${disabled ? style.disabled : ''}`} onClick={handleClick}>
      <div className={style.position}>{position}</div>
      <div className={style.graphic}>
        <div className={style.avatar}>
          {photoURL ? <img src={photoURL} alt='Avatar' /> : <IconEmblem />}
        </div>
      </div>
      <div className={style.content}>
        <h3 className={style.team}>{teamName || 'Deportivo ' + id + ' FC'}</h3>
        {name && <div className={style.name}>DT {name}</div>}
      </div>
      <div className={style.score}>
        <span className={style.value}>{score}</span>
      </div>
    </div>
  )
}

const CardPositionMain = ({ photoURL, score, teamName, name, onClick }) => {
  return (
    <div className={`${style.container} ${style.main}`} onClick={onClick}>
      <div className={style.graphic}>
        <div className={style.avatar}>
          {photoURL ? <img src={photoURL} alt='Avatar' /> : <IconEmblem />}
        </div>
      </div>
      <div className={style.content}>
        <h3 className={style.team}>{teamName}</h3>
        <div className={style.name}>DT {name}</div>
      </div>
      <div className={style.score}>
        <span className={style.value}>{score}</span>
        <IconChevron />
      </div>
    </div>
  )
}

CardPosition.Main = CardPositionMain

export default CardPosition

CardPosition.propTypes = {
  photoURL: PropTypes.string,
  score: PropTypes.number.isRequired,
  teamName: PropTypes.string,
  name: PropTypes.string,
  position: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  current: PropTypes.bool
}

CardPositionMain.propTypes = {
  photoURL: PropTypes.string,
  score: PropTypes.number.isRequired,
  teamName: PropTypes.string,
  name: PropTypes.string
}
