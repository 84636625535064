import style from './AppBar.module.scss'
import { ReactComponent as Logo } from '../../../assets/images/logotipo.svg'
import { ReactComponent as IconBack } from '../../../assets/images/icon_chevron-back.svg'

import { useNavigate } from 'react-router-dom'

import useNavigation from '../../../hooks/useNavigation'

const AppBar = () => {
  const navigate = useNavigate()
  const { navigation } = useNavigation()

  return (
    <header className={`${style.container} ${navigation.isSecondary ? style.secondary : ''}`}>
      {navigation.hasBack && <div className={style.back}><button onClick={() => navigate(-1)}><IconBack /></button></div>}
      {navigation.title === false
        ? <div className={style.logo}><Logo /></div>
        : navigation.title
          ? <div className={style.title}>{navigation.title}</div>
          : null}
    </header>
  )
}

export default AppBar
