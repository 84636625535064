import style from './Option.module.scss'
import { ReactComponent as IconChevron } from '../../assets/images/icon_chevron-forward.svg'

import PropTypes from 'prop-types'

const Option = ({ label, value, onClick, link, className, dataAttr = 'option' }) => {
  return (
    <div className={`${style.container} ${link ? style.link : ''} ${className || ''}`}>
      <span className={style.label}>{label}</span>
      <button className={style.content} onClick={onClick} data-attr={dataAttr}>
        <span className={style.value}>{value}</span>
        <span className={style.icon}><IconChevron /></span>
      </button>
    </div>
  )
}

export default Option

Option.propTypes = {
  className: PropTypes.string,
  dataAttr: PropTypes.string,
  label: PropTypes.string,
  link: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
}
