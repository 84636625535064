import style from './Button.module.scss'

import PropTypes from 'prop-types'

import Spinner from './Spinner'

const Button = ({ type = 'button', secondary, children, disabled, loading, onClick, className, icon }) => {
  return (
    <button
      className={`${style.container} ${secondary ? style.secondary : ''} ${className || ''}`}
      type={type}
      disabled={disabled || loading}
      onClick={onClick}
    >
      <span className={`${style.loader} ${loading ? style.active : ''}`}>{loading && <Spinner />}</span>
      {icon && <span className={style.icon}>{icon}</span>}
      <span className={style.text}>{children}</span>
    </button>
  )
}

export default Button

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.node,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  secondary: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'submit', 'reset'])
}
