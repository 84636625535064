import style from './Lineup.module.scss'
import { ReactComponent as IconAstros } from '../../../assets/images/icon_astros.svg'
import { ReactComponent as IconCheck } from '../../../assets/images/icon_check.svg'
import { ReactComponent as IconWallet } from '../../../assets/images/icon_wallet.svg'

import { useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'

import Section from '../../commons/Section'
import Button from '../../commons/Button'
import Spinner from '../../commons/Spinner'
import PlayingField from '../PlayingField'
import Shirt from '../Shirt'

import useSetTeam from '../../../hooks/useSetTeam'
import useAstros from '../../../hooks/useAstros'
import useToast from '../../../hooks/useToast'
import useUser from '../../../hooks/useUser'

const Lineup = ({ onSelect, onClick }) => {
  const { id } = useParams()
  const { data: astros, isLoading, error } = useAstros(id)
  const { error: toast } = useToast()
  const [team, dispatch] = useSetTeam()
  const [user] = useUser()
  const [bonusDisabled, setBonusDisabled] = useState(false)
  const [playersSelected, setPlayersSelected] = useState(0)

  const removePlayer = useCallback((position, index) => {
    const value = { ...team.players }
    if (position === 'goalkeeper') {
      team.captain?.id === team.players.goalkeeper?.id && dispatch({ type: 'set_captain', value: null })
      value.goalkeeper = null
    } else {
      team.captain?.id === team.players[position][index]?.id && dispatch({ type: 'set_captain', value: null })
      value[position][index] = null
    }
    dispatch({ type: 'set_players', value })
  }, [dispatch, team.captain, team.players])

  useEffect(() => {
    if (error) {
      toast({
        title: 'Ocurrió un error al cargar tu presupuesto',
        message: error.message
      })
    }
  }, [error, toast])

  useEffect(() => {
    if (isLoading) return

    if (user.astros <= 0 || team.isSaved) {
      setBonusDisabled(true)
    } else if (team.hasBonus === null) {
      dispatch({ type: 'set_bonus', value: true })
    }
  }, [isLoading, user.astros, team.isSaved, team.hasBonus, dispatch])

  useEffect(() => {
    if (isLoading) return

    let players = team.players ? Object.values(team.players).flat() : []
    players = players.filter((player) => player !== null)
    setPlayersSelected(players.length)
    const cost = players.reduce((total, player) => total + (player?.price || 0), 0)
    if (team.hasBonus) {
      dispatch({ type: 'set_budget', value: 110 - cost }) // Piratencia con los Astros, se debe cambiar por el valor real que te astros.max
    } else {
      dispatch({ type: 'set_budget', value: astros.round - cost })
    }
  }, [isLoading, team.players, team.hasBonus, astros, dispatch])

  const handleChange = (ev) => {
    const checked = ev.target.checked
    if (checked) {
      dispatch({ type: 'set_budget', value: team.budget + Number(astros.bonus) })
    } else {
      dispatch({ type: 'set_budget', value: team.budget - Number(astros.bonus) })
    }
    dispatch({ type: 'set_bonus', value: checked })
  }

  return (
    <Section className={style.container}>
      <div className={style.header}>
        <div className={style.formation}>
          Formación<br /><span>{team.formation?.name}</span>
        </div>
        <div className={`${style.total} ${team.budget < 0 ? style.negative : ''}`}>
          Presupuesto<br />
          {isLoading ? <span className={style.spinner}><Spinner /></span> : <span>${team.budget}M</span>}
        </div>
        <div className={style.wallet}>
          {!team.isSaved && !isLoading && (
            <label className={`${style.bonus} ${team.hasBonus ? style.selected : ''} ${bonusDisabled ? style.disabled : ''}`}>
              <input
                type='checkbox'
                id='bonus'
                name='bonus'
                checked={team.hasBonus}
                onChange={handleChange}
                disabled={bonusDisabled}
              />
              <div className={style.checkbox}>
                {team.hasBonus ? <IconCheck /> : null}
              </div>
              <div className={style.astros}>
                <IconWallet />
                <span>${team.hasBonus ? user.astros - astros.bonus : user.astros}M</span>
                <div className={style.tag}><IconAstros />Astros</div>
              </div>
            </label>
          )}
        </div>
      </div>
      {!isLoading && team.formation && (
        <>
          <PlayingField>
            <PlayingField.Goalkeeper>
              <Shirt
                player={team.players.goalkeeper}
                onClick={() => onClick('goalkeeper')}
                onRemove={() => removePlayer('goalkeeper')}
                captain={team.captain?.id === team.players.goalkeeper?.id}
              />
            </PlayingField.Goalkeeper>
            <PlayingField.Defenders>
              {team.players.defenders.map((player, i) => (
                <Shirt
                  key={`defenders-${i}`}
                  player={player}
                  onClick={() => onClick('defenders', i)}
                  onRemove={() => removePlayer('defenders', i)}
                  captain={team.captain?.id === player?.id}
                />
              ))}
            </PlayingField.Defenders>
            <PlayingField.Midfielders>
              {team.players.midfielders.map((player, i) => (
                <Shirt
                  key={`midfielders-${i}`}
                  player={player}
                  onClick={() => onClick('midfielders', i)}
                  onRemove={() => removePlayer('midfielders', i)}
                  captain={team.captain?.id === player?.id}
                />
              ))}
            </PlayingField.Midfielders>
            <PlayingField.Forwards>
              {team.players.forwards.map((player, i) => (
                <Shirt
                  key={`forwards-${i}`}
                  player={player}
                  onClick={() => onClick('forwards', i)}
                  onRemove={() => removePlayer('forwards', i)}
                  captain={team.captain?.id === player?.id}
                />
              ))}
            </PlayingField.Forwards>
          </PlayingField>
          <div className={`${style.help} ${playersSelected === 11 ? style.done : ''}`}>
            <span className={style.icon}><IconCheck /></span>
            <span>Debes seleccionar tus 11 jugadores para continuar</span>
          </div>
          <Button
            disabled={playersSelected !== 11 || team.budget < 0}
            onClick={onSelect}
          >
            Continuar
          </Button>
        </>
      )}
    </Section>
  )
}

export default Lineup

Lineup.propTypes = {
  onSelect: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired
}
