import { useEffect } from 'react'

import ProfileModule from '../modules/Profile'

import useNavigation from '../../hooks/useNavigation'

const Profile = () => {
  const { setNavigation } = useNavigation()

  useEffect(() => {
    setNavigation({
      title: 'Perfil'
    })
  }, [setNavigation])

  return <ProfileModule />
}

export default Profile
