import style from './index.module.scss'

import { Outlet } from 'react-router-dom'

import AppBar from './AppBar'
import NavBar from './NavBar'
import Toast from '../Toast'

import { NavigationProvider } from '../../../context/Navigation'

const Main = () => {
  return (
    <NavigationProvider>
      <div className={style.appbar}>
        <AppBar />
      </div>
      <main className={style.main}>
        <Outlet />
      </main>
      <div className={style.navbar}>
        <NavBar />
      </div>
      <Toast />
    </NavigationProvider>
  )
}

export default Main
