import style from './CardPrize.module.scss'
import { ReactComponent as IconFacebook } from '../../assets/images/icon_facebook.svg'

import { useState } from 'react'

import Modal from '../commons/Modal'
import Button from '../commons/Button'

const CardPrize = ({ title, description, image, logo, children, fbUrl }) => {
  const [showModal, setShowModal] = useState(false)

  const handleClick = () => {
    setShowModal(true)
  }

  return (
    <>
      <div className={style.container} style={{ backgroundImage: `url(${image})` }} onClick={handleClick}>
        <div className={style.title}>{title}</div>
        <div className={style.description}>{description}</div>
        <div className={style.logo}>
          <img src={logo} alt='Logo patrocinador' />
        </div>
      </div>
      <Modal
        show={showModal}
        close={() => setShowModal(false)}
        className={style.modal}
      >
        <div className={style.modal_content} style={{ backgroundImage: `url(${image})` }}>
          <div className={style.modal_logo}>
            <img src={logo} alt='Logo patrocinador' />
          </div>
          <div className={style.modal_title}>{title}</div>
          <div className={style.modal_description}>{description}</div>
          <div className={style.modal_footer}>{children}</div>
          <div className={style.modal_button}>
            <Button secondary icon={<IconFacebook />} onClick={() => window.open(fbUrl, '_blank')}>Más info</Button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default CardPrize
