import { useState, useCallback } from 'react'
import {
  signInWithEmailAndPassword,
  signOut as firebaseSignOut,
  sendPasswordResetEmail,
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  EmailAuthProvider,
  reauthenticateWithCredential
} from 'firebase/auth'

import { auth } from '../lib/firebase'

const useAuth = () => {
  const [loading, setLoading] = useState(false)
  const [hasDifferentCredential, setHasDifferentCredential] = useState(false)

  const signIn = useCallback(({ email, password }) => {
    return new Promise((resolve, reject) => {
      setLoading(true)
      signInWithEmailAndPassword(auth, email, password)
        .then(() => {
          resolve()
        })
        .catch((err) => {
          const error = {
            field: null,
            message: '',
            code: ''
          }
          switch (err.code) {
            case 'auth/invalid-email':
              error.field = 'email'
              error.message = 'La cuenta no es un email válido.'
              break
            case 'auth/user-disabled':
              error.field = 'email'
              error.message = 'Esta cuenta se encuentra inactiva.'
              break
            case 'auth/user-not-found':
              error.field = 'email'
              error.message = 'Esta cuenta no pertenece a ningún usuario de la plataforma.'
              break
            case 'auth/wrong-password':
              error.field = 'password'
              error.message = 'Tu contraseña es incorrecta.'
              break
            case 'auth/too-many-requests':
              error.field = 'password'
              error.message = 'El acceso a esta cuenta ha sido bloqueado temporalmente debido a varios intentos fallidos .'
              break
            default:
              error.code = err.code
              error.message = err.message
              break
          }
          console.error(err)
          reject(error)
        })
        .finally(() => {
          setLoading(false)
        })
    })
  }, [])

  const signOut = useCallback(() => {
    return new Promise((resolve, reject) => {
      firebaseSignOut(auth)
        .then(() => {
          resolve()
        })
        .catch((err) => {
          console.error(err)
          reject(err)
        })
    })
  }, [])

  const createUser = useCallback(({ email, password }) => {
    return new Promise((resolve, reject) => {
      setLoading(true)
      createUserWithEmailAndPassword(auth, email, password)
        .then(() => {
          resolve()
        })
        .catch((err) => {
          const error = {
            field: null,
            message: '',
            code: ''
          }
          switch (err.code) {
            case 'auth/invalid-email':
              error.field = 'email'
              error.message = 'La cuenta no es un email válido.'
              break
            case 'auth/email-already-in-use':
              error.field = 'email'
              error.message = 'Esta cuenta ya está en uso por otro usuario.'
              break
            case 'auth/weak-password':
              error.field = 'password'
              error.message = 'La contraseña debe contener mínimo 6 caracteres.'
              break
            default:
              error.code = err.code
              error.message = err.message
              break
          }
          console.error(err)
          reject(error)
        })
        .finally(() => {
          setLoading(false)
        })
    })
  }, [])

  const signinGoogle = useCallback(() => {
    return new Promise((resolve, reject) => {
      setLoading(true)
      const provider = new GoogleAuthProvider()
      signInWithPopup(auth, provider)
        .then(() => {
          resolve()
        })
        .catch((err) => {
          const error = {
            title: '',
            message: '',
            code: ''
          }
          switch (err.code) {
            case 'auth/popup-closed-by-user':
              error.title = 'Inicio de sesión cancelado'
              error.message = 'El inicio de sesión fue cancelado por el usuario.'
              break
            default:
              error.code = err.code
              error.message = err.message
              break
          }
          console.error(err)
          reject(error)
        })
        .finally(() => {
          setLoading(false)
        })
    })
  }, [])

  const signinFacebook = useCallback(() => {
    return new Promise((resolve, reject) => {
      setLoading(true)
      const provider = new FacebookAuthProvider()
      signInWithPopup(auth, provider)
        .then(() => {
          resolve()
        })
        .catch((err) => {
          const error = {
            title: '',
            message: '',
            code: ''
          }
          switch (err.code) {
            case 'auth/popup-closed-by-user':
              error.title = 'Inicio de sesión cancelado'
              error.message = 'El inicio de sesión fue cancelado por el usuario.'
              break
            default:
              error.code = err.code
              error.message = err.message
              break
          }
          console.error(err)
          reject(error)
        })
        .finally(() => {
          setLoading(false)
        })
    })
  }, [])

  const reauthenticate = useCallback((password) => {
    return new Promise((resolve, reject) => {
      setLoading(true)
      const currentUser = auth.currentUser
      const credential = EmailAuthProvider.credential(currentUser.email, password)
      reauthenticateWithCredential(currentUser, credential)
        .then(() => {
          resolve()
        })
        .catch((err) => {
          const error = {
            field: null,
            message: '',
            code: ''
          }
          switch (err.code) {
            case 'auth/invalid-email':
              error.message = 'La cuenta no es un email válido.'
              break
            case 'auth/user-not-found':
              error.message = 'Esta cuenta no pertenece a ningún usuario de la plataforma.'
              break
            case 'auth/wrong-password':
              error.field = 'password'
              error.message = 'Tu contraseña es incorrecta.'
              break
            case 'auth/too-many-requests':
              error.field = 'password'
              error.message = 'Cuenta bloqueada temporalmente.'
              break
            default:
              error.code = err.code
              error.message = err.message
              break
          }
          console.error(err)
          reject(error)
        })
        .finally(() => {
          setLoading(false)
        })
    })
  }, [])

  const resetPassword = useCallback((email) => {
    return new Promise((resolve, reject) => {
      setLoading(true)
      sendPasswordResetEmail(auth, email)
        .then(() => {
          resolve()
        })
        .catch((err) => {
          const error = {
            field: null,
            message: '',
            code: ''
          }
          switch (err.code) {
            case 'auth/invalid-email':
              error.field = 'email'
              error.message = 'La cuenta no es un email válido.'
              break
            case 'auth/user-not-found':
              error.field = 'email'
              error.message = 'Esta cuenta no pertenece a ningún usuario.'
              break
            default:
              error.code = err.code
              error.message = err.message
              break
          }
          console.error(err)
          reject(error)
        })
        .finally(() => {
          setLoading(false)
        })
    })
  }, [])

  return {
    signIn,
    signOut,
    createUser,
    reauthenticate,
    resetPassword,
    signinGoogle,
    signinFacebook,
    loading,
    hasDifferentCredential,
    setHasDifferentCredential
  }
}

export default useAuth
