import style from './Header.module.scss'

import PropTypes from 'prop-types'

import Loader from '../../commons/Loader'
import Steps from '../../commons/Steps'

const stepsNavigation = [
  {
    label: 'Formación',
    key: 1
  },
  {
    label: 'Jugadores',
    key: 2
  },
  {
    label: 'Capitán',
    key: 3
  }
]

const Header = ({ loading, round, team, activeStep, setActiveStep, showFAQ }) => {
  const validator = (key) => {
    let players = team.players ? Object.values(team.players).flat() : []
    players = players.filter((player) => player !== null)

    switch (key) {
      case 2:
        return team.formation === null
      case 3:
        return players.length !== 11
      default:
        return false
    }
  }

  return (
    <div className={style.container}>
      {loading
        ? <div className={style.loader}><Loader className={style.loader_content} /></div>
        : round === 404 || round === null || round === 403
          ? null
          : (
            <div className={style.content}>
              <div className={style.meta}>
                <span>{`${round.leagueName} - ${round.seasonName} - ${round.name}`}</span>
              </div>
              {activeStep !== 4 && (
                <>
                  <Steps
                    className={style.steps}
                    navigation={stepsNavigation}
                    active={activeStep}
                    setActive={setActiveStep}
                    validator={validator}
                    isDone={team.captain !== null}
                  />
                </>
              )}
              <button className={style.help} onClick={showFAQ}>?</button>
            </div>
            )}
    </div>
  )
}

export default Header

Header.propTypes = {
  loading: PropTypes.bool,
  round: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  team: PropTypes.object,
  activeStep: PropTypes.number.isRequired,
  setActiveStep: PropTypes.func.isRequired,
  showFAQ: PropTypes.func
}
