import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import Screen from '../commons/Screen'
import Onboarding from '../modules/OnboardingTeam'
import ManageTeam from '../modules/ManageTeam'

import useNavigation from '../../hooks/useNavigation'
import useRound from '../../hooks/useRound'
import useToast from '../../hooks/useToast'
import { TeamProvider } from '../../context/Team'

const ManageTeamRoute = () => {
  const [active, setActive] = useState(0)
  const [showOnboarding, setShowOnboarding] = useState(null)
  const { setNavigation } = useNavigation()

  const skipOnboarding = () => {
    setActive(1)
    let config = window.localStorage.getItem('config')
    if (config) config = JSON.parse(config)
    window.localStorage.setItem('config', JSON.stringify(config ? { ...config, createdTeam: true } : { createdTeam: true }))
  }

  useEffect(() => {
    setNavigation({
      title: '',
      hasBack: false,
      isSecondary: true
    })
  }, [setNavigation])

  useEffect(() => {
    let config = window.localStorage.getItem('config')
    if (config) {
      config = JSON.parse(config)
      if (config.createdTeam) return setShowOnboarding(false)
      return setShowOnboarding(true)
    } else {
      setShowOnboarding(true)
    }
  }, [])

  if (showOnboarding === null) return

  if (showOnboarding) {
    return (
      <>
        <Onboarding
          active={active === 0}
          onClick={skipOnboarding}
        />
        <Screen
          active={active === 1}
          onEntered={() => setShowOnboarding(false)}
        />
      </>
    )
  }

  return <Team />
}

const Team = () => {
  const { id, rid } = useParams()
  const { data: round, error, isLoading } = useRound(rid, id)
  const { error: toast } = useToast()
  const { setNavigation } = useNavigation()

  useEffect(() => {
    setNavigation({
      title: !round?.name ? '' : !round?.team?.formation ? 'Crear equipo' : 'Modificar equipo',
      hasBack: true,
      isSecondary: true
    })
  }, [setNavigation, round])

  useEffect(() => {
    if (error) {
      toast({
        title: 'Ocurrió un error al cargar tu equipo',
        message: error.message
      })
    }
  }, [error, toast])

  return (
    <TeamProvider>
      <ManageTeam round={round} competitionId={id} isLoading={isLoading} />
    </TeamProvider>
  )
}

export default ManageTeamRoute
