import style from './index.module.scss'

import { useState, useRef, useEffect } from 'react'

import Tabs from '../../commons/Tabs'
import Rules from './Rules'
import Scores from './Scores'

const Help = ({ active = 1 }) => {
  const [activeTab, setActiveTab] = useState(active)
  const contentRef = useRef(null)

  useEffect(() => {
    contentRef.current.scrollTo(0, 0)
  }, [activeTab])

  return (
    <div className={style.container}>
      <Tabs className={style.tabs}>
        <Tabs.Item
          label='Reglas'
          active={activeTab === 1}
          onClick={() => setActiveTab(1)}
        />
        <Tabs.Item
          label='Puntuación'
          active={activeTab === 2}
          onClick={() => setActiveTab(2)}
        />
      </Tabs>
      <div className={style.content} ref={contentRef}>
        {activeTab === 1 && <Rules />}
        {activeTab === 2 && <Scores />}
      </div>
    </div>
  )
}

export default Help
