import style from './Header.module.scss'
import { ReactComponent as IconAvatar } from '../../../assets/images/icon_avatar.svg'

import PropTypes from 'prop-types'

import Loader from '../../commons/Loader'

const Header = ({ loading, team, showFAQ }) => {
  return (
    <div className={style.container}>
      {loading
        ? <div className={style.loader}><Loader className={style.loader_content} /></div>
        : team === 404 || team === null || team === 403
          ? null
          : (
            <>
              <div className={style.content}>
                <h1 className={style.title}>DT {team.dtName}</h1>
                <div className={style.footer}>
                  <div className={style.captain}>
                    <div className={style.photo}>
                      {team.captain.photoURL ? <img src={team.captain.photoURL} alt={team.captain.name} /> : <IconAvatar />}
                    </div>
                    <div>
                      <div className={style.label}>Capitán</div>
                      <div className={style.captain_name}>{team.captain.fullName}</div>
                    </div>
                  </div>
                  <div className={style.info}>
                    <div className={style.meta}>
                      <div className={style.label}>Formación</div>
                      <div className={style.price}>{team.formation.name}</div>
                    </div>
                    <div className={style.meta}>
                      <div className={style.label}>Costo</div>
                      <div className={style.price}>${team.cost}M</div>
                    </div>
                  </div>
                </div>
                <button className={style.help} onClick={showFAQ}>?</button>
              </div>
            </>
            )}
    </div>
  )
}

export default Header

Header.propTypes = {
  loading: PropTypes.bool,
  team: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  showFAQ: PropTypes.func
}
