import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Onboarding from '../modules/Onboarding'
import Screen from '../commons/Screen'

const Welcome = () => {
  const [active, setActive] = useState(0)
  const navigate = useNavigate()

  return (
    <>
      <Onboarding
        active={active === 0}
        onClick={() => setActive(1)}
      />
      <Screen
        active={active === 1}
        onEntered={() => navigate('/auth/login')}
      />
    </>
  )
}

export default Welcome
