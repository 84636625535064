import useSWR from 'swr'
import { useMemo } from 'react'

import { apiGame, apiCompetitions } from '../api'
import { dateToLocale } from '../utils'

const useRound = (id, competitionId) => {
  const round = useSWR(['getRound', id], () => apiGame.getRounds(null, id))
  const info = useSWR(['info', competitionId], () => apiCompetitions.getInfo(competitionId))

  const error = round.error || info.error
  const isLoading = !(!round.isLoading && !info.isLoading)

  const data = useMemo(() => {
    if (!isLoading && !error) {
      if (round.data === 404) {
        return 404
      } else {
        const data = {
          id: round.data.id,
          name: round.data.name,
          leagueName: round.data.league.name,
          leagueId: round.data.league.id,
          seasonName: round.data.season.name,
          seasonId: round.data.season.id,
          startDate: dateToLocale(round.data.start_date, true),
          startDateRaw: round.data.start_date,
          status: round.data.status,
          active: round.data.active
        }
        if (info.data.points === null) return 403
        const team = info.data.rounds_alineations?.find((team) => team.round === Number(id))
        if (team) {
          data.team = {
            id: team.id,
            roundId: team.round,
            formation: team.alineation,
            score: team.points,
            position: team.position,
            teamName: team.coach.team_name,
            teamId: team.coach.id,
            name: team.coach.name
          }
        }
        return data
      }
    }
    return null
  }, [round.data, info.data, error, isLoading, id])

  return { data, error, isLoading }
}

export default useRound
