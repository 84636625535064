import style from './Steps.module.scss'
import { ReactComponent as Icon } from '../../assets/images/icon_check.svg'

import PropTypes from 'prop-types'

const Steps = ({ className, navigation, active, setActive, validator, isDone }) => {
  return (
    <div className={`${style.container} ${className || ''}`}>
      <div className={style.content}>
        {navigation.map((step) => (
          <Step
            key={step.key}
            active={active === step.key}
            done={active > step.key || isDone}
            label={step.label}
            value={step.key}
            onClick={setActive}
            disabled={active < step.key && (validator ? validator(step.key) : false)}
          />)
        )}
      </div>
    </div>
  )
}

const Step = ({ active, done, label, value, onClick, disabled }) => (
  <div className={`${style.step} ${active ? style.active : ''} ${done ? style.done : ''}`}>
    <div className={style.step__content}>
      <button onClick={() => onClick(value)} disabled={disabled}>
        <span className={style.badge}>{done ? <Icon /> : <span>{value}</span>}</span>
        <span className={style.label}>{label}</span>
      </button>
    </div>
  </div>
)

export default Steps

Steps.propTypes = {
  active: PropTypes.number.isRequired,
  className: PropTypes.string,
  isDone: PropTypes.bool,
  navigation: PropTypes.array.isRequired,
  setActive: PropTypes.func.isRequired,
  validator: PropTypes.func
}

Step.propTypes = {
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  done: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired
}
