import { useEffect } from 'react'

import useNavigation from '../../hooks/useNavigation'
import Prizes from '../modules/Posts/Prizes'

const PrizesRoute = () => {
  const { setNavigation } = useNavigation()

  useEffect(() => {
    setNavigation({
      title: '¡Gana premios!',
      isSecondary: true,
      hasBack: true
    })
  }, [setNavigation])

  return <Prizes />
}

export default PrizesRoute
