import { useEffect, useState, useRef, useCallback } from 'react'
import style from './Filter.module.scss'

import groupBy from 'lodash/groupBy'

const Filter = ({ players, onFilter, disabledTeams }) => {
  const [teams, setTeams] = useState([])
  const [selectedTeams, setSelectedTeams] = useState([])
  const itemsRef = useRef(null)

  const reset = useCallback(() => {
    if (selectedTeams.length === teams.length) return

    setSelectedTeams(teams.map((team) => team.id))
  }, [selectedTeams, teams])

  const handleClick = (id) => {
    if (disabledTeams.includes(id)) return
    if (selectedTeams.length === 1) {
      if (selectedTeams.includes(id)) return
    }

    if (selectedTeams.length === teams.length || selectedTeams.length === (teams.length - disabledTeams.length)) return setSelectedTeams([id])

    if (selectedTeams.includes(id)) {
      setSelectedTeams(selectedTeams.filter((team) => team !== id))
    } else {
      setSelectedTeams([...selectedTeams, id])
    }
  }

  useEffect(() => {
    if (players) {
      const groupTeams = groupBy(players, (player) => player.teamId)
      const uniqueTeams = Object.keys(groupTeams).map((key) => ({
        id: groupTeams[key][0].teamId,
        logoURL: groupTeams[key][0].teamLogoURL,
        name: groupTeams[key][0].teamName
      }))
      uniqueTeams.sort((a, b) => a.name.localeCompare(b.name))
      setTeams(uniqueTeams)
    }
  }, [players])

  useEffect(() => {
    itemsRef?.current.scrollTo({ left: 0, behavior: 'auto' })
    setSelectedTeams(teams.map((team) => team.id))
  }, [teams])

  useEffect(() => {
    if (teams.length > 0) {
      if (selectedTeams.length) {
        const filteredPlayers = players.filter((player) => selectedTeams.includes(player.teamId))
        onFilter(filteredPlayers)
      }
    }
  }, [selectedTeams, onFilter, players, teams])

  useEffect(() => {
    if (disabledTeams.length > 0) {
      if (!selectedTeams.some((selected) => disabledTeams.includes(selected))) return

      let filteredSelectedTeams = [...selectedTeams]
      filteredSelectedTeams = filteredSelectedTeams.filter((selected) => !disabledTeams.includes(selected))
      setSelectedTeams(filteredSelectedTeams)
    }
  }, [disabledTeams, selectedTeams])

  return (
    <div className={style.container}>
      <div className={style.content} onClick={reset}>
        <div className={style.label}>Equipos</div>
        <div className={`${style.value} ${selectedTeams.length === teams.length ? style.active : ''}`}>Todos</div>
      </div>
      <div className={style.scroller}>
        <div className={style.crop}>
          <div className={style.scrollerContent} ref={itemsRef}>
            <div className={style.items}>
              {teams.map((team) => (
                <Item
                  key={team.id}
                  {...team}
                  onClick={handleClick}
                  active={selectedTeams.includes(team.id)}
                  disabled={disabledTeams.includes(team.id)}
                />
              ))}
              <div className={style.item}>0</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Item = ({ id, logoURL, name, active, disabled, onClick }) => (
  <div
    className={`${style.item} ${active ? style.active : ''} ${disabled ? style.disabled : ''}`}
    title={name}
    role='button'
    onClick={() => onClick(id)}
  >
    <div className={style.logo}>
      {logoURL ? <img src={logoURL} alt={name} /> : <span>{name}</span>}
    </div>
  </div>
)

export default Filter
