import getHeaders from './headers'

const BASE_URL = process.env.NODE_ENV === 'development' ? 'http://dev-api.astrogool.com/api/' : 'https://api.astrogool.com/api/'

const get = async (resource) => {
  try {
    const headers = await getHeaders()
    const res = await fetch(`${BASE_URL}${resource}`, {
      method: 'GET',
      headers
    })
    if (res.status === 404) {
      return 404
    } else if (res.status === 204) {
      return 204
    } else if (res.ok) {
      const body = await res.json()
      if (body.results) {
        return body.results
      }
      if (body.data) {
        return body.data
      }
      return body
    }
    throw new Error(res.statusText)
  } catch (err) {
    console.error(err)
    throw err
  }
}

const put = async (resource, data) => {
  try {
    const headers = await getHeaders()
    const res = await fetch(`${BASE_URL}${resource}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(data)
    })
    if (res.ok) {
      const body = await res.json()
      return body
    }
    throw new Error(res.statusText)
  } catch (err) {
    console.error(err)
    throw err
  }
}

const apiProvider = {
  get,
  put
}

export default apiProvider
