import { useState, useCallback } from 'react'

import { apiFantasy } from '../api'

const useCreateTeam = () => {
  const [isSaving, setIsSaving] = useState(false)

  const createTeam = useCallback((data, id) => {
    return new Promise((resolve, reject) => {
      setIsSaving(true)

      const { formation, players, captain } = data
      const dataObject = {
        alineation: formation.id,
        captain: captain.id,
        gatekeeper: players.goalkeeper.id,
        back: players.defenders.map((player) => player.id),
        middle: players.midfielders.map((player) => player.id),
        front: players.forwards.map((player) => player.id)
      }

      apiFantasy.createTeam(dataObject, id)
        .then(() => {
          resolve()
        })
        .catch((err) => {
          console.error(err)
          reject(err)
        })
        .finally(() => {
          setIsSaving(false)
        })
    })
  }, [])

  return { createTeam, isSaving }
}

export default useCreateTeam
