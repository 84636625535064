import style from './index.module.scss'

import Heading from '../../commons/Heading'
import Section from '../../commons/Section'

import imgSponsor1 from '../../../assets/images/sponsor-la_ola.png'
import imgSponsor2 from '../../../assets/images/sponsor-wipizz.png'
import imgSponsor3 from '../../../assets/images/sponsor-la_doce.png'
import imgSponsor4 from '../../../assets/images/sponsor-la_aleteria.png'

import { ReactComponent as Logo } from '../../../assets/images/logotipo.svg'

const Prizes = () => {
  return (
    <Section className={style.container}>
      <Heading.Display>¡AstroGool está a punto de volverse aún más emocionante!</Heading.Display>
      <div className={style.content}>
        <p>A partir de esta temporada, <b>premiaremos a los 4 primeros lugares de cada una de las 17 jornadas</b> del torneo.</p>
        <p>Cada jornada es una nueva oportunidad para brillar y llevarte fantásticos premios <b>gracias a nuestros patrocinadores:</b></p>
        <div className={style.sponsors}>
          <div className={style.sponsor}>
            <img src={imgSponsor1} alt='La Ola' />
          </div>
          <div className={style.sponsor}>
            <img src={imgSponsor2} alt='Wipizz' />
          </div>
          <div className={style.sponsor}>
            <img src={imgSponsor3} alt='La Doce' />
          </div>
          <div className={style.sponsor}>
            <img src={imgSponsor4} alt='La Aletería' />
          </div>
        </div>
      </div>
      <ul className={style.prizes}>
        <li>🏆 Premios para los 4 primeros lugares de cada jornada</li>
        <li>⚽ 17 jornadas llenas de emoción y competencia</li>
        <li>🎁 Increíbles recompensas por tu destreza estratégica</li>
      </ul>
      <div className={style.content}>
        <p>¡No pierdas la oportunidad de ser el próximo campeón de AstroGool! Únete a la competencia, arma tu equipo, y prepárate para conquistar la cima. ¡El próximo ganador podrías ser tú!</p>
        <div className={style.footer}>
          <div className={style.logo}><Logo /></div>
          <h2>¡Juega y gana como los grandes!</h2>
        </div>
      </div>
    </Section>
  )
}

export default Prizes
