import { createContext, useReducer, useCallback } from 'react'

const ToastContext = createContext()
const { Provider } = ToastContext

const TIMEOUT_DURATION = 3000
const initialState = {
  type: '',
  title: '',
  message: '',
  show: false
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'show_success':
      return {
        type: 'success',
        title: action.value.title,
        message: action.value.message,
        show: true
      }
    case 'show_error':
      return {
        type: 'error',
        title: action.value.title,
        message: action.value.message,
        show: true
      }
    case 'hide':
      return {
        ...state,
        show: false
      }
    case 'clear':
      return initialState
    default:
      break
  }
  throw Error('Acción desconocida: ' + action.type)
}

const ToastProvider = ({ children }) => {
  const [toast, dispatch] = useReducer(reducer, initialState)

  const success = useCallback(({ title, message }) => {
    dispatch({
      type: 'show_success',
      value: { title, message }
    })
    setTimeout(() => dispatch({ type: 'hide' }), TIMEOUT_DURATION)
  }, [])

  const error = useCallback(({ title, message }) => {
    dispatch({
      type: 'show_error',
      value: { title, message }
    })
    setTimeout(() => dispatch({ type: 'hide' }), TIMEOUT_DURATION)
  }, [])

  const clear = useCallback(() => {
    dispatch({ type: 'clear' })
  }, [])

  const value = { toast, success, error, clear }

  return <Provider value={value}>{children}</Provider>
}

export { ToastContext, ToastProvider }
