import style from './Auth.module.scss'
import { ReactComponent as Logotipo } from '../../assets/images/logotipo.svg'

import { Outlet, useNavigate, useLocation } from 'react-router-dom'

import Tabs from '../commons/Tabs'
import Toast from './Toast'

const tabNavigation = [
  {
    label: 'Entrar',
    key: 'login'
  },
  {
    label: 'Registrarse',
    key: 'signup'
  }
]

const Auth = () => {
  const location = useLocation()
  const navigate = useNavigate()

  return (
    <div className={style.container}>
      <div className={style.header}>
        <div className={style.logotipo}>
          <Logotipo />
        </div>
      </div>
      <div className={style.content}>
        <Tabs className={style.tabs}>
          {tabNavigation.map((item) => (
            <Tabs.Item
              key={item.key}
              label={item.label}
              active={location.pathname === '/auth/' + item.key}
              onClick={() => navigate('/auth/' + item.key)}
            />
          ))}
        </Tabs>
        <Outlet />
        <div className={style.legal}>
          <p>©{new Date().getFullYear()} AstroGool</p>
          <p><a href='/terms' target='_blank' rel='noopener noreferrer'>Términos y condiciones</a> | <a href='/privacy' target='_blank' rel='noopener noreferrer'>Política de privacidad</a> | <a href='/cookies' target='_blank' rel='noopener noreferrer'>Política de cookies</a></p>
        </div>
      </div>
      <Toast />
    </div>
  )
}

export default Auth
