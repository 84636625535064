import useSWR from 'swr'
import { useMemo } from 'react'

import { apiFantasy } from '../api'

const usePoints = (id) => {
  const { data, error, isLoading } = useSWR(['getPoints', id], () => apiFantasy.getPoints(id))

  const team = useMemo(() => {
    if (!isLoading && !error) {
      if (data === 404) {
        return 404
      }
      return {
        name: data.coach?.team_name,
        dtName: data.coach?.name,
        formation: data.alineation,
        points: data.points,
        cost: data.cost,
        players: {
          defenders: data.back.map((player) => ({
            fullName: player.full_name,
            id: player.id,
            name: player.short_name,
            number: player.number,
            position: player.position,
            price: player.cost,
            teamName: player.team?.name,
            teamId: player.team?.id,
            teamLogoURL: player.team?.logo,
            colorPrimary: player.team?.color_primary,
            colorSecondary: player.team?.color_secondary,
            type: player.team?.shirt_type,
            points: player.points
          })),
          forwards: data.front.map((player) => ({
            fullName: player.full_name,
            id: player.id,
            name: player.short_name,
            number: player.number,
            position: player.position,
            price: player.cost,
            teamName: player.team?.name,
            teamId: player.team?.id,
            teamLogoURL: player.team?.logo,
            colorPrimary: player.team?.color_primary,
            colorSecondary: player.team?.color_secondary,
            type: player.team?.shirt_type,
            points: player.points
          })),
          goalkeeper: {
            fullName: data.gatekeeper.full_name,
            id: data.gatekeeper.id,
            name: data.gatekeeper.short_name,
            number: data.gatekeeper.number,
            position: data.gatekeeper.position,
            price: data.gatekeeper.cost,
            teamName: data.gatekeeper.team?.name,
            teamId: data.gatekeeper.team?.id,
            teamLogoURL: data.gatekeeper.team?.logo,
            colorPrimary: data.gatekeeper.team?.color_primary,
            colorSecondary: data.gatekeeper.team?.color_secondary,
            type: data.gatekeeper.team?.shirt_type,
            points: data.gatekeeper.points
          },
          midfielders: data.middle.map((player) => ({
            fullName: player.full_name,
            id: player.id,
            name: player.short_name,
            number: player.number,
            position: player.position,
            price: player.cost,
            teamName: player.team?.name,
            teamId: player.team?.id,
            teamLogoURL: player.team?.logo,
            colorPrimary: player.team?.color_primary,
            colorSecondary: player.team?.color_secondary,
            type: player.team?.shirt_type,
            points: player.points
          }))
        },
        captain: {
          fullName: data.captain.full_name,
          id: data.captain.id,
          name: data.captain.short_name,
          number: data.captain.number,
          photoURL: data.captain.photo,
          position: data.captain.position,
          price: data.captain.cost,
          teamName: data.captain.team?.name,
          teamId: data.captain.team?.id,
          teamLogoURL: data.captain.team?.logo
        }
      }
    }
    return null
  }, [data, error, isLoading])

  return { data: team, error, isLoading }
}

export default usePoints
