import useSWR from 'swr'
import { useMemo } from 'react'

import { apiCompetitions } from '../api'
import { dateToLocale } from '../utils'

const useCompetition = (id) => {
  const competition = useSWR(['getCompetition', id], () => apiCompetitions.get(id))
  const info = useSWR(['info', id], () => apiCompetitions.getInfo(id))

  const error = competition.error || info.error
  const isLoading = !(!competition.isLoading && !info.isLoading)

  const data = useMemo(() => {
    if (!isLoading && !error) {
      if (competition.data === 404) {
        return 404
      } else {
        let data = {
          id: competition.data.id,
          leagueId: competition.data.league.id,
          leagueName: competition.data.league.name,
          seasonId: competition.data.season.id,
          seasonName: competition.data.season.name,
          currentRoundName: competition.data.current_round?.name,
          currentRoundDate: dateToLocale(competition.data.current_round?.start_date),
          mode: competition.data.mode === 'tournament' ? 'Torneo' : competition.data.mode,
          players: competition.data.players
        }
        if (info.data.points !== null) {
          data = {
            ...data,
            enrolled: true,
            points: info.data.points,
            position: info.data.position,
            teams: info.data.rounds_alineations?.map((team) => (
              {
                id: team.id,
                roundId: team.round,
                formation: team.alineation,
                score: team.points,
                position: team.position
              }
            ))
          }
        }
        return data
      }
    }
    return null
  }, [competition.data, info.data, error, isLoading])

  return { data, error, isLoading }
}

export default useCompetition
