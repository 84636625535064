import { useState, createContext, useCallback } from 'react'

const NavigationContext = createContext()
const { Provider } = NavigationContext

const initialState = {
  title: null,
  hasBack: false,
  isSecondary: false
}

const NavigationProvider = ({ children }) => {
  const [navigation, setNavigation] = useState(initialState)

  const set = useCallback(({ title = null, hasBack = false, isSecondary = false }) => {
    setNavigation({
      title,
      hasBack,
      isSecondary
    })
  }, [])

  const value = [navigation, set]

  return <Provider value={value}>{children}</Provider>
}

export { NavigationContext, NavigationProvider }
