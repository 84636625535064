import style from './index.module.scss'

import { useState } from 'react'
import PropTypes from 'prop-types'

import Header from './Header'
import { NotFound, ServerError, Forbidden } from '../Errors'
import PlayingField from '../PlayingField'
import Shirt from '../Shirt'
import Window from '../../commons/Window'
import Help from '../Help'

import usePoints from '../../../hooks/usePoints'

const Team = ({ id }) => {
  const { data: team, isLoading } = usePoints(id)
  const [showFAQ, setShowFAQ] = useState(false)

  return (
    <div className={style.container}>
      <Header
        loading={isLoading}
        team={team}
        showFAQ={() => setShowFAQ(true)}
      />
      {isLoading
        ? null
        : team === 404
          ? <NotFound />
          : team === null
            ? <ServerError />
            : team === 403
              ? <Forbidden />
              : <Content team={team} />}
      <Window
        show={showFAQ}
        close={() => setShowFAQ(false)}
      >
        <Help />
      </Window>
    </div>
  )
}

const Content = ({ team }) => {
  return (
    <PlayingField>
      <PlayingField.Goalkeeper>
        <Shirt
          player={team.players.goalkeeper}
          captain={team.captain.id === team.players.goalkeeper.id}
        />
      </PlayingField.Goalkeeper>
      <PlayingField.Defenders>
        {team.players.defenders.map((player, i) => (
          <Shirt
            key={`defenders-${i}`}
            player={player}
            captain={team.captain.id === player.id}
          />
        ))}
      </PlayingField.Defenders>
      <PlayingField.Midfielders>
        {team.players.midfielders.map((player, i) => (
          <Shirt
            key={`midfielders-${i}`}
            player={player}
            captain={team.captain.id === player.id}
          />
        ))}
      </PlayingField.Midfielders>
      <PlayingField.Forwards>
        {team.players.forwards.map((player, i) => (
          <Shirt
            key={`forwards-${i}`}
            player={player}
            captain={team.captain.id === player.id}
          />
        ))}
      </PlayingField.Forwards>
    </PlayingField>
  )
}

export default Team

Team.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
}

Content.propTypes = {
  team: PropTypes.object.isRequired
}
