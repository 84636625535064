import style from './ResetPassword.module.scss'

import { useState } from 'react'

import Button from '../../commons/Button'
import Input from '../../commons/Input'

import useAuth from '../../../hooks/useAuth'
import useToast from '../../../hooks/useToast'

const ResetPassword = ({ close }) => {
  const [errors, setErrors] = useState(null)
  const [success, setSuccess] = useState(false)
  const auth = useAuth()
  const toast = useToast()

  const handleBlur = (ev) => {
    const value = ev.target.value

    if (value.trim() === '') {
      setErrors(null)
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
      setErrors('Este correo no es un email válido')
    } else {
      setErrors(null)
    }
  }

  const handleSubmit = (ev) => {
    ev.preventDefault()
    const email = ev.target.elements.email.value
    if (email.trim() === '') {
      setErrors('No olvides tu email')
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      setErrors('Este correo no es un email válido')
    } else {
      setErrors(null)
      auth.resetPassword(email)
        .then(() => {
          setSuccess(true)
        })
        .catch((err) => {
          if (err.field) {
            setErrors(err.message)
          } else {
            toast.error({
              title: err.code,
              message: err.message
            })
          }
        })
    }
  }

  return (
    <div className={style.container}>
      {
      !success
        ? (
          <div className={style.content}>
            <h1 className={style.title}>¿Olvidaste tu contraseña?</h1>
            <p>No te preocupes. Introduce el correo electrónico asociado a tu cuenta para recuperarla.</p>
            <form onSubmit={handleSubmit}>
              <Input
                type='email'
                label='Correo'
                name='email'
                placeholder='Ingresa tu correo'
                error={errors}
                onBlur={handleBlur}
                light
              />
              <Button
                type='submit'
                loading={auth.loading}
              >
                Recuperar contraseña
              </Button>
              <div className={style.link}>
                <span
                  onClick={close}
                >
                  Regresar a iniciar sesión
                </span>
              </div>
            </form>
          </div>
          )
        : <Success onClick={close} />
      }
    </div>
  )
}

const Success = ({ onClick }) => (
  <div className={style.content}>
    <h1 className={style.title}>¡Revisa tu correo!</h1>
    <div className={style.row}>
      <p>Un correo de recuperación <b>se ha enviado a tu email</b>.</p>
      <p className={style.small}>Si no ves el correo en la bandeja de entrada, prueba en la de correo no deseado.</p>
    </div>
    <Button onClick={onClick}>Regresar a iniciar sesión</Button>
  </div>
)

export default ResetPassword
