import useSWR from 'swr'
import { useMemo } from 'react'

import { apiCompetitions } from '../api'
import { dateToLocale } from '../utils'

const useCompetitions = () => {
  const competitions = useSWR('get', () => apiCompetitions.get())
  const enrolled = useSWR('enrolled', () => apiCompetitions.enrolled())

  const error = competitions.error || enrolled.error
  const isLoading = !(!competitions.isLoading && !enrolled.isLoading)

  const data = useMemo(() => {
    let all = []
    const my = []
    const myFinished = []
    if (!isLoading && !error) {
      if (Array.isArray(competitions.data)) {
        competitions.data.forEach((item) => {
          if (item.active) {
            all.push({
              id: item.id,
              leagueId: item.league.id,
              leagueName: item.league.name,
              seasonId: item.season.id,
              seasonName: item.season.name,
              currentRoundName: item.current_round?.name,
              currentRoundDate: dateToLocale(item.current_round?.start_date),
              mode: item.mode === 'tournament' ? 'Torneo' : item.mode,
              players: item.players
            })
          }
        })
      }
      if (Array.isArray(enrolled.data)) {
        enrolled.data.forEach((item) => {
          if (item.active) {
            my.push({
              id: item.id,
              leagueId: item.league.id,
              leagueName: item.league.name,
              seasonId: item.season.id,
              seasonName: item.season.name,
              currentRoundName: item.current_round?.name,
              currentRoundDate: dateToLocale(item.current_round?.start_date),
              mode: item.mode === 'tournament' ? 'Torneo' : item.mode,
              players: item.players,
              position: item.position,
              points: item.points
            })
          } else {
            myFinished.push({
              id: item.id,
              leagueId: item.league.id,
              leagueName: item.league.name,
              seasonId: item.season.id,
              seasonName: item.season.name,
              mode: item.mode === 'tournament' ? 'Torneo' : item.mode,
              players: item.players,
              position: item.position,
              points: item.points
            })
          }
          all = all.filter((i) => i.id !== item.id)
        })
      }
    }
    return { all, my, myFinished }
  }, [competitions.data, enrolled.data, error, isLoading])

  return { data, error, isLoading }
}

export default useCompetitions
