import {
  createContext,
  useReducer,
  useEffect
} from 'react'

const TeamContext = createContext()
const { Provider } = TeamContext

const initialState = {
  formation: null,
  players: null,
  captain: null,
  id: null,
  budget: null,
  hasBonus: false,
  isSaved: false
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'set_id':
      return {
        ...state,
        id: action.value
      }
    case 'set_formation':
      if (action.value.id === state.formation?.id) return state
      return {
        ...state,
        formation: action.value,
        players: {
          goalkeeper: null,
          defenders: Array.apply(null, Array(action.value.defenders)).map(() => null),
          midfielders: Array.apply(null, Array(action.value.midfielders)).map(() => null),
          forwards: Array.apply(null, Array(action.value.forwards)).map(() => null)
        },
        captain: null
      }
    case 'set_players':
      return {
        ...state,
        players: action.value,
        captain: Object.values(action.value).flat().find(player => player?.id === state.captain?.id) || null
      }
    case 'set_captain':
      return {
        ...state,
        captain: action.value
      }
    case 'set_budget':
      return {
        ...state,
        budget: action.value
      }
    case 'set_bonus':
      return {
        ...state,
        hasBonus: action.value
      }
    case 'set':
      return {
        ...action.value
      }
    case 'remove':
      window.localStorage.removeItem('savedTeam')
      return state
    case 'reset':
      window.localStorage.removeItem('savedTeam')
      return initialState
    default:
      break
  }
  throw Error('Acción desconocida: ' + action.type)
}

const TeamProvider = ({ children }) => {
  const [team, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    if (team.id) {
      if (team.isSaved) return

      const tempTeam = window.localStorage.getItem('tempTeam' + team.id)
      if (tempTeam) {
        const localTeam = JSON.parse(tempTeam)
        dispatch({ type: 'set', value: localTeam })
      }
    }
  }, [team.id, team.isSaved])

  useEffect(() => {
    if (team.id && !team.isSaved) {
      window.localStorage.setItem('tempTeam' + team.id, JSON.stringify(team))
    }
  }, [team])

  return <Provider value={[team, dispatch]}>{children}</Provider>
}

export { TeamContext, TeamProvider }
