import style from './Input.module.scss'
import { ReactComponent as IconEye } from '../../assets/images/icon_eye.svg'
import { ReactComponent as IconEyeOff } from '../../assets/images/icon_eye-off.svg'
import { ReactComponent as IconSearch } from '../../assets/images/icon_search.svg'
import { ReactComponent as IconClose } from '../../assets/images/icon_close.svg'

import { useRef, useState } from 'react'
import PropTypes from 'prop-types'

const Input = ({ type = 'text', label, placeholder, name, error = null, onBlur, onChange, value, defaultValue, className }) => {
  return (
    <div className={`${style.container} ${error ? style.hasError : ''} ${className || ''}`}>
      <label className={style.content}>
        <span className={style.label}>{label}</span>
        <div className={style.input}>
          <input
            type={type}
            name={name}
            placeholder={placeholder}
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            defaultValue={defaultValue}
          />
        </div>
        {error &&
          <div className={style.feedback}>
            <span className={style.error}>{error}</span>
            <span className={style.bounds} />
          </div>}
      </label>
      {error && <div className={style.marginOffset} />}
    </div>
  )
}

const Password = ({ label, placeholder, name, error = null, onBlur, onChange, value, className }) => {
  const [showPassword, setShowPassword] = useState(false)

  return (
    <div className={`${style.container} ${error ? style.hasError : ''} ${className || ''}`}>
      <label className={style.content}>
        <span className={style.label}>{label}</span>
        <div className={style.input}>
          <input
            type={showPassword ? 'text' : 'password'}
            name={name}
            placeholder={placeholder}
            onBlur={onBlur}
            onChange={onChange}
            value={value}
          />
          <span className={style.showPassword} onClick={() => setShowPassword(!showPassword)}>{showPassword ? <IconEyeOff /> : <IconEye />}</span>
        </div>
        {error &&
          <div className={style.feedback}>
            <span className={style.error}>{error}</span>
            <span className={style.bounds} />
          </div>}
      </label>
      {error && <div className={style.marginOffset} />}
    </div>
  )
}

const Search = ({ placeholder, name, onBlur, onChange, onClear, className }) => {
  const inputRef = useRef(null)

  return (
    <div className={`${style.container} ${className || ''}`}>
      <div className={style.input}>
        <input
          ref={inputRef}
          type='search'
          name={name}
          placeholder={placeholder}
          onBlur={onBlur}
          onChange={onChange}
        />
        <span className={style.clearIcon} onClick={() => { inputRef.current.value = ''; onClear() }}><IconClose /></span>
        <span className={style.searchIcon}><IconSearch /></span>
      </div>
    </div>
  )
}

Input.Password = Password
Input.Search = Search

export default Input

Input.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string
}

Password.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string
}

Search.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  placeholder: PropTypes.string
}
