import style from './TableStandings.module.scss'

import { useEffect } from 'react'
import PropTypes from 'prop-types'

import Heading from '../commons/Heading'
import Section from '../commons/Section'
import Skeleton from '../commons/Skeleton'
import CardPosition from './CardPosition'

import useStandings from '../../hooks/useStandings'
import useUser from '../../hooks/useUser'
import useToast from '../../hooks/useToast'

const TableStandings = ({ competitionId, roundId, team, setRoute }) => {
  const { data: standings, error, isLoading } = useStandings(competitionId, roundId)
  const [user] = useUser()
  const { error: toast } = useToast()
  const { userId } = user

  useEffect(() => {
    if (error) {
      toast({
        title: 'Ocurrió un error al cargar los puntajes',
        message: error.message
      })
    }
  }, [error, toast])

  return (
    <Section className={style.container}>
      <div className={style.fixed}>
        {team?.formation && (
          <div className={style.card}>
            <CardPosition.Main
              {...team}
              photoURL={user.photoURL}
              onClick={() => setRoute(`/app/team/${team.id}`)}
            />
          </div>
        )}
        <div className={style.header}>
          <span className={style.position}>Posición</span>
          <span className={style.score}>Puntos</span>
        </div>
      </div>
      <div className={style.content}>
        {isLoading
          ? (
            <>
              <Skeleton className={style.skeleton} />
              <Skeleton className={style.skeleton} />
              <Skeleton className={style.skeleton} />
              <Skeleton className={style.skeleton} />
              <Skeleton className={style.skeleton} />
              <Skeleton className={style.skeleton} />
              <Skeleton className={style.skeleton} />
              <Skeleton className={style.skeleton} />
            </>
            )
          : standings === 204 || standings === null
            ? (
              <div className={style.empty}>
                <h3>La tabla de posiciones aún no se define</h3>
                <p>Te esperamos de vuelta más tarde.</p>
              </div>
              )
            : standings.map((item) => (
              <CardPosition
                key={item.position}
                current={userId === item.id}
                disabled={!item.hasTeam}
                onClick={setRoute}
                {...item}
              />)
            )}
      </div>
    </Section>
  )
}

const TableStandingsPreview = ({ competitionId }) => {
  const { data: standings, error, isLoading } = useStandings(competitionId)
  const { error: toast } = useToast()

  useEffect(() => {
    if (error) {
      toast({
        title: 'Ocurrió un error al cargar los puntajes',
        message: error.message
      })
    }
  }, [error, toast])

  return (
    <Section className={`${style.container} ${style.preview}`}>
      <Heading
        title='Participantes'
      />
      <div className={style.header}>
        <span className={style.position}>Posición</span>
        <span className={style.score}>Puntos</span>
      </div>
      <div className={style.content}>
        {isLoading
          ? (
            <>
              <Skeleton className={style.skeleton} />
              <Skeleton className={style.skeleton} />
              <Skeleton className={style.skeleton} />
              <Skeleton className={style.skeleton} />
              <Skeleton className={style.skeleton} />
              <Skeleton className={style.skeleton} />
              <Skeleton className={style.skeleton} />
              <Skeleton className={style.skeleton} />
            </>
            )
          : standings === 204 || standings === null
            ? (
              <div className={style.empty}>
                <h3>La tabla de posiciones aún no se define</h3>
                <p>Te esperamos de vuelta más tarde.</p>
              </div>
              )
            : standings.map((item) => <CardPosition key={item.position} {...item} />)}
      </div>
    </Section>
  )
}

TableStandings.Preview = TableStandingsPreview

export default TableStandings

TableStandings.propTypes = {
  competitionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  roundId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  team: PropTypes.object,
  setRoute: PropTypes.func
}

TableStandingsPreview.propTypes = {
  competitionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
}
