import style from './Skeleton.module.scss'

import PropTypes from 'prop-types'

const Skeleton = ({ className }) => {
  return (
    <div className={`${style.container} ${className || ''}`} />
  )
}

export default Skeleton

Skeleton.propTypes = {
  className: PropTypes.string
}
