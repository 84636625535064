import style from './DeleteAccount.module.scss'

import { useState } from 'react'
import PropTypes from 'prop-types'

import image from '../../../assets/images/img_warning.svg'

import Button from '../../commons/Button'
import Modal from '../../commons/Modal'
import Reauthenticate from '../Reauthenticate'

import useDeleteAccount from '../../../hooks/useDeleteAccount'
import useToast from '../../../hooks/useToast'
import useAuth from '../../../hooks/useAuth'

const DeleteAccount = ({ onCancel }) => {
  const [deleteAccount, deleting] = useDeleteAccount()
  const [showReauthentication, setShowReauthentication] = useState(false)
  const toast = useToast()
  const auth = useAuth()

  const handleSubmit = (ev) => {
    ev.preventDefault()
    deleteAccount()
      .then((requiresRecentLogin) => {
        if (requiresRecentLogin) {
          setShowReauthentication(true)
        }
        auth.signOut()
      })
      .catch((err) => {
        toast.error({
          title: 'No se pudo eliminar la cuenta',
          message: err.message
        })
      })
  }

  const handleReauth = () => {
    deleteAccount()
      .catch((err) => {
        toast.error({
          title: 'No se pudo eliminar la cuenta',
          message: err.message
        })
      })
  }

  return (
    <section className={style.container}>
      <div className={style.graphic}>
        <img src={image} alt='Copa' />
      </div>
      <h1 className={style.title}>Eliminar cuenta</h1>
      <div className={style.content}>
        <form className={style.form} onSubmit={handleSubmit}>
          <div className={style.description}>Estás a punto de <b>eliminar permanentemente tu cuenta de AstroGool</b>. Esto significa que toda la información asociada, incluyendo tus datos de perfil, historial de juego y cualquier otro registro que hayas creado, se eliminarán por completo y no podrán ser recuperados. ¿Estás seguro?</div>
          <div className={style.actions}>
            <Button
              onClick={onCancel}
            >
              No, conservar mi cuenta
            </Button>
            <Button
              className={style.danger}
              type='submit'
              loading={deleting}
              secondary
            >
              Si, eliminar mi cuenta
            </Button>
          </div>
        </form>
      </div>
      <Modal
        show={showReauthentication}
        close={() => setShowReauthentication({ ...showReauthentication, show: false })}
        handleClose={() => setShowReauthentication(false)}
      >
        <Reauthenticate onReauth={handleReauth} />
      </Modal>
    </section>
  )
}

export default DeleteAccount

DeleteAccount.propTypes = {
  onCancel: PropTypes.func.isRequired
}
