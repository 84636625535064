import useSWR from 'swr'
import { useMemo } from 'react'

import { apiGame } from '../api'

const useFormations = () => {
  const { data, error, isLoading } = useSWR('getFormations', () => apiGame.getFormations())

  const formations = useMemo(() => {
    if (!isLoading && !error) {
      if (Array.isArray(data)) {
        return data.map((item) => ({
          id: item.id,
          name: item.name,
          defenders: item.position_defensa,
          midfielders: item.position_centro,
          forwards: item.position_delantero
        }))
      }
      return data
    }
    return null
  }, [data, error, isLoading])

  return { data: formations, error, isLoading }
}

export default useFormations
