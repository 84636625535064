import style from './NavBar.module.scss'
import { ReactComponent as IconHome } from '../../../assets/images/icon_home.svg'
import { ReactComponent as IconProfile } from '../../../assets/images/icon_profile.svg'
import { ReactComponent as IconCompetition } from '../../../assets/images/icon_competition.svg'

import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import useNavigation from '../../../hooks/useNavigation'

const links = [
  {
    path: '/app/home',
    icon: <IconHome />,
    name: 'Inicio'
  },
  {
    path: '/app/competitions',
    icon: <IconCompetition />,
    name: 'Competencias',
    activePaths: ['/app/tournament']
  },
  {
    path: '/app/profile',
    icon: <IconProfile />,
    name: 'Perfil'
  }
]

const NavBar = () => {
  const [current, setCurrent] = useState(null)
  const location = useLocation()
  const { navigation } = useNavigation()

  useEffect(() => {
    window.scroll(0, 0)
    const pathname = location.pathname
    links.forEach((link) => {
      if (pathname === link.path) {
        setCurrent(link.name)
      } else if (link.activePaths) {
        link.activePaths.forEach((path) => {
          if (pathname.includes(path)) {
            setCurrent(link.name)
          }
        })
      }
    })
  }, [location.pathname])

  return (
    <nav className={`${style.container} ${navigation.isSecondary ? style.secondary : ''}`}>
      {links.map(({ path, icon, name }, i) => (
        <Item
          key={'nav-link-' + i}
          path={path}
          icon={icon}
          current={current === name}
        >
          {name}
        </Item>
      ))}
    </nav>
  )
}

const Item = ({ children, icon, path, current }) => {
  const navigate = useNavigate()

  const handleClick = (ev) => {
    ev.preventDefault()
    navigate(path)
  }

  return (
    <a
      className={`${style.item} ${current ? style.isCurrent : ''}`}
      role='button'
      tabIndex={0}
      href={path}
      onClick={handleClick}
    >
      <span className={style.icon}>
        {icon}
      </span>
      <span className={style.label}>
        {children}
      </span>
    </a>
  )
}

export default NavBar
