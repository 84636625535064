import style from './Legal.module.scss'
import { ReactComponent as Logotipo } from '../../assets/images/logotipo.svg'

import { Outlet } from 'react-router-dom'

const Legal = () => {
  return (
    <div className={style.container}>
      <div className={style.header}>
        <div className={style.logo}>
          <Logotipo />
        </div>
      </div>
      <div className={style.content}>
        <Outlet />
      </div>
    </div>
  )
}

export default Legal
