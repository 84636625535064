import useSWR from 'swr'
import { useMemo } from 'react'

import { apiGame } from '../api'
import { dateToLocale } from '../utils'

const useRounds = (id) => {
  const { data, error, isLoading } = useSWR(['getRounds', id], () => apiGame.getRounds(id))

  const rounds = useMemo(() => {
    if (!isLoading && !error) {
      if (Array.isArray(data)) {
        const rounds = []
        data.forEach((item) => {
          if (item.status !== 'scheduled') {
            rounds.push({
              id: item.id,
              name: item.name,
              startDate: dateToLocale(item.start_date),
              status: item.status,
              active: item.active
            })
          }
        })
        rounds.sort((a, b) => {
          if (a.status !== 'finished' && b.status === 'finished') {
            return -1
          }
          if (b.status !== 'finished' && a.status === 'finished') {
            return 1
          }
          return b.name.localeCompare(a.name, 'en', { numeric: true })
        })
        return rounds
      }
      return data
    }
    return null
  }, [data, error, isLoading])

  return { data: rounds, error, isLoading }
}

export default useRounds
