import style from './Header.module.scss'
import { ReactComponent as IconUser } from '../../../assets/images/icon_profile.svg'
import { ReactComponent as IconCamera } from '../../../assets/images/icon_camera.svg'

import { useRef } from 'react'
import PropTypes from 'prop-types'

const Header = ({ name, team, photoURL, onPhotoClick }) => {
  const inputFileRef = useRef()

  const handleClick = (ev) => {
    ev.preventDefault()
    inputFileRef.current.click()
  }

  const handleChange = (ev) => {
    ev.preventDefault()
    const file = ev.target.files[0]
    if (file) {
      onPhotoClick(file)
    }
  }

  return (
    <div className={style.container}>
      <div className={style.content}>
        <div className={style.picture}>
          <div className={style.avatar}>
            {photoURL ? <img src={photoURL} alt='Avatar' /> : <IconUser />}
          </div>
          <button className={style.camera} onClick={handleClick}><IconCamera /></button>
        </div>
        <input
          className={style.input}
          type='file'
          onChange={handleChange}
          accept='image/*'
          ref={inputFileRef}
        />
        <div className={style.body}>
          <h1 className={style.title}>{`DT ${name}`}</h1>
          <div className={style.team}>{team ? <span>{`@${team}`}</span> : <em>@nombra a tu equipo</em>}</div>
        </div>
      </div>
    </div>
  )
}

export default Header

Header.propTypes = {
  name: PropTypes.string.isRequired,
  team: PropTypes.string,
  photoURL: PropTypes.string,
  onPhotoClick: PropTypes.func.isRequired
}
