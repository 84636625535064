import style from './Results.module.scss'
import image from '../../../assets/images/img_empty.svg'

import PropTypes from 'prop-types'

import CardPlayer from '../CardPlayer'

const Results = ({ players, onClick, astros }) => {
  return (
    <>
      {players === 204 || players === null
        ? (
          <div className={style.empty}>
            <h3>La selección de jugadores no está disponible</h3>
            <p>Inténtalo de nuevo mas tarde.</p>
          </div>
          )
        : players.length === 0
          ? (
            <div className={style.empty}>
              <div className={style.graphic}>
                <img src={image} alt='Vacío' />
              </div>
              <div className={style.text}>No hay jugadores que coincidan con tu búsqueda</div>
            </div>
            )
          : players.map((item) => (
            <CardPlayer
              key={item.id}
              {...item}
              disabled={astros < item.price}
              onClick={() => onClick(item)}
            />)
          )}
    </>
  )
}

export default Results

Results.propTypes = {
  players: PropTypes.oneOfType([PropTypes.array, PropTypes.number]).isRequired,
  onClick: PropTypes.func.isRequired,
  astros: PropTypes.number.isRequired
}
