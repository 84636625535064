import style from './Checkbox.module.scss'
import { ReactComponent as Icon } from '../../assets/images/icon_check.svg'

const Checkbox = ({ checked, onChange, children }) => {
  return (
    <label className={style.container}>
      <span className={`${style.checkbox} ${checked ? style.checked : ''}`}>
        {checked ? <Icon /> : null}
      </span>
      <input
        type='checkbox'
        hidden
        checked={checked}
        onChange={onChange}
      />
      {children}
    </label>
  )
}

export default Checkbox
