import style from './index.module.scss'

import { useEffect, useState, useRef } from 'react'

import Referral from './Referral'
import Skeleton from '../../commons/Skeleton'
import Heading from '../../commons/Heading'
import Section from '../../commons/Section'
import Modal from '../../commons/Modal'

import useUser from '../../../hooks/useUser'
import useToast from '../../../hooks/useToast'
import { apiCore } from '../../../api'

const Game = () => {
  const [showModal, setShowModal] = useState(false)
  const [user, dispatch] = useUser()
  const { gameIntents, isReady, inviteCode } = user
  const toast = useToast()
  const data = useRef(null)

  useEffect(() => {
    const handleMessage = (ev) => {
      if (ev.origin !== 'https://astrogool-game.web.app' && process.env.NODE_ENV !== 'development') return
      if (typeof ev.data !== 'object') return
      switch (ev.data.type) {
        case 'on-astros':
          return apiCore.saveGameResult(!!ev.data.win)
            .then((res) => {
              data.current = {
                astros: res.astros,
                gameIntents: res.game_intents
              }
            })
            .catch((err) => {
              console.error(err)
              toast.error({
                title: 'Ocurrió un error al guardar tu puntaje',
                message: err.message
              })
            })
        case 'on-end':
          dispatch({
            type: 'game_over',
            value: {
              astros: data.current.astros,
              gameIntents: data.current.gameIntents
            }
          })
          data.current = null
          break
        default:
          break
      }
    }

    if (gameIntents > 0) window.addEventListener('message', handleMessage)
    return () => {
      window.removeEventListener('message', handleMessage)
      if (data.current) {
        dispatch({
          type: 'game_over',
          value: {
            astros: data.current.astros,
            gameIntents: data.current.gameIntents
          }
        })
      }
    }
  }, [gameIntents, dispatch, toast])

  return (
    <Section>
      <Heading
        title='⚽️ ¡Tira el penal y gana! ⚽️'
        description='Gana 10 Millones de Astros anotando un Gol. Elije un balón con la dirección de tu tiro ¡suerte!'
      >
        <div className={style.lives}>
          {!isReady ? 'Cargando' : <span>Tiros: <b>{gameIntents}</b></span>}
          <AddButton
            onClick={() => setShowModal(true)}
          />
        </div>
      </Heading>
      {!isReady
        ? (
          <Skeleton className={style.skeleton} />
          )
        : gameIntents > 0
          ? (
            <iframe
              title='¡Tira el penal y gana!'
              src='https://astrogool-game.web.app/index.html?astros=1'
              className={style.iframe}
            />
            )
          : (
            <div className={style.referral}>
              <Referral url={`${window.location.origin}/auth/signup?invite_code=${inviteCode}`} />
            </div>
            )}
      <Modal
        show={showModal}
        close={() => setShowModal(false)}
        className={style.modal}
      >
        <Referral url={`${window.location.origin}/auth/signup?invite_code=${inviteCode}`} />
      </Modal>
    </Section>
  )
}

const AddButton = ({ onClick }) => (
  <button className={style.button} onClick={onClick}>
    <svg width='10' height='10' viewBox='0 0 10 10'>
      <path d='M9.79167 4.16667H5.83333V0.208333C5.83333 0.09375 5.73958 0 5.625 0H4.375C4.26042 0 4.16667 0.09375 4.16667 0.208333V4.16667H0.208333C0.09375 4.16667 0 4.26042 0 4.375V5.625C0 5.73958 0.09375 5.83333 0.208333 5.83333H4.16667V9.79167C4.16667 9.90625 4.26042 10 4.375 10H5.625C5.73958 10 5.83333 9.90625 5.83333 9.79167V5.83333H9.79167C9.90625 5.83333 10 5.73958 10 5.625V4.375C10 4.26042 9.90625 4.16667 9.79167 4.16667Z' />
    </svg>
  </button>
)

export default Game
