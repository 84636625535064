import style from './Nav.module.scss'

import PropTypes from 'prop-types'

const Nav = ({ current, setCurrent }) => {
  return (
    <ul className={style.container}>
      <li
        className={`${style.item} ${current === 0 && style.isActive}`}
        onClick={() => setCurrent(0)}
      >
        <span>1</span>
      </li>
      <li
        className={`${style.item} ${current === 1 && style.isActive}`}
        onClick={() => setCurrent(1)}
      >
        <span>2</span>
      </li>
      <li
        className={`${style.item} ${current === 2 && style.isActive}`}
        onClick={() => setCurrent(2)}
      >
        <span>3</span>
      </li>
    </ul>
  )
}

export default Nav

Nav.propTypes = {
  current: PropTypes.number.isRequired,
  setCurrent: PropTypes.func.isRequired
}
