import style from './index.module.scss'

import { useState, useEffect, useRef, useCallback } from 'react'
import debounce from 'lodash/debounce'
import debuur from 'lodash/deburr'
import toLower from 'lodash/toLower'
import groupBy from 'lodash/groupBy'
import PropTypes from 'prop-types'

import Header from './Header'
import Results from './Results'
import Skeleton from '../../commons/Skeleton'
import Input from '../../commons/Input'
import Sort from './Sort'
import Filter from './Filter'

import useSetTeam from '../../../hooks/useSetTeam'
import usePlayers from '../../../hooks/usePlayers'
import useToast from '../../../hooks/useToast'

const normalize = (value) => {
  return toLower(debuur(value))
}

const SORT_OPTIONS = [
  {
    label: 'Mayor precio',
    value: 'higherPrice'
  },
  {
    label: 'Menor precio',
    value: 'lowerPrice'
  },
  {
    label: 'Nombre A-Z',
    value: 'az'
  },
  {
    label: 'Nombre Z-A',
    value: 'za'
  }
]

const Players = ({ position, index, roundId, close, show }) => {
  const [team, dispatch] = useSetTeam()
  const { data: players, error, isLoading } = usePlayers(position, roundId, team?.players)
  const { error: toast } = useToast()
  const [searchResults, setSearchResults] = useState(null)
  const [filteredResults, setFilteredResults] = useState(null)
  const [showResults, setShowResults] = useState(false)
  const [selectedSortOption, setSelectedSortOption] = useState(SORT_OPTIONS[0])
  const [disabledTeams, setDisabledTeams] = useState([])
  const resultsRef = useRef(null)

  const handleClick = (player) => {
    const value = { ...team.players }
    if (position === 'goalkeeper') {
      value.goalkeeper = { ...player }
    } else {
      value[position][index] = { ...player }
    }
    dispatch({ type: 'set_players', value })
    close()
  }

  const handleSearch = (value) => {
    if (value.trim()) {
      const searchTerm = normalize(value)
      const results = players.filter((item) => normalize(item.fullName).includes(searchTerm))
      sortResults(results, selectedSortOption.value)
    } else {
      handleClear()
    }
  }

  const handleSort = (option) => {
    setSelectedSortOption(option)
    const results = searchResults ? [...searchResults] : [...players]
    sortResults(results, option.value)
  }

  const handleFilter = useCallback((filtered) => {
    setFilteredResults(filtered, selectedSortOption.value)
  }, [selectedSortOption])

  const handleClear = () => {
    sortResults([...players], selectedSortOption.value)
  }

  const handleChange = debounce((ev) => handleSearch(ev.target.value), 400)

  const sortResults = (results, sortBy) => {
    switch (sortBy) {
      case 'higherPrice':
        return setSearchResults(results.sort((a, b) => b.price - a.price))
      case 'lowerPrice':
        return setSearchResults(results.sort((a, b) => a.price - b.price))
      case 'az':
        return setSearchResults(results.sort((a, b) => a.fullName.localeCompare(b.fullName)))
      case 'za':
        return setSearchResults(results.sort((a, b) => b.fullName.localeCompare(a.fullName)))
      default:
        return setSearchResults(results)
    }
  }

  useEffect(() => {
    if (error) {
      toast({
        title: error.code,
        message: error.message
      })
    }
  }, [error, toast])

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        setShowResults(true)
      }, 320)
    } else {
      setShowResults(false)
    }
  }, [show])

  useEffect(() => {
    if (resultsRef.current) {
      resultsRef.current.scroll({ top: 0, behavior: 'auto' })
    }
  }, [searchResults, filteredResults])

  useEffect(() => {
    let players = team.players ? Object.values(team.players).flat() : []
    players = groupBy(players, (player) => player?.teamId)
    const teamsToDisable = []
    Object.keys(players).forEach((key) => {
      if (players[key].length > 3) teamsToDisable.push(Number(key))
    })
    setDisabledTeams(teamsToDisable)
  }, [team.players])

  return (
    <div className={style.container}>
      <Header
        position={position}
        astros={team.budget}
      />
      <Input.Search
        className={style.search}
        name='search'
        placeholder='Buscar jugador por nombre'
        onChange={handleChange}
        onClear={handleClear}
      />
      <div className={style.options}>
        <Sort
          options={SORT_OPTIONS}
          selected={selectedSortOption}
          onSelect={handleSort}
        />
        <Filter
          players={searchResults || players}
          onFilter={handleFilter}
          disabledTeams={disabledTeams}
        />
      </div>
      <div className={style.content}>
        <div className={style.crop}>
          <div className={style.results} ref={resultsRef}>
            {isLoading
              ? (
                <>
                  <Skeleton className={style.skeleton} />
                  <Skeleton className={style.skeleton} />
                  <Skeleton className={style.skeleton} />
                  <Skeleton className={style.skeleton} />
                  <Skeleton className={style.skeleton} />
                  <Skeleton className={style.skeleton} />
                  <Skeleton className={style.skeleton} />
                  <Skeleton className={style.skeleton} />
                  <Skeleton className={style.skeleton} />
                  <Skeleton className={style.skeleton} />
                </>
                )
              : showResults
                ? (
                  <Results
                    players={filteredResults || searchResults || players}
                    onClick={handleClick}
                    astros={team.budget}
                  />
                  )
                : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Players

Players.propTypes = {
  position: PropTypes.string.isRequired,
  index: PropTypes.number,
  roundId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  close: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
}
