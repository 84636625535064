import { useState, useCallback } from 'react'
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { updateProfile } from 'firebase/auth'
import { doc, setDoc } from 'firebase/firestore'

import { auth, db, storage } from '../lib/firebase'
import { apiCore } from '../api'

import useUser from './useUser'

const ROUTE = 'users'
const FILE_NAME = 'profile_photo.jpg'

const useUploadPhoto = () => {
  const [saving, setSaving] = useState(false)
  const [user, dispatch] = useUser()

  const uploadPhoto = useCallback((file) => {
    return new Promise((resolve, reject) => {
      setSaving(true)
      const storageRef = ref(storage, `${ROUTE}/${user.uid}/${FILE_NAME}`)
      const metadata = {
        contentType: 'image/jpeg'
      }
      let photoURL
      uploadBytes(storageRef, file, metadata)
        .then((snap) => {
          return getDownloadURL(snap.ref)
        })
        .then((url) => {
          photoURL = url
          return apiCore.updateProfile({ photo: url })
        })
        .then(() => {
          return setDoc(doc(db, 'users', user.uid), { photoURL }, { merge: true })
        })
        .then(() => {
          return updateProfile(auth.currentUser, { photoURL })
        })
        .then(() => {
          dispatch({
            type: 'change_photo',
            value: photoURL
          })
          resolve()
        })
        .catch((err) => {
          console.error(err)
          reject(err)
        })
        .finally(() => {
          setSaving(false)
        })
    })
  }, [user, dispatch])

  return [uploadPhoto, saving]
}

export default useUploadPhoto
