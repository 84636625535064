import style from './CardCompetition.module.scss'

import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

import { ReactComponent as IconForward } from '../../assets/images/icon_chevron-forward.svg'
import { ReactComponent as IconRank } from '../../assets/images/icon_rank.svg'

const CardCompetition = ({ signed, id, leagueName, seasonName, currentRoundName, currentRoundDate, mode, players, position, points, finished }) => {
  const navigate = useNavigate()

  return (
    <div className={style.container} onClick={() => navigate('/app/competition/' + id)}>
      <h3 className={style.title}>{leagueName + ' - ' + seasonName}</h3>
      <div className={style.round}>
        {finished
          ? <b>Competencia finalizada</b>
          : <><b>{currentRoundName}</b> | {currentRoundDate}</>}
      </div>
      <div className={style.footer}>
        <span>Modalidad: <b>{mode}</b></span>
        <span>Participantes: <b>{players}</b></span>
      </div>
      <div className={style.badge}>
        {signed || finished
          ? (
            <div className={`${style.rank} ${finished ? style.finished : ''}`}>
              <IconRank />
              <Rank
                label='Posición'
                value={position}
              />
              <Rank
                label='Puntaje'
                value={points}
              />
            </div>
            )
          : <div className={style.cta}>Ingresar a la liga</div>}
      </div>
      <div className={style.icon}>
        <IconForward />
      </div>
    </div>
  )
}

const Rank = ({ label, value }) => (
  <div className={style.rank_item}>
    <span className={style.label}>{label}</span>
    <span className={style.value}>{value === undefined ? 'NA' : value}</span>
  </div>
)

export default CardCompetition

CardCompetition.propTypes = {
  currentRoundDate: PropTypes.string,
  currentRoundName: PropTypes.string,
  id: PropTypes.number,
  leagueName: PropTypes.string,
  mode: PropTypes.string,
  players: PropTypes.number,
  seasonName: PropTypes.string,
  signed: PropTypes.bool
}
