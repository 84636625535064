import useSWR from 'swr'
import { useMemo } from 'react'

import { apiGame } from '../api'

const usePlayers = (position, roundId, currentPlayers) => {
  let parsedPosition
  switch (position) {
    case 'defenders':
      parsedPosition = 'defensa'
      break
    case 'midfielders':
      parsedPosition = 'centro'
      break
    case 'forwards':
      parsedPosition = 'delantero'
      break
    case 'goalkeeper':
      parsedPosition = 'portero'
      break
    default:
      break
  }
  const { data, error, isLoading } = useSWR(['getPlayers', { parsedPosition, roundId }], () => apiGame.getPlayers(parsedPosition, roundId))

  const players = useMemo(() => {
    if (!isLoading && !error) {
      const players = []
      if (Array.isArray(data)) {
        const idsToFilter = []
        if (position === 'goalkeeper' && currentPlayers.goalkeeper) {
          idsToFilter.push(currentPlayers.goalkeeper.id)
        } else if (position !== 'goalkeeper') {
          currentPlayers[position].forEach((item) => {
            if (item) idsToFilter.push(item.id)
          })
        }
        data.forEach((item) => {
          if (idsToFilter.includes(item.id)) return
          players.push({
            id: item.id,
            name: item.short_name,
            fullName: item.full_name,
            teamId: item.team.id,
            teamName: item.team.name,
            teamLogoURL: item.team.logo,
            colorPrimary: item.team.color_primary,
            colorSecondary: item.team.color_secondary,
            type: item.team.shirt_type,
            position: item.position,
            number: item.number,
            price: item.cost
          })
        })
        players.sort((a, b) => a.price - b.price)
        players.reverse()
      }
      return players
    }
    return null
  }, [data, error, isLoading, currentPlayers, position])

  return { data: players, error, isLoading }
}

export default usePlayers
