import style from './Rounds.module.scss'

import { useEffect } from 'react'
import PropTypes from 'prop-types'

import Section from '../../commons/Section'
import Skeleton from '../../commons/Skeleton'
import CardRound from '../../modules/CardRound'

import useRounds from '../../../hooks/useRounds'
import useToast from '../../../hooks/useToast'

const Rounds = ({ competitionId, seasonId, setRoute, teams }) => {
  const { data: rounds, error, isLoading } = useRounds(competitionId)
  const { error: toast } = useToast()

  useEffect(() => {
    if (error) {
      toast({
        title: 'Ocurrió un error al cargar las jornadas',
        message: error.message
      })
    }
  }, [error, toast])

  return (
    <Section className={style.container}>
      {isLoading
        ? (
          <>
            <Skeleton className={style.skeleton} />
            <Skeleton className={style.skeleton} />
            <Skeleton className={style.skeleton} />
            <Skeleton className={style.skeleton} />
            <Skeleton className={style.skeleton} />
            <Skeleton className={style.skeleton} />
          </>
          )
        : rounds === 204 || rounds === null
          ? (
            <div className={style.empty}>
              <h3>No hay jornadas disponibles para esta competición</h3>
              <p>Te esperamos de vuelta más tarde.</p>
            </div>
            )
          : (rounds.map((round) => (
            <CardRound
              key={round.id}
              onClick={() => setRoute(`/app/competition/${competitionId}/round/${round.id}`)}
              {...round}
              team={teams?.find((team) => team.roundId === round.id)}
            />
            ))
            )}
    </Section>
  )
}

export default Rounds

Rounds.propTypes = {
  competitionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  seasonId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setRoute: PropTypes.func.isRequired,
  teams: PropTypes.array
}
