import style from './Team.module.scss'
import { ReactComponent as IconAvatar } from '../../../assets/images/icon_avatar.svg'

import { Link } from 'react-router-dom'
import { useEffect } from 'react'
import PropTypes from 'prop-types'

import Skeleton from '../../commons/Skeleton'
import PlayingField from '../PlayingField'
import Shirt from '../Shirt'

import useTeam from '../../../hooks/useTeam'
import useToast from '../../../hooks/useToast'

const Team = ({ id }) => {
  const { data: team, error, isLoading } = useTeam(id)
  const { error: toast } = useToast()

  useEffect(() => {
    if (error) {
      toast({
        title: 'Ocurrió un error al cargar tu equipo',
        message: error.message
      })
    }
  }, [error, toast])

  return (
    <div className={style.container}>
      {isLoading
        ? (
          <>
            <Skeleton className={style.sk_header} />
            <Skeleton className={style.sk_field} />
          </>
          )
        : team === null
          ? (
            <div className={style.empty}>
              <h3>Algo no anda bien con la carga de tu equipo :(</h3>
              <p>Inténtalo de nuevo más tarde.</p>
            </div>
            )
          : team === 204
            ? (
              <div className={style.empty}>
                <h3>Sin equipo participante</h3>
                <p>Tu equipo no se presentó en esta jornada.</p>
                <p>¡Esperamos verte en la siguiente!</p>
              </div>
              )
            : <Content team={team} />}
    </div>
  )
}

const Content = ({ team }) => {
  return (
    <div className={style.content}>
      <div className={style.name}>{team.name ? team.name : <Link to='/app/profile'>¿Sin nombre de equipo?</Link>}</div>
      <div className={style.header}>
        <div className={style.captain}>
          <div className={style.photo}>
            {team.captain.photoURL ? <img src={team.captain.photoURL} alt={team.captain.name} /> : <IconAvatar />}
          </div>
          <div>
            <div className={style.label}>Capitán</div>
            <div className={style.captain_name}>{team.captain.fullName}</div>
          </div>
        </div>
        <div className={style.info}>
          <div className={style.meta}>
            <div className={style.label}>Formación</div>
            <div className={style.price}>{team.formation.name}</div>
          </div>
          <div className={style.meta}>
            <div className={style.label}>Costo</div>
            <div className={style.price}>${Object.values(team.players).flat().reduce((total, player) => total + (player?.price || 0), 0)}M</div>
          </div>
        </div>
      </div>
      <PlayingField>
        <PlayingField.Goalkeeper>
          <Shirt
            player={team.players.goalkeeper}
            captain={team.captain.id === team.players.goalkeeper.id}
          />
        </PlayingField.Goalkeeper>
        <PlayingField.Defenders>
          {team.players.defenders.map((player, i) => (
            <Shirt
              key={`defenders-${i}`}
              player={player}
              captain={team.captain.id === player.id}
            />
          ))}
        </PlayingField.Defenders>
        <PlayingField.Midfielders>
          {team.players.midfielders.map((player, i) => (
            <Shirt
              key={`midfielders-${i}`}
              player={player}
              captain={team.captain.id === player.id}
            />
          ))}
        </PlayingField.Midfielders>
        <PlayingField.Forwards>
          {team.players.forwards.map((player, i) => (
            <Shirt
              key={`forwards-${i}`}
              player={player}
              captain={team.captain.id === player.id}
            />
          ))}
        </PlayingField.Forwards>
      </PlayingField>
    </div>
  )
}

export default Team

Team.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
}

Content.propTypes = {
  team: PropTypes.object.isRequired
}
