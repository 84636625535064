import { useEffect } from 'react'

import logoSponsor1Src from '../../assets/images/sponsor-la_ola.png'
import logoSponsor2Src from '../../assets/images/sponsor-wipizz.png'
import logoSponsor3Src from '../../assets/images/sponsor-la_doce.png'
import logoSponsor4Src from '../../assets/images/sponsor-la_aleteria.png'
import imagePrize1Src from '../../assets/images/prize-1.jpg'
import imagePrize2Src from '../../assets/images/prize-2.jpg'
import imagePrize3Src from '../../assets/images/prize-3.jpg'
import imagePrize4Src from '../../assets/images/prize-4.jpg'

import Heading from '../commons/Heading'
import Section from '../commons/Section'
import Skelleton from '../commons/Skeleton'
import CardCompetition from '../modules/CardCompetition'
import Game from '../modules/Game'
import UserProfile from '../modules/UserProfile'
import Slider from '../modules/Slider'
import CardPrize from '../modules/CardPrize'

import useNavigation from '../../hooks/useNavigation'
import useCompetitions from '../../hooks/useCompetitions'
import useToast from '../../hooks/useToast'
import BannerHome from '../modules/BannerHome'

const Home = () => {
  const { data, error, isLoading } = useCompetitions()
  const { setNavigation } = useNavigation()
  const { error: toast } = useToast()

  useEffect(() => {
    setNavigation({
      title: false
    })
  }, [setNavigation])

  useEffect(() => {
    if (error) {
      toast({
        title: 'Ocurrió un error al cargar las competencias',
        message: error.message
      })
    }
  }, [error, toast])

  return (
    <>
      <UserProfile />
      <Section>
        <Heading
          title='🏆 ¡Gana premios con AstroGool! 🏆'
          description='Premiaremos a los 3 primeros lugares de cada jornada.'
        />
        <BannerHome />
      </Section>
      {isLoading
        ? (
          <Section>
            <Skelleton />
            <Skelleton />
            <Skelleton />
          </Section>
          )
        : <Competitions my={data.my} all={data.all} />}
      <Game />
    </>
  )
}

const Competitions = ({ my, all }) => {
  return (
    <>
      {!my.length
        ? (
          <Section>
            <Heading.Display>Crea tu equipo de ensueño y demuestra tus habilidades de estratega en cada partido</Heading.Display>
          </Section>
          )
        : (
          <Section>
            <Heading
              title='Mis competencias'
              description='¡Prepárate para ver tu equipo brillar y alcanzar la gloria en cada encuentro!'
            />
            {my.map((props) => <CardCompetition key={props.id} {...props} signed />)}
            <Heading
              title='🎁 Premios de la jornada 🎁'
            />
            <Slider
              items={[
                <CardPrize
                  key={0}
                  title='🥇 1er Lugar'
                  description={<p><strong>Combo La Ola</strong><br />Tostada especial la Ola + <br />Cubeta de cerveza</p>}
                  image={imagePrize1Src}
                  logo={logoSponsor1Src}
                  fbUrl='https://www.facebook.com/Laolaseafood'
                >
                  <p>Válido en cualquier sucursal<br />de La Ola Tepic y Guadalajara</p>
                </CardPrize>,
                <CardPrize
                  key={1}
                  title='🥈 2do Lugar'
                  description={<p>Cupón de<br /><strong>$200 pesos</strong><br /> para consumo libre</p>}
                  image={imagePrize2Src}
                  logo={logoSponsor2Src}
                  fbUrl='https://www.facebook.com/wipizzcddelvalle'
                >
                  <p>Hazlo válido en cualquier sucursal <br />Cd. del Valle, Plaza Pop, Tec y Santiago</p>
                </CardPrize>,
                <CardPrize
                  key={2}
                  title='🥉 3er Lugar'
                  description={<p>Cupón de<br /><strong>$200 pesos</strong><br /> para consumo libre</p>}
                  image={imagePrize3Src}
                  logo={logoSponsor3Src}
                  fbUrl='https://www.facebook.com/ladocemariscosychelas'
                >
                  <p>Visítanos en Av. Iturbide esq. Vicente Guerrero Col. Menchaca</p>
                </CardPrize>,
                <CardPrize
                  key={3}
                  title='🏅 4to Lugar'
                  description={<p><strong>Combo Pareja</strong><br /> Orden doble de alitas + <br />2 cervezas de media*</p>}
                  image={imagePrize4Src}
                  logo={logoSponsor4Src}
                  fbUrl='https://www.facebook.com/LaAleteria'
                >
                  <p>Visítanos en Paseo de Viena esq. Florencia Col. Cd. del Valle</p>
                </CardPrize>
              ]}
              height='100px'
            />
          </Section>
          )}
      {all.length
        ? (
          <Section>
            <Heading
              title='Ligas disponibles'
              description='Únete a la liga que más te guste para competir con otros jugadores.'
            />
            {all.map((props) => <CardCompetition key={props.id} {...props} />)}
          </Section>
          )
        : null}
    </>
  )
}

export default Home
