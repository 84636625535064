import { Routes, Route, Navigate, useLocation } from 'react-router-dom'

import Auth from './layout/Auth'
import Main from './layout/Main'
import Legal from './layout/Legal'

import Welcome from './routes/Welcome'
import Login from './routes/Login'
import Signup from './routes/Signup'
import Home from './routes/Home'
import Competitions from './routes/Competitions'
import Competition from './routes/Competition'
import Round from './routes/Round'
import ManageTeam from './routes/ManageTeam'
import Team from './routes/Team'
import Profile from './routes/Profile'
import Terms from './routes/Terms'
import Privacy from './routes/Privacy'
import Cookies from './routes/Cookies'
import Prizes from './routes/Prizes'

import SplashPreloader from './modules/SplashPreloader'

import { UserProvider } from '../context/User'
import { ToastProvider } from '../context/Toast'
import useUser from '../hooks/useUser'

const App = () => {
  return (
    <ToastProvider>
      <UserProvider>
        <Routes>
          <Route path='auth' element={<NotAuth><Auth /></NotAuth>}>
            <Route index element={<Navigate to='login' />} />
            <Route path='login' element={<Login />} />
            <Route path='signup' element={<Signup />} />
          </Route>
          <Route path='app' element={<RequireAuth><Main /></RequireAuth>}>
            <Route index element={<Navigate to='home' />} />
            <Route path='home' element={<Home />} />
            <Route path='competitions' element={<Competitions />} />
            <Route path='competition/:id' element={<Competition />} />
            <Route path='competition/:id/round/:rid' element={<Round />} />
            <Route path='competition/:id/round/:rid/team' element={<ManageTeam />} />
            <Route path='team/:id' element={<Team />} />
            <Route path='profile' element={<Profile />} />
            <Route path='prizes' element={<Prizes />} />
          </Route>
          <Route path='/' element={<NotAuth><Welcome /></NotAuth>} />
          <Route element={<Legal />}>
            <Route path='/terms' element={<Terms />} />
            <Route path='/privacy' element={<Privacy />} />
            <Route path='/cookies' element={<Cookies />} />
          </Route>
        </Routes>
      </UserProvider>
    </ToastProvider>
  )
}

const NotAuth = ({ children }) => {
  const [user] = useUser()
  const location = useLocation()

  if (user) {
    const from = location.state?.from?.pathname || '/app/home'
    return <Navigate to={from} replace />
  }
  return children
}

const RequireAuth = ({ children }) => {
  const [user] = useUser()
  const location = useLocation()

  if (!user) {
    return <Navigate to='/auth/login' state={{ from: location }} />
  } else if (!user.isReady) {
    return <SplashPreloader />
  }
  return children
}

export default App
