import style from './Window.module.scss'
import { ReactComponent as CloseIcon } from '../../assets/images/icon_close.svg'

import { useRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import PropTypes from 'prop-types'

const Window = ({ show, close, children, className, onExited }) => {
  const nodeRef = useRef(null)

  const handleEntered = () => {
    document.body.style.overflow = 'hidden'
  }

  const handleExited = () => {
    document.body.style.overflow = 'auto'
    if (onExited) onExited()
  }

  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={show}
      timeout={400}
      mountOnEnter
      unmountOnExit
      classNames={{ ...style }}
      onEntered={handleEntered}
      onExited={handleExited}
    >
      <div className={style.container} ref={nodeRef}>
        <div className={`${style.content} ${className || ''}`}>
          <button className={style.action} onClick={close}>
            <CloseIcon />
          </button>
          <div className={style.body}>
            {children}
          </div>
        </div>
        <div className={style.overlay} onClick={close} />
      </div>
    </CSSTransition>
  )
}

export default Window

Window.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  close: PropTypes.func.isRequired,
  onExited: PropTypes.func,
  show: PropTypes.bool.isRequired
}
