import style from './Slider.module.scss'

import { useState, useRef } from 'react'

const Slider = ({ items, height }) => {
  const [isDragging, setIsDragging] = useState(false)
  const [startX, setStartX] = useState(0)
  const [scrollLeft, setScrollLeft] = useState(0)
  const sliderRef = useRef(null)

  const startDragging = (ev) => {
    setIsDragging(true)
    setStartX(ev.pageX - sliderRef.current.offsetLeft)
    setScrollLeft(sliderRef.current.scrollLeft)
  }

  const stopDragging = () => {
    setIsDragging(false)
  }

  const onDrag = (ev) => {
    if (!isDragging) return
    ev.preventDefault()
    const x = ev.pageX - sliderRef.current.offsetLeft
    const walk = (x - startX) * 1
    sliderRef.current.scrollLeft = scrollLeft - walk
  }

  return (
    <div className={style.container} onMouseLeave={stopDragging}>
      <div className={style.crop} style={{ height }}>
        <div
          className={style.content}
          ref={sliderRef}
          onMouseUp={stopDragging}
          onMouseMove={onDrag}
          onMouseDown={startDragging}
        >
          <div className={style.items}>
            {items.map((item, index) => (
              <div className={style.item} key={index}>
                {item}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Slider
