import { useState, useCallback } from 'react'
import { updatePassword as firebaseUpdatePassword } from 'firebase/auth'

import { auth } from '../lib/firebase'

const useUpdatePassword = () => {
  const [saving, setSaving] = useState(false)

  const updatePassword = useCallback((password) => {
    return new Promise((resolve, reject) => {
      setSaving(true)
      firebaseUpdatePassword(auth.currentUser, password)
        .then(() => {
          resolve()
        })
        .catch((err) => {
          const error = {
            field: null,
            message: ''
          }
          switch (err.code) {
            case 'auth/weak-password':
              error.field = 'password'
              error.message = 'Tu contraseña no cumple con las condiciones de seguridad.'
              break
            case 'auth/requires-recent-login':
              return resolve(true)
            default:
              error.message = err.message
              break
          }
          console.error(err)
          reject(error)
        })
        .finally(() => {
          setSaving(false)
        })
    })
  }, [])

  return [updatePassword, saving]
}

export default useUpdatePassword
