import apiProvider from './provider'

const apiCore = {
  url: 'v1/core',
  getProfile: async function (id) {
    if (id) return await apiProvider.get(`${this.url}/profile/${id}`)
    return await apiProvider.get(`${this.url}/profile/my`)
  },
  updateProfile: async function (data) {
    return await apiProvider.put(`${this.url}/profile/my/`, data)
  },
  redeemCode: async function (code) {
    return await apiProvider.get(`${this.url}/user_code/redeem?code=${code}`)
  },
  saveGameResult: async function (result) {
    return await apiProvider.get(`${this.url}/user_game/record?win=${result}`)
  },
  deleteAccount: async function () {
    return await apiProvider.get(`${this.url}/user/delete`)
  }
}

const apiCompetitions = {
  url: 'v1/fantasy/competitions',
  get: async function (id) {
    if (id) return await apiProvider.get(`${this.url}/${id}`)
    return await apiProvider.get(this.url)
  },
  enrolled: async function () {
    return await apiProvider.get(`${this.url}/enrolled`)
  },
  enroll: async function (id) {
    return await apiProvider.get(`${this.url}/${id}/enroll`)
  },
  getInfo: async function (id) {
    return await apiProvider.get(`${this.url}/${id}/info`)
  },
  getStandings: async function (competitionId, roundId) {
    if (roundId) return await apiProvider.get(`${this.url}/${competitionId}/rounds?round=${roundId}`)
    return await apiProvider.get(`${this.url}/${competitionId}/season`)
  },
  getRules: async function (id) {
    return await apiProvider.get(`${this.url}/${id}/rules`)
  }
}

const apiGame = {
  url: 'v1/game',
  getRounds: async function (seasonId, id) {
    if (id) return await apiProvider.get(`${this.url}/rounds/${id}`)
    return await apiProvider.get(`${this.url}/rounds?competition=${seasonId}`)
  },
  getFormations: async function () {
    return await apiProvider.get(`${this.url}/alineations`)
  },
  getPlayers: async function (position, roundId, id) {
    if (id) return await apiProvider.get(`${this.url}/players/${id}`)
    return await apiProvider.get(`${this.url}/players?position=${position || ''}${roundId ? `&round=${roundId}` : ''}`)
  }
}

const apiFantasy = {
  url: 'v1/fantasy',
  getTeams: async function (id) {
    return await apiProvider.get(`${this.url}/game_alineation/${id}`)
  },
  createTeam: async function (data, id) {
    return await apiProvider.put(`${this.url}/game_alineation/${id}/`, data)
  },
  getPoints: async function (id) {
    return await apiProvider.get(`${this.url}/game_alineation/${id}/points`)
  }
}

export {
  apiCore,
  apiCompetitions,
  apiGame,
  apiFantasy
}
