import style from './index.module.scss'

import { useState } from 'react'
import PropTypes from 'prop-types'

import Input from '../../commons/Input'
import Button from '../../commons/Button'
import Modal from '../../commons/Modal'
import Reauthenticate from '../Reauthenticate'

import useUpdateEmail from '../../../hooks/useUpdateEmail'
import useToast from '../../../hooks/useToast'

const UpdateEmail = ({ email, onSaved }) => {
  const [updateEmail, saving] = useUpdateEmail()
  const [error, setError] = useState(null)
  const [reauthentication, setReauthentication] = useState({
    newValue: '',
    show: false
  })
  const toast = useToast()

  const handleBlur = (ev) => {
    const value = ev.target.value
    if (value.trim() === '') {
      setError('Tu email no puede quedar vacío')
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
      setError('Este correo no es un email válido')
    } else {
      setError(null)
    }
  }

  const handleSubmit = (ev) => {
    ev.preventDefault()
    const value = ev.target.email.value
    if (value.trim() === '') {
      setError('Tu email no puede quedar vacío')
      return
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
      setError('Este correo no es un email válido')
      return
    }
    updateEmail(value)
      .then((requiresRecentLogin) => {
        if (requiresRecentLogin) {
          setReauthentication({
            newValue: value,
            show: true
          })
          return
        }
        toast.success({
          title: 'Se han guardado los cambios',
          message: 'Tu email ha sido modificado correctamente.'
        })
        onSaved()
      })
      .catch((err) => {
        if (err.field) {
          setError(err.message)
        } else {
          toast.error({
            title: 'No se han podido guardar los cambios',
            message: err.message
          })
        }
      })
  }

  const handleReauth = () => {
    const { newValue } = reauthentication
    setReauthentication({
      newValue: '',
      show: false
    })
    updateEmail(newValue)
      .then(() => {
        toast.success({
          title: 'Se han guardado los cambios',
          message: 'Tu email ha sido modificada correctamente.'
        })
        onSaved()
      })
      .catch((err) => {
        toast.error({
          title: 'No se han podido guardar los cambios',
          message: err.message
        })
      })
  }

  return (
    <section className={style.container}>
      <h1 className={style.title}>Cambiar email</h1>
      <form className={style.form} onSubmit={handleSubmit}>
        <div className={style.description}>Asegúrate de proporcionar una dirección de correo electrónico válida y que tengas acceso a ella, ya que utilizaremos esta dirección para enviarte notificaciones importantes sobre tu cuenta y las actualizaciones de AstroGool.</div>
        <Input
          type='email'
          label='Email'
          name='email'
          placeholder='cuenta@email.com'
          error={error}
          defaultValue={email}
          onBlur={handleBlur}
        />
        <Button
          className={style.button}
          type='submit'
          loading={saving}
        >
          Actualizar
        </Button>
      </form>
      <Modal
        show={reauthentication.show}
        close={() => setReauthentication({ ...reauthentication, show: false })}
        handleClose={() => setReauthentication({ newValue: '', show: false })}
      >
        <Reauthenticate onReauth={handleReauth} />
      </Modal>
    </section>
  )
}

export default UpdateEmail

UpdateEmail.propTypes = {
  email: PropTypes.string.isRequired,
  onSaved: PropTypes.func.isRequired
}
