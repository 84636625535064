import style from './CardPlayer.module.scss'

import { useState, useEffect, useRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import PropTypes from 'prop-types'

import { ReactComponent as IconEmblem } from '../../assets/images/icon_shield.svg'
import { ReactComponent as IconAstros } from '../../assets/images/icon_astros.svg'
import { ReactComponent as BadgeCaptain } from '../../assets/images/bagde_captain.svg'

const CardPlayer = ({ fullName, teamName, price, number, teamLogoURL, selected, onClick, captain, disabled }) => {
  const [isSelected, setIsSelected] = useState(selected)
  const nodeRef = useRef(null)

  const handleClick = () => {
    if (disabled) return
    setIsSelected(true)
    setTimeout(() => {
      onClick()
    }, 120)
  }

  useEffect(() => {
    setIsSelected(selected)
  }, [selected])

  return (
    <div className={`${style.container} ${isSelected ? style.selected : ''} ${disabled ? style.disabled : ''}`} onClick={handleClick}>
      <div className={style.avatar}>
        {
          teamLogoURL
            ? <div className={style.logo}><img src={teamLogoURL} alt='Logo' /></div>
            : <div className={style.logo}><IconEmblem /></div>
            }
        <CSSTransition
          nodeRef={nodeRef}
          in={captain}
          timeout={240}
          mountOnEnter
          unmountOnExit
          classNames={{ ...style }}
        >
          <div className={style.badge} ref={nodeRef}><BadgeCaptain /></div>
        </CSSTransition>
      </div>
      <div className={style.content}>
        <h3 className={style.name}>{fullName}</h3>
        <div className={style.meta}>{teamName} #{number}</div>
      </div>
      <div className={style.price}><IconAstros /><span>${price} M</span></div>
    </div>
  )
}

export default CardPlayer

CardPlayer.propTypes = {
  fullName: PropTypes.string.isRequired,
  teamName: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  teamLogoURL: PropTypes.string,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  captain: PropTypes.bool
}
