import style from './index.module.scss'

import { useState } from 'react'
import PropTypes from 'prop-types'

import Input from '../../commons/Input'
import Button from '../../commons/Button'

import useUpdateName from '../../../hooks/useUpdateName'
import useToast from '../../../hooks/useToast'

const UpdateName = ({ name, lastName, onSaved }) => {
  const [updateName, saving] = useUpdateName()
  const [errors, setErrors] = useState({
    name: null,
    lastName: null
  })
  const toast = useToast()

  const handleBlur = (ev) => {
    const field = ev.target.name
    const value = ev.target.value
    switch (field) {
      case 'name':
        if (value.trim() === '') {
          setErrors({
            ...errors,
            name: 'Tu nombre no puede quedar vacío'
          })
        } else {
          setErrors({
            ...errors,
            name: null
          })
        }
        return
      case 'lastName':
        if (value.trim() === '') {
          setErrors({
            ...errors,
            lastName: 'Tu apellido es requerido'
          })
        } else {
          setErrors({
            ...errors,
            lastName: null
          })
        }
        return
      default:
        return false
    }
  }

  const handleSubmit = (ev) => {
    ev.preventDefault()
    const name = ev.target.name.value
    const lastName = ev.target.lastName.value

    if (name.trim() === '') {
      setErrors({
        ...errors,
        name: 'Tu nombre no puede quedar vacío'
      })
      return
    }
    if (lastName.trim() === '') {
      setErrors({
        ...errors,
        lastName: 'Tu apellido es requerido'
      })
      return
    }
    updateName({
      name,
      lastName
    })
      .then(() => {
        toast.success({
          title: 'Se han guardado los cambios',
          message: 'Tu perfil ha sido actualizado correctamente.'
        })
        onSaved()
      })
      .catch((err) => {
        toast.error({
          title: 'No se pudieron guardar los cambios',
          message: err.message
        })
      })
  }

  return (
    <section className={style.container}>
      <h1 className={style.title}>Nombre del Director Técnico</h1>
      <p className={style.description}>Este es el nombre que se mostrará en tu perfil y en el ranking. Será visible para todos los usuarios. </p>
      <form className={style.form} onSubmit={handleSubmit}>
        <Input
          label='Nombre'
          name='name'
          placeholder='Nombre(s)'
          error={errors.name}
          defaultValue={name}
          onBlur={handleBlur}
        />
        <Input
          label='Apellido'
          name='lastName'
          placeholder='Apellido(s)'
          error={errors.lastName}
          defaultValue={lastName}
          onBlur={handleBlur}
        />
        <Button
          className={style.button}
          type='submit'
          loading={saving}
        >
          Guardar
        </Button>
      </form>
    </section>
  )
}

export default UpdateName

UpdateName.propTypes = {
  name: PropTypes.string,
  lastName: PropTypes.string,
  onSaved: PropTypes.func.isRequired
}
