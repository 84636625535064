import style from './Loader.module.scss'
import loaderSrc from '../../assets/loader.json'

import PropTypes from 'prop-types'
import { Player } from '@lottiefiles/react-lottie-player'

const Loader = ({ className }) => {
  return (
    <Player
      autoplay
      loop
      src={loaderSrc}
      className={className || style.content}
    />
  )
}

export default Loader

Loader.propTypes = {
  className: PropTypes.string
}
