const Cookies = () => {
  return (
    <section>
      <h1>Política de Cookies</h1>
      <p><i>Fecha de actualización 4 de enero de 2024</i></p>
      <p>Astrogool® (“nosotros”, “a nosotros” o “nuestro”) usa cookies y sitios afiliados (en conjunto, el “Sitio”). Nuestra Política de cookies explica qué son las cookies, cómo se usan, de qué modo los terceros con los que nos asociamos pueden usarlas en el Sitio y tus opciones con respecto a las mismas. Lee esta Política de Cookies y nuestro Aviso de Privacidad y Confidencialidad para la Protección de Datos Personales, que detalla información adicional sobre cómo usar información de identificación personal y tus diferentes derechos.</p>
      <p>Las cookies son pequeños fragmentos de texto enviados por un sitio web que visitas desde tu navegador web, este archivo de cookie se almacena en tu navegador web y permite que el Sitio o una tercera parte te reconozca con el fin de facilitar tu próxima visita y hacer que el Sitio te sea más útil. Básicamente, las cookies son la tarjeta de identificación de un usuario para los servidores de Astrogool®. Las balizas web también conocidas como web bug son pequeños archivos gráficos vinculados a nuestros servidores que nos permiten controlar el uso que le das a nuestro Sitio y a funcionalidades relacionadas.</p>
      <p>Estos nos permiten a ofrecer un servicio óptimo y más eficiente y personalizar tu experiencia en nuestro Sitio. Las cookies y balizas de web pueden ser “persistentes” o “de sesión”.</p>
      <p>Cuando usas y accedes a este sitio, es posible que coloquemos varios archivos de cookies en tu navegador web. Astrogool® usa o puede usar cookies o balizas web para ayudarnos a determinar e identificar visitantes repetidos, el tipo de contenido y los sitios a los que se vincula un usuario de nuestro Sitio, el tiempo que cada usuario pasa en un área particular de nuestro Sitio, y las funcionalidades específicas que los usuarios eligen usar.</p>
      <p>En la medida que los datos de cookies constituyen información personal identificable, procesamos dichos datos sobre la base de tu consentimiento. Usamos cookies de sesión y persistentes en el sitio y diferentes tipos de cookies para ejecutar el sitio, además de nuestras propias cookies, es posible que también usemos cookies de terceros para informar estadísticas de uso del sitio y perfeccionar las estrategias de mercadotecnia.</p>
      <p>Si deseas eliminar cookies o indicarle a tu navegador web que elimine o rechace cookies, visita las páginas de ayuda de tu navegador web. Sin embargo, ten en cuenta que, si eliminas cookies o te niegas a aceptarlas, es posible que no puedas usar algunas o todas las características que ofrecemos. Es posible que no puedas iniciar sesión ni guardar tus preferencias y que algunas de nuestras páginas no se muestren de manera correcta.</p>
      <p>Astrogool®</p>
    </section>
  )
}

export default Cookies
