import style from './index.module.scss'

import { useState, useEffect, useRef } from 'react'

import Header from './Header'
import UpdateName from './UpdateName'
import UpdateTeam from './UpdateTeam'
import UpdateEmail from './UpdateEmail'
import UpdatePassword from './UpdatePassword'
import UpdatePhoto from './UpdatePhoto'
import DeleteAccount from './DeleteAccount'
import Heading from '../../commons/Heading'
import Section from '../../commons/Section'
import Option from '../../commons/Option'
import Window from '../../commons/Window'
import Help from '../Help'

import useUser from '../../../hooks/useUser'
import useAuth from '../../../hooks/useAuth'

const Profile = () => {
  const [user] = useUser()
  const auth = useAuth()
  const [section, setSection] = useState(null)
  const [showWindow, setShowWindow] = useState(false)
  const fileRef = useRef(null)

  const { displayName, teamName, photoURL, email } = user

  const handleClick = (ev) => {
    const attr = ev.target.getAttribute('data-attr')
    setSection(attr)
  }

  useEffect(() => {
    if (section) setShowWindow(true)
  }, [section])

  return (
    <div className={style.container}>
      <Header
        name={displayName || email}
        team={teamName}
        photoURL={photoURL}
        onPhotoClick={(file) => { fileRef.current = file; setSection('photo') }}
      />
      <Section>
        <Heading
          title='Mi perfil'
        />
        <div className={style.content}>
          <Option
            label='Nombre del DT'
            value={displayName || '- Configura tu nombre'}
            onClick={handleClick}
            dataAttr='name'
          />
          <Option
            label='Nombre del equipo'
            value={teamName || '- Define el nombre del equipo'}
            onClick={handleClick}
            dataAttr='team'
          />
        </div>
      </Section>
      <Section>
        <Heading
          title='Cuenta'
        />
        <div className={style.content}>
          <Option
            label='Email'
            value={email}
            onClick={handleClick}
            dataAttr='email'
          />
          <Option
            label='Contraseña'
            value='********'
            onClick={handleClick}
            dataAttr='password'
          />
          <Option
            label='Borrar datos'
            value='Eliminar cuenta'
            onClick={handleClick}
            dataAttr='delete'
          />
        </div>
      </Section>
      <Section>
        <Heading
          title='Ayuda'
        />
        <div className={style.content}>
          <Option
            value='Reglas generales'
            onClick={handleClick}
            dataAttr='rules'
          />
          <Option
            value='Sistema de puntuación'
            onClick={handleClick}
            dataAttr='scores'
          />
        </div>
      </Section>
      <Section>
        <div className={style.content}>
          <Option
            value='Cerrar sesión'
            onClick={() => auth.signOut()}
            link
          />
          <div className={style.version}>
            <p>©AstroGool v{process.env.REACT_APP_VERSION}</p>
            <p><a href='/terms' target='_blank' rel='noopener noreferrer'>Términos y condiciones</a> | <a href='/privacy' target='_blank' rel='noopener noreferrer'>Política de privacidad</a> | <a href='/cookies' target='_blank' rel='noopener noreferrer'>Política de cookies</a></p>
          </div>
        </div>
      </Section>
      <Window
        show={showWindow}
        close={() => setShowWindow(false)}
        onExited={() => setSection(null)}
      >
        <Content
          section={section}
          user={user}
          onSaved={() => setShowWindow(false)}
          file={fileRef.current}
        />
      </Window>
    </div>
  )
}

const Content = ({ section, user, onSaved, file }) => {
  const { name, lastName, teamName, email } = user

  switch (section) {
    case 'name':
      return <UpdateName name={name} lastName={lastName} onSaved={onSaved} />
    case 'team':
      return <UpdateTeam name={teamName} onSaved={onSaved} />
    case 'email':
      return <UpdateEmail email={email} onSaved={onSaved} />
    case 'password':
      return <UpdatePassword onSaved={onSaved} />
    case 'delete':
      return <DeleteAccount onCancel={onSaved} />
    case 'photo':
      return <UpdatePhoto image={file} onSaved={onSaved} />
    case 'rules':
      return <Help />
    case 'scores':
      return <Help active={2} />
    default:
      return null
  }
}

export default Profile
