import style from './Empty.module.scss'

import Section from '../commons/Section'

import image from '../../assets/images/img_empty.svg'

const Empty = () => {
  return (
    <Section className={style.container}>
      <div className={style.graphic}>
        <img src={image} alt='Vacío' />
      </div>
      <div className={style.content}>
        <h2 className={style.title}>¡Es la hora de dar el silbatazo inicial!</h2>
        <p className={style.text}>Aún no te has unido a ninguna competición. Elige una liga, arma tu equipo de ensueño y estarás listo para la acción.</p>
      </div>
    </Section>
  )
}

export default Empty
