import style from './Counter.module.scss'
import { ReactComponent as IconWarning } from '../../../assets/images/icon_warning.svg'
import image from '../../../assets/images/img_round.svg'

import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import Section from '../../commons/Section'
import Button from '../../commons/Button'
import Team from './Team'

const leftFillNum = (num) => {
  return num.toString().padStart(2, '0')
}

const calculateTimeRemaining = (date) => {
  const startDate = new Date(date).getTime()
  const now = new Date().getTime()
  const distance = startDate - now
  const days = leftFillNum(Math.floor(distance / (1000 * 60 * 60 * 24)))
  const hours = leftFillNum(Math.floor(distance % (1000 * 60 * 60 * 24) / (1000 * 60 * 60)))
  const minutes = leftFillNum(Math.floor(distance % (1000 * 60 * 60) / (1000 * 60)))
  const seconds = leftFillNum(Math.floor(distance % (1000 * 60) / 1000))
  return ({ days, hours, minutes, seconds })
}

const Timer = ({ timeRemaining }) => (
  <div className={style.counter}>
    <div className={style.item}>
      <div className={style.value}>{timeRemaining.days}</div>
      <div className={style.label}>Días</div>
    </div>
    <div className={style.item}>
      <div className={style.value}>{timeRemaining.hours}</div>
      <div className={style.label}>Hrs</div>
    </div>
    <div className={style.item}>
      <div className={style.value}>{timeRemaining.minutes}</div>
      <div className={style.label}>Mins</div>
    </div>
    <div className={style.item}>
      <div className={style.value}>{timeRemaining.seconds}</div>
      <div className={style.label}>Segs</div>
    </div>
  </div>
)

const Counter = ({ date, setHasStarted, setRoute, competitionId, roundId, team }) => {
  const [timeRemaining, setTimeRemaining] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  })

  useEffect(() => {
    const interval = setInterval(() => {
      const time = calculateTimeRemaining(date)
      if (time.seconds <= -1) return setHasStarted(true)
      setTimeRemaining(time)
    }, 1000)
    return () => clearInterval(interval)
  }, [date, setHasStarted])

  if (team.formation === null) {
    return (
      <Section>
        <div className={style.graphic}>
          <img src={image} alt='Figure' />
        </div>
        <div className={style.content}>
          <p>Aún estás a tiempo <b>¡arma tu equipo imparable antes de que comience la acción!</b></p>
          <Timer timeRemaining={timeRemaining} />
          <Button onClick={() => setRoute(`/app/competition/${competitionId}/round/${roundId}/team`)}>¡Crear mi equipo!</Button>
        </div>
      </Section>
    )
  }
  return (
    <Section className={style.container}>
      <Team id={team.id} />
      <div className={style.content}>
        <Timer timeRemaining={timeRemaining} />
        <div className={style.tip}>
          <span className={style.icon}><IconWarning /></span>
          <p>Recuerda, puedes realizar cambios estratégicos en tu equipo <b>antes de que inicie la jornada</b>.</p>
        </div>
        <Button onClick={() => setRoute(`/app/competition/${competitionId}/round/${roundId}/team`)}>Modificar mi equipo</Button>
      </div>
    </Section>
  )
}

export default Counter

Counter.propTypes = {
  competitionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  date: PropTypes.string.isRequired,
  roundId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setHasStarted: PropTypes.func.isRequired,
  setRoute: PropTypes.func.isRequired,
  team: PropTypes.object.isRequired
}
