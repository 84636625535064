import { useContext, useEffect } from 'react'

import { TeamContext } from '../context/Team'
import useTeam from './useTeam'

const useSetTeam = (teamId) => {
  const [team, dispatch] = useContext(TeamContext)
  const { data, error, isLoading } = useTeam(teamId)

  useEffect(() => {
    if (isLoading || error) return

    if (data && data !== 204) {
      dispatch({
        type: 'set',
        value: {
          ...data,
          hasBonus: Object.values(data.players).flat().reduce((total, player) => total + (player?.price || 0), 0) > 100,
          id: teamId,
          isSaved: true
        }
      })
    } else if (teamId) {
      dispatch({ type: 'set_id', value: teamId })
    }
  }, [data, error, isLoading, dispatch, teamId])

  return [team, dispatch]
}

export default useSetTeam
