const dateToLocale = (date, showTime) => {
  if (date) {
    let dateLocale = new Date(date)
    if (showTime) {
      dateLocale = dateLocale.toLocaleDateString('es-MX', { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' }) + ', ' + dateLocale.toLocaleTimeString('es-MX', { hour: '2-digit', minute: '2-digit' })
    } else {
      dateLocale = dateLocale.toLocaleDateString('es-MX', { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' })
    }
    dateLocale = dateLocale.charAt(0).toUpperCase() + dateLocale.slice(1)
    return dateLocale
  }
  return null
}

export { dateToLocale }
