import { useState, useCallback } from 'react'
import { updateEmail as firebaseUpdateEmail } from 'firebase/auth'
import { doc, setDoc } from 'firebase/firestore'

import { auth, db } from '../lib/firebase'
import { apiCore } from '../api'

import useUser from './useUser'

const useUpdateEmail = () => {
  const [saving, setSaving] = useState(false)
  const [user, dispatch] = useUser()

  const updateEmail = useCallback((email) => {
    return new Promise((resolve, reject) => {
      setSaving(true)
      firebaseUpdateEmail(auth.currentUser, email)
        .then(() => {
          return apiCore.updateProfile({ mail: email })
        })
        .then(() => {
          return setDoc(doc(db, 'users', user.uid), { email }, { merge: true })
        })
        .then(() => {
          dispatch({
            type: 'change_email',
            value: email
          })
          resolve()
        })
        .catch((err) => {
          const error = {
            field: null,
            message: ''
          }
          switch (err.code) {
            case 'auth/invalid-email':
              error.field = 'email'
              error.message = 'La cuenta no es un email válido.'
              break
            case 'auth/email-already-in-use':
              error.field = 'email'
              error.message = 'No se puede utilizar este email, pertenece a otro usuario.'
              break
            case 'auth/requires-recent-login':
              return resolve(true)
            default:
              error.message = err.message
              break
          }
          console.error(err)
          reject(error)
        })
        .finally(() => {
          setSaving(false)
        })
    })
  }, [user, dispatch])

  return [updateEmail, saving]
}

export default useUpdateEmail
