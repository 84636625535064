import style from './index.module.scss'

import { useState } from 'react'
import PropTypes from 'prop-types'

import Input from '../../commons/Input'
import Button from '../../commons/Button'
import Modal from '../../commons/Modal'
import Reauthenticate from '../Reauthenticate'

import useUpdatePassword from '../../../hooks/useUpdatePassword'
import useToast from '../../../hooks/useToast'

const UpdatePassword = ({ onSaved }) => {
  const [updatePassword, saving] = useUpdatePassword()
  const [errors, setErrors] = useState({
    password: null,
    confirmPassword: null
  })
  const [reauthentication, setReauthentication] = useState({
    newValue: '',
    show: false
  })
  const toast = useToast()

  const handleBlur = (ev) => {
    const name = ev.target.name
    const value = ev.target.value
    if (value.trim() === '') {
      setErrors({
        ...errors,
        [name]: 'Tu contraseña no puede quedar vacía'
      })
    } else if (name === 'password' && !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(value)) {
      setErrors({
        ...errors,
        password: 'Tu contraseña no cumple con los requisitos'
      })
    } else {
      setErrors({
        password: null,
        confirmPassword: null
      })
    }
  }

  const handleSubmit = (ev) => {
    ev.preventDefault()
    const passwordValue = ev.target.password.value
    const confirmPasswordValue = ev.target.confirmPassword.value
    if (passwordValue.trim() === '') {
      setErrors({
        ...errors,
        password: 'Tu contraseña no puede quedar vacía'
      })
      return
    } else if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(passwordValue)) {
      setErrors({
        ...errors,
        password: 'Tu contraseña no cumple con los requisitos'
      })
      return
    } else if (passwordValue !== confirmPasswordValue) {
      setErrors({
        ...errors,
        confirmPassword: 'Tus contraseñas no coinciden'
      })
      return
    }
    updatePassword(passwordValue)
      .then((requiresRecentLogin) => {
        if (requiresRecentLogin) {
          setReauthentication({
            newValue: passwordValue,
            show: true
          })
          return
        }
        toast.success({
          title: 'Se han realizado los cambios',
          message: 'Tu contraseña ha sido modificada correctamente.'
        })
        onSaved()
      })
      .catch((err) => {
        toast.error({
          title: 'No se pudo cambiar la contraseña',
          message: err.message
        })
      })
  }

  const handleReauth = () => {
    const { newValue } = reauthentication
    setReauthentication({
      newValue: '',
      show: false
    })
    updatePassword(newValue)
      .then(() => {
        toast.success({
          title: 'Se han realizado los cambios',
          message: 'Tu contraseña ha sido modificada correctamente.'
        })
        onSaved()
      })
      .catch((err) => {
        toast.error({
          title: 'No se pudo cambiar la contraseña',
          message: err.message
        })
      })
  }

  return (
    <section className={style.container}>
      <h1 className={style.title}>Cambiar contraseña</h1>
      <form className={style.form} onSubmit={handleSubmit}>
        <div className={style.description}>Tu nueva contraseña debe incluir mínimo 8 caracteres, por lo menos un dígito y una mayúscula.</div>
        <Input.Password
          label='Contraseña'
          name='password'
          placeholder='Ingresa tu nueva contraseña'
          error={errors.password}
          onBlur={handleBlur}
        />
        <Input.Password
          label='Confirmar contraseña'
          name='confirmPassword'
          placeholder='Confirma la contraseña'
          error={errors.confirmPassword}
          onBlur={handleBlur}
        />
        <Button
          className={style.button}
          type='submit'
          loading={saving}
        >
          Cambiar contraseña
        </Button>
      </form>
      <Modal
        show={reauthentication.show}
        close={() => setReauthentication({ ...reauthentication, show: false })}
        handleClose={() => setReauthentication({ newValue: '', show: false })}
      >
        <Reauthenticate onReauth={handleReauth} />
      </Modal>
    </section>
  )
}

export default UpdatePassword

UpdatePassword.propTypes = {
  onSaved: PropTypes.func
}
