import style from './UserProfile.module.scss'

import { useNavigate } from 'react-router-dom'

import { ReactComponent as IconWallet } from '../../assets/images/icon_wallet.svg'
import { ReactComponent as IconAstros } from '../../assets/images/icon_astros.svg'
import { ReactComponent as IconUser } from '../../assets/images/icon_profile.svg'

import useUser from '../../hooks/useUser'

const UserProfile = () => {
  const [user] = useUser()
  const navigate = useNavigate()

  return (
    <div className={style.container}>
      <div className={style.welcome}>
        <div className={style.avatar} onClick={() => navigate('/app/profile')}>
          {user.photoURL ? <img src={user.photoURL} alt='Avatar' /> : <IconUser />}
        </div>
        <div className={style.profile}>
          <span>Bienvenido D.T.</span>
          <span className={style.name}>{user.displayName || user.email}</span>
        </div>
      </div>
      <div className={style.astros}>
        <IconWallet className={style.icon} />
        <span>${user.astros}M</span>
        <div className={style.tag}><IconAstros /> Astros</div>
      </div>
    </div>
  )
}

export default UserProfile
