import style from './index.module.scss'

import { useState, useRef, useEffect } from 'react'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import PropTypes from 'prop-types'

import Header from './Header'
import Formation from './Formation'
import Lineup from './Lineup'
import Captain from './Captain'
import Success from './Success'
import Window from '../../commons/Window'
import Players from '../Players'
import Help from '../Help'
import { NotFound, ServerError, Forbidden } from '../Errors'

import useSetTeam from '../../../hooks/useSetTeam'
import useToast from '../../../hooks/useToast'
import useCreateTeam from '../../../hooks/useCreateTeam'

const Team = ({ round, isLoading }) => {
  const [team] = useSetTeam()
  const [activeStep, setActiveStep] = useState(1)
  const [selectPlayer, setSelectPlayer] = useState({
    show: false,
    position: null,
    index: null
  })
  const [showFAQ, setShowFAQ] = useState(false)

  const handleClose = () => {
    setSelectPlayer((state) => ({ ...state, show: false }))
  }

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'auto'
    })
  }, [activeStep])

  return (
    <div className={style.container}>
      <Header
        loading={isLoading}
        round={round}
        team={team}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        showFAQ={() => setShowFAQ(true)}
      />
      {isLoading
        ? null
        : round === 404
          ? <NotFound />
          : round === null
            ? <ServerError />
            : round === 403
              ? <Forbidden />
              : (
                <Content
                  teamId={round.team?.id}
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  setSelectPlayer={setSelectPlayer}
                />
                )}
      <Window
        show={selectPlayer.show}
        close={handleClose}
      >
        {selectPlayer.position
          ? (
            <Players
              position={selectPlayer.position}
              index={selectPlayer.index}
              roundId={round?.id}
              close={handleClose}
              show={selectPlayer.show}
            />
            )
          : <p>.</p>}
      </Window>
      <Window
        show={showFAQ}
        close={() => setShowFAQ(false)}
      >
        <Help />
      </Window>
    </div>
  )
}

const Content = ({ teamId, activeStep, setActiveStep, setSelectPlayer }) => {
  const [team, dispatch] = useSetTeam(teamId)
  const { createTeam, isSaving } = useCreateTeam()
  const { error: toast } = useToast()
  const nodeRef = useRef(null)

  const handleClickLineup = (position, index) => {
    setSelectPlayer({
      show: true,
      position,
      index
    })
  }

  const handleFinish = () => {
    createTeam(team, teamId)
      .then(() => {
        dispatch({ type: 'remove' })
        setActiveStep(4)
      })
      .catch((err) => {
        toast({
          title: 'Ocurrió un error al guardar el equipo',
          message: err.message
        })
      })
  }

  return (
    <SwitchTransition>
      <CSSTransition
        key={activeStep}
        nodeRef={nodeRef}
        timeout={280}
        classNames={{ ...style }}
      >
        <div className={style.content} ref={nodeRef}>
          {(() => {
            switch (activeStep) {
              case 1:
                return <Formation onSelect={() => setActiveStep(2)} />
              case 2:
                return <Lineup onSelect={() => setActiveStep(3)} onClick={handleClickLineup} />
              case 3:
                return <Captain onFinish={handleFinish} saving={isSaving} />
              case 4:
                return <Success isModified={team.isSaved} />
              default:
                return null
            }
          })()}
        </div>
      </CSSTransition>
    </SwitchTransition>
  )
}

export default Team

Team.propTypes = {
  round: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  isLoading: PropTypes.bool
}

Content.propTypes = {
  activeStep: PropTypes.number.isRequired,
  setActiveStep: PropTypes.func.isRequired,
  setSelectPlayer: PropTypes.func.isRequired,
  teamId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
}
