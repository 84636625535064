import { useState, useCallback } from 'react'

import { apiCore } from '../api'

import useUser from './useUser'

const useUpdateTeam = () => {
  const [saving, setSaving] = useState(false)
  const [, dispatch] = useUser()

  const updateTeam = useCallback((value) => {
    return new Promise((resolve, reject) => {
      setSaving(true)
      apiCore.updateProfile({ team_name: value })
        .then(() => {
          dispatch({
            type: 'change_teamname',
            value
          })
          resolve()
        })
        .catch((err) => {
          console.error(err)
          reject(err)
        })
        .finally(() => {
          setSaving(false)
        })
    })
  }, [dispatch])

  return [updateTeam, saving]
}

export default useUpdateTeam
