import style from './Section.module.scss'

import PropTypes from 'prop-types'

const Section = ({ children, className }) => {
  return (
    <section className={`${style.container} ${className || ''}`}>
      {children}
    </section>
  )
}

export default Section

Section.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}
