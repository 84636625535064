import useSWR from 'swr'
import { useMemo } from 'react'

import { apiCompetitions } from '../api'

const useAstros = (id) => {
  const { data, error, isLoading } = useSWR(['getRules', id], () => apiCompetitions.getRules(id))

  const astros = useMemo(() => {
    if (!isLoading && !error) {
      if (data === 404) {
        return 404
      }
      return {
        round: data.team_cost,
        bonus: data.overprice,
        max: data.to_spend
      }
    }
    return null
  }, [data, error, isLoading])

  return { data: astros, error, isLoading }
}

export default useAstros
