import style from './Header.module.scss'

import PropTypes from 'prop-types'

import Loader from '../../commons/Loader'
import Status from '../../commons/Status'

const Header = ({ loading, round, hasStarted }) => {
  return (
    <div className={style.container}>
      {loading
        ? <div className={style.loader}><Loader className={style.loader_content} /></div>
        : round === 404 || round === null || round === 403 || round?.status === 'scheduled'
          ? null
          : (
            <>
              <div className={style.content}>
                <h1 className={style.title}>{`${round.leagueName} - ${round.seasonName}`}</h1>
                <div className={style.meta}>
                  <span>{round.startDate} hrs.</span>
                </div>
                <Status status={hasStarted ? 'live' : round.status} />
              </div>
            </>
            )}
    </div>
  )
}

export default Header

Header.propTypes = {
  loading: PropTypes.bool,
  round: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  hasStarted: PropTypes.bool.isRequired
}
