import { createContext, useEffect, useReducer, useRef } from 'react'
import {
  onAuthStateChanged,
  FacebookAuthProvider,
  OAuthCredential,
  linkWithCredential
} from 'firebase/auth'
import { doc, setDoc, serverTimestamp } from 'firebase/firestore'

import { auth, db } from '../lib/firebase'
import { apiCore } from '../api'
import useAuth from '../hooks/useAuth'
import useToast from '../hooks/useToast'

const reducer = (state, action) => {
  switch (action.type) {
    case 'sign_in':
      return action.value
    case 'sign_out':
      return null
    case 'change_name': {
      const { name, lastName } = action.value
      return {
        ...state,
        name,
        lastName,
        displayName: name + ' ' + lastName
      }
    }
    case 'change_email':
      return {
        ...state,
        email: action.value
      }
    case 'change_teamname':
      return {
        ...state,
        teamName: action.value
      }
    case 'change_photo':
      return {
        ...state,
        photoURL: action.value
      }
    case 'game_over': {
      const { astros, gameIntents } = action.value
      return {
        ...state,
        astros,
        gameIntents
      }
    }
    default:
      break
  }
  throw Error('Acción desconocida: ' + action.type)
}

const UserContext = createContext()
const { Provider } = UserContext

const UserProvider = ({ children }) => {
  const localUser = useRef(window.localStorage.getItem('authUser'))
  const initialState = useRef(localUser.current ? JSON.parse(localUser.current) : null)
  const [user, dispatch] = useReducer(reducer, initialState.current)
  const { setHasDifferentCredential } = useAuth()
  const { error: toast } = useToast()

  useEffect(() => {
    const authListener = onAuthStateChanged(auth, async (userCredential) => {
      try {
        if (userCredential) {
          const userObject = {
            uid: userCredential.uid,
            email: userCredential.email,
            displayName: userCredential.displayName,
            photoURL: userCredential.photoURL
          }
          const credential = window.sessionStorage.getItem('credential')
          if (credential) {
            const credentialToLink = OAuthCredential.fromJSON(credential)
            await linkWithCredential(auth.currentUser, credentialToLink)
            window.sessionStorage.removeItem('credential')
          }
          const inviteCode = window.sessionStorage.getItem('inviteCode')
          if (inviteCode) {
            await apiCore.redeemCode(inviteCode)
          }
          const profile = await apiCore.getProfile()
          window.localStorage.setItem('authUser', JSON.stringify(userObject))
          await setDoc(doc(db, 'users', userCredential.uid), {
            lastLogin: serverTimestamp(),
            ...userObject,
            first_name: profile.first_name,
            last_name: profile.last_name,
            teamName: profile.team_name,
            apiId: profile.id,
            custom_fields: {
              displayName: userObject.displayName
            }
          }, { merge: true })
          dispatch({
            type: 'sign_in',
            value: {
              ...userObject,
              name: profile.first_name,
              lastName: profile.last_name,
              teamName: profile.team_name,
              id: profile.id,
              userId: profile.user_id,
              astros: profile.astros,
              inviteCode: profile.invite_code,
              gameIntents: profile.game_intents,
              photoURL: profile.photo || userObject.photoURL,
              isReady: true
            }
          })
        } else {
          window.localStorage.clear()
          dispatch({
            type: 'sign_out'
          })
        }
      } catch (err) {
        if (err.code === 'auth/account-exists-with-different-credential') {
          const credential = FacebookAuthProvider.credentialFromError(err)
          window.sessionStorage.setItem('credential', JSON.stringify(credential))
          setHasDifferentCredential(true)
          return
        }
        console.error(err)
        toast({
          title: err.code,
          message: err.message
        })
        dispatch({
          type: 'sign_out'
        })
      }
    })

    return () => {
      authListener()
    }
  }, [setHasDifferentCredential, toast])

  const value = [user, dispatch]

  return <Provider value={value}>{children}</Provider>
}

export { UserContext, UserProvider }
