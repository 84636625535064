import style from './index.module.scss'
import imageSrc1 from '../../../assets/images/img_onboard-team_1.svg'
import imageSrc2 from '../../../assets/images/img_onboard-team_2.svg'
import imageSrc3 from '../../../assets/images/img_onboard-team_3.svg'
import imageSrc4 from '../../../assets/images/img_onboard-team_4.svg'

import { useState, useRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import PropTypes from 'prop-types'

import Nav from './Nav'
import Slide from './Slide'
import Button from '../../commons/Button'
import useUser from '../../../hooks/useUser'

const Onboarding = ({ active, onClick }) => {
  const [user] = useUser()
  const [current, setCurrent] = useState(0)
  const nodeRef = useRef(null)

  const nextSlide = () => {
    const maxLength = 3
    if (current < maxLength) {
      setCurrent(current + 1)
      return
    }
    onClick()
  }

  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={active}
      timeout={500}
      mountOnEnter
      unmountOnExit
      classNames={{ ...style }}
    >
      <div className={style.container} ref={nodeRef}>
        <div className={style.header}>
          <button
            className={style.link}
            onClick={onClick}
          >
            Saltar
          </button>
        </div>
        <div className={style.content}>
          <Slide
            active={current === 0}
            title={<>¡Bienvenido DT. {user.displayName}!</>}
            text='AstroGool es la App de Fútbol Fantasy en la que ganas formando tu equipo ideal. ¡Demuestra y aprovecha tus conocimientos en fútbol!'
            imageSrc={imageSrc1}
          />
          <Slide
            active={current === 1}
            title='¿Cómo participo?'
            text='Elige la competición en la que deseas jugar, selecciona una jornada abierta y arma tu 11 ideal. ¡Reta a tus amigos y descubran quien es el mejor!'
            imageSrc={imageSrc2}
          />
          <Slide
            active={current === 2}
            title='¿Cómo gano puntos?'
            text='Los jugadores que selecciones en tu equipo generan puntos dependiendo su desempeño individualmente en la cancha, pero también con el resultado colectivo de su club. ¡Revisa el sistema de puntuación, y aplica tu mejor estrategia!'
            imageSrc={imageSrc3}
          />
          <Slide
            active={current === 3}
            title='¡Estás listo para Ganar!'
            text='Revisa los resultados de tu equipo al final de cada jornada, la suma del total de puntos logrados te posicionará en la tabla general. Podrás ganar en cada jornada y así sumar la mayor cantidad de puntaje para ser el campeón de todo el torneo.'
            imageSrc={imageSrc4}
          />
        </div>
        <div className={style.footer}>
          <Nav
            current={current}
            setCurrent={setCurrent}
          />
          <div className={style.button}>
            <Button onClick={nextSlide}>
              {
                current === 3
                  ? '¡A jugar!'
                  : 'Siguiente'
              }
            </Button>
          </div>
        </div>
      </div>
    </CSSTransition>
  )
}

export default Onboarding

Onboarding.propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func
}
