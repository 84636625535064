import style from './AccountExists.module.scss'

import Button from '../../commons/Button'

const AccountExists = ({ onClick }) => {
  return (
    <div className={style.container}>
      <div className={style.content}>
        <h1 className={style.title}>Tu email está asociado a otra cuenta</h1>
        <p><b>Inicia sesión con ella</b> para asociarla a tu cuenta de Facebook.</p>
        <p className={style.small}>Si no recuerdas la contraseña puedes utilizar la opción de recuperar contraseña.</p>
      </div>
      <Button onClick={onClick}>Aceptar</Button>
    </div>
  )
}

export default AccountExists
