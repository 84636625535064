import style from './index.module.scss'
import imageSrc from '../../../assets/images/img_error_500b.svg'

import { useNavigate } from 'react-router-dom'

import Section from '../../commons/Section'
import Button from '../../commons/Button'

const ServerError = () => {
  const navigate = useNavigate()

  return (
    <Section className={style.container}>
      <div className={style.content}>
        <div className={style.image}>
          <img src={imageSrc} alt='Error 500' />
        </div>
        <h1 className={style.title}>Uuups! :(</h1>
        <h2 className={style.subtitle}>Algo salió mal, no es tu culpa y claro que no era penal.</h2>
        <p className={style.text}>Error 500</p>
      </div>
      <Button onClick={() => navigate(-1)}>Regresar</Button>
    </Section>
  )
}

export default ServerError

// <h1 className={style.title}>¡Tarjeta roja para el servidor!</h1>
// <h2 className={style.subtitle}>Ya estamos trabajando en los cambios para reanudar el juego.<br />Inténtalo de nuevo más tarde.</h2>
