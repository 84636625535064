import style from './BannerHome.module.scss'
import imageSrc from '../../assets/images/banner_demo.jpg'

import { useNavigate } from 'react-router-dom'

const BannerHome = () => {
  const navigate = useNavigate()

  return (
    <div className={style.container} onClick={() => navigate('/app/prizes')}>
      <img
        className={style.image}
        src={imageSrc}
        alt='Gana premios con AstroGool'
      />
    </div>
  )
}

export default BannerHome
