import { useState, useCallback } from 'react'
import { httpsCallable } from 'firebase/functions'

import { functions } from '../lib/firebase'
import { apiCore } from '../api'

const useDeleteAccount = () => {
  const [deleting, setDeleting] = useState(false)

  const deleteAccount = useCallback(() => {
    return new Promise((resolve, reject) => {
      setDeleting(true)
      const cloudFunctionDelete = httpsCallable(functions, 'deleteUser')
      cloudFunctionDelete()
        .then(() => {
          return apiCore.deleteAccount()
        })
        .then(() => {
          resolve()
        })
        .catch((err) => {
          /* if (err.code === 'auth/requires-recent-login') {
            return resolve(true)
          } */
          console.error(err)
          reject(err)
        })
        .finally(() => {
          setDeleting(false)
        })
    })
  }, [])

  return [deleteAccount, deleting]
}

export default useDeleteAccount
