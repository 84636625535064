import style from './Formation.module.scss'
import { ReactComponent as ShirtImage } from '../../../assets/images/shirt_item.svg'

import { useEffect } from 'react'
import PropTypes from 'prop-types'

import Section from '../../commons/Section'
import Skeleton from '../../commons/Skeleton'
import Button from '../../commons/Button'

import useSetTeam from '../../../hooks/useSetTeam'
import useFormations from '../../../hooks/useFormations'
import useToast from '../../../hooks/useToast'

const Formation = ({ onSelect }) => {
  const [team, dispatch] = useSetTeam()
  const { data: formations, error, isLoading } = useFormations()
  const { error: toast } = useToast()

  const handleClick = (value) => {
    dispatch({ type: 'set_formation', value })
  }

  useEffect(() => {
    if (error) {
      toast({
        title: 'Ocurrió un error al cargar las formaciones',
        message: error.message
      })
    }
  }, [error, toast])

  return (
    <Section className={style.container}>
      <h1 className={style.title}>Selecciona una formación</h1>
      {isLoading
        ? (
          <div className={style.content}>
            <Skeleton className={style.skeleton} />
            <Skeleton className={style.skeleton} />
            <Skeleton className={style.skeleton} />
            <Skeleton className={style.skeleton} />
            <Skeleton className={style.skeleton} />
            <Skeleton className={style.skeleton} />
          </div>
          )
        : formations === 204 || formations === null
          ? (
            <div className={style.empty}>
              <h3>No hay alineaciones disponibles que mostrar</h3>
              <p>Inténtalo de nuevo más tarde.</p>
            </div>
            )
          : (
            <div className={style.content}>
              {formations.map((item) => (
                <Option
                  key={item.id}
                  formation={item}
                  selected={team.formation?.id === item.id}
                  onClick={() => handleClick(item)}
                />
              ))}
            </div>
            )}
      <Button
        disabled={!team.formation?.id}
        onClick={onSelect}
      >
        Siguiente
      </Button>
    </Section>
  )
}

const Option = ({ formation, onClick, selected }) => {
  return (
    <div className={`${style.option} ${selected ? style.selected : ''}`} onClick={onClick}>
      <div className={style.option__content}>
        <div className={style.row}>
          {Array.apply(null, Array(formation.forwards)).map((_, i) => <Shirt key={`forwards-${i}`} />)}
        </div>
        <div className={style.row}>
          {Array.apply(null, Array(formation.midfielders)).map((_, i) => <Shirt key={`midfielders-${i}`} />)}
        </div>
        <div className={style.row}>
          {Array.apply(null, Array(formation.defenders)).map((_, i) => <Shirt key={`defenders-${i}`} />)}
        </div>
        <div className={style.row}>
          <Shirt />
        </div>
        <div className={style.label}>{formation.name}</div>
      </div>
    </div>
  )
}

const Shirt = () => {
  return (
    <div className={style.shirt}>
      <ShirtImage />
    </div>
  )
}

export default Formation

Formation.propTypes = {
  onSelect: PropTypes.func.isRequired
}
