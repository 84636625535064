import style from './index.module.scss'

import { useState } from 'react'

import Button from '../../commons/Button'
import Input from '../../commons/Input'
import Modal from '../../commons/Modal'
import Providers from './Providers'
import ResetPassword from './ResetPassword'
import AccountExists from './AccountExists'

import useAuth from '../../../hooks/useAuth'
import useToast from '../../../hooks/useToast'

const LoginForm = () => {
  const [errors, setErrors] = useState({
    email: null,
    password: null
  })
  const [showPasswordReset, setShowPasswordReset] = useState(false)
  const auth = useAuth()
  const toast = useToast()

  const handleBlur = (ev) => {
    const field = ev.target.name
    const value = ev.target.value
    switch (field) {
      case 'email':
        if (value.trim() === '') {
          setErrors({
            ...errors,
            email: null
          })
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
          setErrors({
            ...errors,
            email: 'Este correo no es un email válido'
          })
        } else {
          setErrors({
            ...errors,
            email: null
          })
        }
        return
      case 'password':
        setErrors({
          ...errors,
          password: null
        })
        return
      default:
        return false
    }
  }

  const handleSubmit = (ev) => {
    ev.preventDefault()
    const email = ev.target.elements.email.value
    const password = ev.target.elements.password.value
    if (email.trim() === '') {
      setErrors({
        ...errors,
        email: 'No olvides tu email'
      })
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      setErrors({
        ...errors,
        email: 'Este correo no es un email válido'
      })
    } else if (password.trim() === '') {
      setErrors({
        ...errors,
        password: 'No olvides introducir tu contraseña'
      })
    } else {
      setErrors({
        email: null,
        password: null
      })
      auth.signIn({ email, password })
        .catch((err) => {
          if (err.field) {
            setErrors({
              email: null,
              password: null,
              [err.field]: err.message
            })
          } else {
            toast.error({
              title: err.code,
              message: err.message
            })
          }
        })
    }
  }

  return (
    <>
      <form className={style.container} onSubmit={handleSubmit}>
        <Input
          type='email'
          label='Correo'
          name='email'
          placeholder='Ingresa tu correo'
          error={errors.email}
          onBlur={handleBlur}
        />
        <Input.Password
          label='Contraseña'
          name='password'
          placeholder='Ingresa tu contraseña'
          error={errors.password}
          onBlur={handleBlur}
        />
        <div className={style.recoverPassword}>
          <span className={style.link} onClick={() => setShowPasswordReset(true)}>¿Olvidaste tu contraseña?</span>
        </div>
        <Button
          type='submit'
          loading={auth.loading}
        >
          Iniciar sesión
        </Button>
      </form>
      <Providers />
      <Modal
        show={showPasswordReset}
        close={() => setShowPasswordReset(false)}
      >
        <ResetPassword close={() => setShowPasswordReset(false)} />
      </Modal>
      <Modal
        show={auth.hasDifferentCredential}
        close={() => auth.setHasDifferentCredential(false)}
      >
        <AccountExists onClick={() => auth.setHasDifferentCredential(false)} />
      </Modal>
    </>
  )
}

export default LoginForm
