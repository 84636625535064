import style from './Sort.module.scss'
import { ReactComponent as IconDown } from '../../../assets/images/icon_down.svg'

import { useState, useRef, useEffect } from 'react'
import { CSSTransition } from 'react-transition-group'
import PropTypes from 'prop-types'

const Sort = ({ options, selected, onSelect }) => {
  const [showDropdown, setShowDropdown] = useState(false)
  const targetRef = useRef(null)
  const nodeRef = useRef(null)

  const handleSelect = (option) => {
    setShowDropdown(false)
    onSelect(option)
  }

  useEffect(() => {
    const handleClickOutside = (ev) => {
      if (targetRef.current && !targetRef.current.contains(ev.target)) {
        setShowDropdown(false)
      }
    }

    if (showDropdown) {
      document.addEventListener('click', handleClickOutside)
    }

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [showDropdown])

  return (
    <div className={style.container} ref={targetRef}>
      <div className={style.content} role='button' onClick={() => setShowDropdown(!showDropdown)}>
        <div className={style.label}>Ordenar por</div>
        <div className={style.value}>{selected.label} <span><IconDown /></span></div>
      </div>
      <CSSTransition
        nodeRef={nodeRef}
        in={showDropdown}
        timeout={200}
        mountOnEnter
        unmountOnExit
        classNames={{ ...style }}
      >
        <div className={style.dropdown} ref={nodeRef}>
          {
            options.map((option) => (
              <div
                key={option.label}
                className={`${style.option} ${option.value === selected.value ? style.selected : ''}`}
                role='button'
                onClick={() => handleSelect(option)}
              >
                {option.label}
              </div>
            ))
          }
        </div>
      </CSSTransition>
    </div>
  )
}

export default Sort

Sort.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  })).isRequired,
  selected: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  }).isRequired,
  onSelect: PropTypes.func
}
