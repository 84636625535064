import { useState, useCallback } from 'react'

import { apiCompetitions } from '../api'

const useEnroll = () => {
  const [saving, setSaving] = useState(false)

  const enroll = useCallback((competitionId) => {
    return new Promise((resolve, reject) => {
      setSaving(true)
      apiCompetitions.enroll(competitionId)
        .then(() => {
          resolve()
        })
        .catch((err) => {
          console.error(err)
          reject(err)
        })
        .finally(() => {
          setSaving(false)
        })
    })
  }, [])

  return [enroll, saving]
}

export default useEnroll
