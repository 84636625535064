const Terms = () => {
  return (
    <section>
      <h1>Términos y condiciones</h1>
      <p><i>Fecha de actualización 11 de enero de 2024</i></p>
      <p>Hola, ¡Bienvenido a Astrogool®! ("Astrogool" o "nosotros") Los siguientes términos y condiciones (los “Términos y Condiciones”) rigen el uso que el Usuario le dé al sitio web, aplicación móvil (“App”) y a cualquiera de los contenidos disponibles por o a través de www.astrogool.com, incluyendo cualquier contenido derivado del "Sitio Web".</p>
      <p>Al usar este Sitio Web, usted acepta y está de acuerdo con estos Términos y Condiciones, si usted no está de acuerdo no utilice los servicios que ofrece www.astrogool.com. Estos Términos constituyen el acuerdo completo entre nosotros con respecto a nuestro Servicio, y reemplazan cualquier acuerdo anterior que podamos tener.</p>
      <h2>I. INFORMACIÓN DEL USUARIO</h2>
      <p>Durante el uso que haga del Sitio Web y/o de los servicios/productos, se le puede pedir que nos proporcione información de carácter personal (dicha información en lo sucesivo "Información del Usuario"). Las políticas de uso y recopilación de información de www.astrogool.com con respecto a la privacidad de la Información del Usuario se establecen en el Aviso de Privacidad y Confidencialidad para la Protección de Datos Personales del Sitio Web, la cual está incorporada al mismo como referencia para todos los propósitos. Usted reconoce y acepta ser el único responsable de la exactitud del contenido de la Información del Usuario.</p>
      <h2>II. REGLAS DE USO GENERAL Y PROHIBICIONES</h2>
      <p>En Astrogool® estamos comprometidos con ofrecer un Servicio abierto e inclusivo, por lo tanto, obligamos al Usuario que cumpla con una serie de reglas de uso y prohibiciones, que serán aplicables de manera general para toda la comunidad que utilice nuestros servicios, cuales se mencionan a continuación:</p>
      <ul>
        <li>Se permite el uso de Astrogool® a mayores de 18 años o la edad mínima legal en el país, excluyendo nuestra responsabilidad si el Usuario exhibe una fecha de nacimiento y edad distinta a la permitida.</li>
        <li>Queda estrictamente prohibida cualquier distribución, publicación o explotación comercial o promocional, de cualquiera de los contenidos, códigos, datos o materiales de Astrogool®, incluyendo, sin limitación, la alteración o retiro de cualquier marca comercial, marca registrada, logo, marca de servicios o cualquier otro contenido de propiedad o notificación de derechos de propiedad de Astrogool®.</li>
        <li>Usted reconoce que no adquiere ningún derecho de propiedad al descargar algún material con derechos de autor y propiedad industrial a través de Astrogool®. Por lo tanto, queda estrictamente prohibido modificar, traducir, crear obras derivadas ni aplicar ingeniería inversa a nuestros productos o sus componentes.</li>
        <li>Si Usted selecciona un nombre de usuario o identificador similar para su cuenta, que consideramos inapropiado porque infringe propiedad intelectual de alguien, es ofensivo o suplanta la identidad de otro usuario; tendremos la facultad plena para modificarlo, sin su autorización.</li>
        <li>No podrás hacer uso de los Servicios en caso de que nosotros hayamos inhabilitado previamente tu cuenta por infringir la ley o alguna de nuestras políticas.</li>
        <li>El uso de los Servicios para organización de Torneos de manera privada no exime a los usuarios en el cumplimiento de la ley, del pago de impuestos o de normativas de terceros que sean aplicables. Astrogool® no se hace responsable por el mal uso que el usuario de a los servicios.</li>
        <li>Usted se obliga, además, a no usar el Sitio Web de alguna manera que pudiera dañar, deshabilitar, sobrecargar, deteriorar o interferir con que cualquier otra persona pueda usar o disfrutar o de cualquiera de sus servicios. Usted no obtendrá ni intentará obtener algún material o información a través de cualquier medio que no haya sido estipulado o puesto a la disposición del público intencionalmente a través del Sitio Web.</li>
      </ul>
      <h2>III. ENLACES CON EL SITIO WEB</h2>
      <p>Usted está de acuerdo en que si incluye un enlace (link) de cualquier otro sitio al Sitio Web www.astrogool.com, dicho enlace se abrirá en una nueva ventana navegadora y se enlazará con la versión completa de una página formateada HTML de este Sitio Web. Usted no tiene permitido enlazarse directamente a ninguna imagen almacenada en Astrogool® o en nuestros servicios, como sería usar un método de enlace "en-línea" para provocar que la imagen almacenada por nosotros fuera expuesta en otro sitio web.</p>
      <p>La inclusión de cualquier enlace a dichos sitios en nuestro Sitio no implica el aval, patrocinio o recomendación de ese sitio de Astrogool®, por lo que rechaza cualquier responsabilidad por los enlaces (1) de otro sitio web a este Sitio Web y (2) a otro sitio web de este Sitio Web.</p>
      <h2>IV. MATERIAL PARA USO DEL SITIO WEB</h2>
      <p>Cualquier información, trabajos creativos, demostración, ideas, sugerencias, conceptos, métodos, sistemas, diseños, planes, testimonios, técnicas u otros materiales que nos haya mandado o presentado (incluyendo, por ejemplo y sin limitación, aquello que usted presenta o envía a nuestros grupos de chateo, tablas de mensajes y/o a nuestros ‘blogs’, o que nos manda vía correo electrónico) ("Material para Uso del Sitio Web", “Material”, “Materiales”) se considerará como no confidencial o secreto y que puede ser usado por nosotros de cualquier manera consistente con el Aviso de Privacidad y Confidencialidad para la Protección de Datos Personales del Sitio Web.</p>
      <h2>V. ORDENES DE PRODUCTOS Y SERVICIOS</h2>
      <p>Al ordenar cualquier producto en el Sitio Web, por el presente documento usted garantiza que tiene 18 años de edad o más. Se obliga a pagar la totalidad de los precios de cualquier compra que haga, ya sea con tarjeta de crédito/débito concurrente con su orden en línea o por otro medio de pago aceptable por Astrogool®, así como el pago de todos los impuestos aplicables.</p>
      <h2>VI. DISPONIBILIDAD, ERRORES E IMPRECISIONES.</h2>
      <p>Estamos constantemente actualizando nuestras ofertas de productos y servicios en el Servicio. Los productos o servicios disponibles en nuestro Servicio pueden tener un precio incorrecto, describirse incorrectamente o no estar disponibles, y podemos experimentar retrasos en la actualización de la información sobre el Servicio y en nuestra publicidad en otros sitios web. No podemos y no garantizamos la exactitud o integridad de cualquier información, incluidos precios, imágenes de productos, especificaciones, disponibilidad y servicios. Nos reservamos el derecho de cambiar o actualizar la información y de corregir errores, imprecisiones u omisiones en cualquier momento sin previo aviso.</p>
      <h2>VII. LIMITACIÓN DE RESPONSABILIDAD</h2>
      <p>Astrogool®, sus subsidiarias, afiliadas o cualquier parte implicada en la creación, producción o entrega de cualquier servicio o cualquier aspecto de un servicio, NO TENDRÁN RESPONSABILIDAD por cualquier daño directo, indirecto o especial que surjan con el uso del servicio, su acceso o incapacidad de utilizar los servicios o cualquier sitio web enlazado a o desde el servicio, cualquier contenido del mismo, o en relación con cualquier falla de rendimiento, error, omisión, interrupción, defecto, demora en la operación o transmisión, falla de línea o sistema incluso si nosotros o cualquier tercero haya sido informado de la posibilidad de tales daños o pérdidas.</p>
      <h2>VIII. RENUNCIA DE GARANTÍAS.</h2>
      <p>El uso del Servicio es bajo su propio riesgo y se proporciona "TAL CUAL" y "SEGÚN DISPONIBILIDAD", por lo tanto, no hacemos ninguna declaración ni garantía de ningún tipo para usted o cualquier otra persona relacionada de cualquier manera con los servicios, incluyendo cualquier parte de los mismos, o cualquier sitio web u otro contenido o servicio que pueda ser accesible directa o indirectamente a través de los servicios. Entonces, renunciamos, en la medida máxima permitida por la ley, a cualquier y todas tales declaraciones y garantías.</p>
      <h2>IX. CONCURSOS, SORTEOS Y/O PROMOCIONES</h2>
      <p>Cualquier concurso, sorteo u otras promociones (colectivamente, "Promociones") disponibles a través del Servicio pueden regirse por la convocatoria que establece reglas que son independientes de estos Términos. Si participa en alguna de las promociones, revise las reglas aplicables y nuestro Aviso de Privacidad y Confidencialidad para la Protección de Datos Personales. Si las reglas para una Promoción entran en conflicto con estos Términos, se aplicarán las reglas de la promoción.</p>
      <h2>X. LEGISLACION APLICABLE</h2>
      <p>Estos Términos y Condiciones se regirán e interpretarán de conformidad con las leyes de los Estados Unidos Mexicanos, sin tener en cuenta sus disposiciones sobre conflictos de leyes. Nuestro incumplimiento de cualquier derecho o disposición no se considerará una renuncia a esos derechos. Si alguna disposición de se considera inválida o inaplicable por un tribunal, las disposiciones restantes seguirán vigentes.</p>
      <h2>XI. TERMINACION</h2>
      <p>Astrogool® puede terminar, cambiar, suspender o descontinuar cualquier aspecto del Sitio Web o de los servicios en cualquier momento, asimismo, se encuentra facultada para restringir, suspender o terminar su acceso al Sitio Web y/o a sus servicios si creemos que usted está en incumplimiento de nuestros términos y condiciones o de la ley aplicable, o por cualquier otra razón sin notificación o responsabilidad.</p>
      <h2>XII. MODIFICACIONES</h2>
      <p>Astrogool® se reserva el derecho, a su sola discreción, de cambiar, modificar, añadir o quitar cualquier porción de los Términos y Condiciones, toda o en parte, en cualquier momento. Los cambios en los Términos y Condiciones serán efectivos cuando se publiquen. La continuación del uso del Sitio Web y/o de los servicios puestos a disposición en o a través de este después de haber sido publicado cualquier cambio, será considerado como aceptación de esos cambios.</p>
      <h2>XIII. CONTACTO</h2>
      <p>Si tiene preguntas o comentarios en relación con los Términos y Condiciones aquí descritos, no dude en contactarnos al correo electrónico <a href='mailto:astrogool2023@gmail.com'>astrogool2023@gmail.com</a>. Es importante que nos proporcione su nombre completo y medio de contacto preferido, ya sea por correo electrónico, teléfono y/o dirección física, para responder a sus preguntas y comentarios.</p>
      <p><b>ATENTAMENTE</b><br />Astrogool®</p>
    </section>
  )
}

export default Terms
