import { auth } from '../lib/firebase'

const getHeaders = () => {
  return new Promise((resolve, reject) => {
    if (!auth.currentUser) {
      reject(new Error('No se ha cargado la sesión'))
    }
    auth.currentUser.getIdToken()
      .then((token) => {
        const authorization = `Bearer ${token}`
        const headers = {
          Authorization: authorization,
          'Content-Type': 'application/json'
        }
        resolve(headers)
      })
      .catch((err) => {
        console.error(err)
        reject(err)
      })
  })
}

export default getHeaders
