import style from './Captain.module.scss'

import PropTypes from 'prop-types'

import Section from '../../commons/Section'
import Button from '../../commons/Button'
import CardPlayer from '../CardPlayer'

import useSetTeam from '../../../hooks/useSetTeam'

const Captain = ({ onFinish, saving }) => {
  const [team, dispatch] = useSetTeam()
  const { players, captain } = team

  const handleClick = (value) => {
    dispatch({ type: 'set_captain', value })
  }

  return (
    <Section className={style.container}>
      <div className={style.header}>
        <h1 className={style.title}>Elige a tu capitán</h1>
        <p className={style.text}>Recuerda que los puntos que haga el capitán valen el doble (x2).</p>
      </div>
      <div className={style.content}>
        <div className={style.positions}>
          <div className={style.subtitle}>Delanteros</div>
          {players.forwards.map((player) => (
            <CardPlayer
              key={player.id}
              {...player}
              onClick={() => handleClick(player)}
              selected={captain?.id === player.id}
              captain={captain?.id === player.id}
            />)
          )}
        </div>
        <div className={style.positions}>
          <div className={style.subtitle}>Medios</div>
          {players.midfielders.map((player) => (
            <CardPlayer
              key={player.id}
              {...player}
              onClick={() => handleClick(player)}
              selected={captain?.id === player.id}
              captain={captain?.id === player.id}
            />)
          )}
        </div>
        <div className={style.positions}>
          <div className={style.subtitle}>Defensas</div>
          {players.defenders.map((player) => (
            <CardPlayer
              key={player.id}
              {...player}
              onClick={() => handleClick(player)}
              selected={captain?.id === player.id}
              captain={captain?.id === player.id}
            />)
          )}
        </div>
        <div className={style.positions}>
          <div className={style.subtitle}>Portero</div>
          <CardPlayer
            {...players.goalkeeper}
            onClick={() => handleClick(players.goalkeeper)}
            selected={captain?.id === players.goalkeeper.id}
            captain={captain?.id === players.goalkeeper.id}
          />
        </div>
        <Button
          disabled={!captain}
          onClick={onFinish}
          loading={saving}
        >
          {`${captain ? 'Guardar equipo ' : 'Elige a tu capitán'}`}
        </Button>
      </div>
    </Section>
  )
}

export default Captain

Captain.propTypes = {
  onFinish: PropTypes.func.isRequired,
  saving: PropTypes.bool
}
