import style from './Rules.module.scss'

const Rules = () => {
  return (
    <div className={style.container}>
      <div className={style.header}>
        <h1 className={style.title}>Reglas generales</h1>
        <div className={style.text}>
          <p>Cada DT de <b>Astrogool</b> debe crear su equipo fantasy antes del primer partido de cada jornada, este equipo <b>sólo compite en la jornada seleccionada e inscrita y debe ser rearmado para la siguiente</b> ¡Busca tu mejor estrategia!</p>
        </div>
      </div>
      <div className={style.content}>
        <div className={style.section}>
          <h2 className={style.subtitle}>¿Cómo?</h2>
          <ul className={style.list}>
            <li>Elige una formación y arma tu <b>11 ideal</b> con todos los equipos que participen en la liga, ya sea ofensiva o defensiva, ¡tú decides!.</li>
            <li>Dispones de <b>100 millones (100M) de Astros por jornada</b> para fichar jugadores para tu selección. Estos Astros no se acumulan en tu balance general.</li>
            <li>Si cuentas con <b>Astros</b> en tu balance general, puedes <b>agregar hasta 10M de Astros extra para armar tu equipo de esa jornada</b>. En caso de no gastarlos en su totalidad estos <b>no pueden ser reintegrados a tu balance</b>.</li>
            <li>Sólo se permiten <b>máximo 4 jugadores de un mismo club</b> en tu equipo.</li>
            <li>Nombra a un jugador capitán y obtén <b>doble puntaje (un x2)</b>.</li>
            <li>Guarda tu equipo y participa en la jornada seleccionada.</li>
          </ul>
        </div>
        <div className={style.section}>
          <h2 className={style.subtitle}>¿Dónde?</h2>
          <ul className={style.list}>
            <li>Los DT de <b>AstroGool</b> compiten con sus equipos fantasy en una tabla por jornada y una tabla general, clasificándose según su puntuación.</li>
            <li>En caso de <b>empate en la puntuación</b>, se da ventaja al equipo fantasy con el presupuesto más bajo. Si los equipos tienen el mismo presupuesto, el que se <b>guardó primero, por fecha y hora, gana</b>.</li>
          </ul>
        </div>
        <div className={style.section}>
          <h2 className={style.subtitle}>Reta a otros DT</h2>
          <ul className={style.list}>
            <li><b>Invita a tus amigos</b> para ganar la oportunidad de acumular más <b>Astros</b> en tu balance general, diviértete y demuestra quien sabe más de futbol.</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Rules
