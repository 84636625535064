import style from './SplashPreloader.module.scss'

import Loader from '../commons/Loader'

const SplashPreloader = () => {
  return (
    <div className={style.container}>
      <div className={style.content}>
        <Loader className={style.loader} />
      </div>
    </div>
  )
}

export default SplashPreloader
