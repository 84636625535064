import style from './index.module.scss'

import { useState } from 'react'
import PropTypes from 'prop-types'

import Input from '../../commons/Input'
import Button from '../../commons/Button'

import useUpdateTeam from '../../../hooks/useUpdateTeam'
import useToast from '../../../hooks/useToast'

const UpdateTeam = ({ name, onSaved }) => {
  const [updateTeam, saving] = useUpdateTeam()
  const [error, setError] = useState(null)
  const toast = useToast()

  const handleBlur = (ev) => {
    const value = ev.target.value
    if (value.trim() === '') {
      setError('El nombre del equipo no puede quedar vacío')
    } else {
      setError(null)
    }
  }

  const handleSubmit = (ev) => {
    ev.preventDefault()
    const value = ev.target.name.value
    if (value.trim() === '') {
      setError('El nombre del equipo no puede quedar vacío')
      return
    }
    updateTeam(value)
      .then(() => {
        toast.success({
          title: 'Se han guardado los cambios',
          message: 'El nombre de tu equipo ha sido actualizado correctamente.'
        })
        onSaved()
      })
      .catch((err) => {
        toast.error({
          title: 'No se pudieron guardar los cambios',
          message: err.message
        })
      })
  }

  return (
    <section className={style.container}>
      <h1 className={style.title}>Nombre de equipo</h1>
      <form className={style.form} onSubmit={handleSubmit}>
        <div className={style.description}>Elige el nombre de tu equipo, se mostrará en el ranking y será visible para todos los usuarios.</div>
        <Input
          label='Nombre'
          name='name'
          placeholder='Ingresa el nombre de tu equipo'
          error={error}
          defaultValue={name}
          onBlur={handleBlur}
        />
        <Button
          className={style.button}
          type='submit'
          loading={saving}
        >
          Guardar
        </Button>
      </form>
    </section>
  )
}

export default UpdateTeam

UpdateTeam.propTypes = {
  name: PropTypes.string,
  onSaved: PropTypes.func.isRequired
}
