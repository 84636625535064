import style from './index.module.scss'
import imageSrc from '../../../assets/images/img_error_403.svg'

import { useNavigate } from 'react-router-dom'

import Section from '../../commons/Section'
import Button from '../../commons/Button'

const Forbidden = () => {
  const navigate = useNavigate()

  return (
    <Section className={style.container}>
      <div className={style.content}>
        <div className={style.image}>
          <img src={imageSrc} alt='Error 403' />
        </div>
        <h1 className={style.title}>¡Hay portero, señores!</h1>
        <h2 className={style.subtitle}>Lo sentimos, pero no tienes permitido pasar por aquí.</h2>
        <p className={style.text}>Error 403</p>
      </div>
      <Button onClick={() => navigate(-1)}>Regresar</Button>
    </Section>
  )
}

export default Forbidden
