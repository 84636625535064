import style from './Tabs.module.scss'

import { forwardRef } from 'react'
import PropTypes from 'prop-types'

const Tabs = forwardRef(({ children, className }, ref) => {
  return (
    <div className={`${style.container} ${className || ''}`} ref={ref}>
      <div className={style.content}>
        {children}
      </div>
    </div>
  )
})

const Item = ({ label, onClick, active }) => {
  return (
    <div
      className={`${style.item} ${active ? style.active : ''}`}
      onClick={onClick}
    >
      <span>{label}</span>
    </div>
  )
}

Tabs.Item = Item

export default Tabs

Tabs.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

Item.propTypes = {
  active: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
}
