import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import Team from '../modules/Team'

import useNavigation from '../../hooks/useNavigation'
import usePoints from '../../hooks/usePoints'
import useToast from '../../hooks/useToast'

const TeamRoute = () => {
  const { id } = useParams()
  const { data: team, error, isLoading } = usePoints(id)
  const { error: toast } = useToast()
  const { setNavigation } = useNavigation()

  useEffect(() => {
    setNavigation({
      title: isLoading ? '' : team?.name || 'Equipo',
      hasBack: true,
      isSecondary: true
    })
  }, [setNavigation, team, isLoading])

  useEffect(() => {
    if (error) {
      toast({
        title: 'Ocurrió un error al cargar la alineación',
        message: error.message
      })
    }
  }, [error, toast])

  return (
    <Team id={id} />
  )
}

export default TeamRoute
