import style from './Heading.module.scss'

import PropTypes from 'prop-types'

const Heading = ({ title, description, children }) => {
  return (
    <div className={style.container}>
      <div className={style.heading}>
        <h2 className={style.title}>{title}</h2>
        <div className={style.content}>
          {children}
        </div>
      </div>
      {description && <p className={style.description}>{description}</p>}
    </div>
  )
}

const Display = ({ children }) => (
  <h2 className={style.display}>
    {children}
  </h2>
)

Heading.Display = Display

export default Heading

Heading.propTypes = {
  children: PropTypes.node,
  description: PropTypes.string,
  title: PropTypes.string.isRequired
}

Display.propTypes = {
  children: PropTypes.node.isRequired
}
