import style from './Reauthenticate.module.scss'

import { useState } from 'react'

import Button from '../commons/Button'
import Input from '../commons/Input'

import useAuth from '../../hooks/useAuth'
import useToast from '../../hooks/useToast'

const Reauthenticate = ({ onReauth }) => {
  const [error, setError] = useState(null)
  const auth = useAuth()
  const toast = useToast()

  const handleBlur = (ev) => {
    const value = ev.target.value
    if (value.trim() === '') {
      setError('Introduce tu contraseña actual')
    } else {
      setError(null)
    }
  }

  const handleSubmit = (ev) => {
    ev.preventDefault()
    const value = ev.target.password.value
    if (value.trim() === '') {
      setError('Introduce tu contraseña actual')
      return
    }
    auth.reauthenticate(value)
      .then(() => {
        onReauth()
      })
      .catch((err) => {
        if (err.field) {
          setError(err.message)
        } else {
          toast.error({
            title: err.code,
            message: err.message
          })
        }
      })
  }

  return (
    <form className={style.container} onSubmit={handleSubmit}>
      <div className={style.content}>
        <h1 className={style.title}>Introduce tu contraseña</h1>
        <p className={style.text}>Para concretar la operación es necesaria confirmarla con tu contraseña actual.</p>
        <Input.Password
          label='Contraseña'
          name='password'
          placeholder='Ingresa tu contraseña'
          error={error}
          onBlur={handleBlur}
        />
        <Button
          type='submit'
          loading={auth.loading}
          className={style.button}
        >
          Confirmar
        </Button>
      </div>
    </form>
  )
}

export default Reauthenticate
