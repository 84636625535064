import { useState, useCallback } from 'react'
import { updateProfile } from 'firebase/auth'
import { doc, setDoc } from 'firebase/firestore'

import { auth, db } from '../lib/firebase'
import { apiCore } from '../api'

import useUser from './useUser'

const useUpdateName = () => {
  const [saving, setSaving] = useState(false)
  const [user, dispatch] = useUser()

  const updateName = useCallback((value) => {
    return new Promise((resolve, reject) => {
      setSaving(true)
      const displayName = value.name + ' ' + value.lastName
      apiCore.updateProfile({
        first_name: value.name,
        last_name: value.lastName,
        username: value.name + ' ' + value.lastName
      })
        .then(() => {
          return updateProfile(auth.currentUser, { displayName })
        })
        .then(() => {
          return setDoc(doc(db, 'users', user.uid), { displayName }, { merge: true })
        })
        .then(() => {
          dispatch({
            type: 'change_name',
            value
          })
          resolve()
        })
        .catch((err) => {
          console.error(err)
          reject(err)
        })
        .finally(() => {
          setSaving(false)
        })
    })
  }, [user, dispatch])

  return [updateName, saving]
}

export default useUpdateName
